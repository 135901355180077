<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Create Thread</h3>
                </div>
                <form @submit.prevent="create">
                    <div class="mb-3">
                        <label for="threadTitle" class="form-label">Thread Title</label>
                        <input type="text" v-model="title" placeholder="Enter a title for this thread" class="form-control" id="threadTitle" required>
                    </div>
                    <div class="mb-3">
                        <ForumPostEditor v-model:editorContent="editorContent" :content="editorContent"/>
                        <div v-if="submissionError" class="mb-3 error-message text-center">
                            <p>{{ submissionError }}</p>
                        </div>
                    </div>
                    <LoadingButton :loading="loadingSubmission" :classes="['full-width green']" :disabled="loadingSubmission">Submit Thread</LoadingButton>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import ForumPostEditor from "@/views/forums/ForumPostEditor"
import LoadingButton from "@/components/LoadingButton"

export default {
    components: {
        ForumPostEditor,
        LoadingButton
    },
    data() {
        return {
            client: null,
            forums: [],
            editor: null,
            title: '',
            editorContent: '<p>Click here and type your message</p>'
        }
    },
    created() {
        document.title = 'Forums - Near-Reality'

        this.client = new APIClient()
    },
    methods: {
        addImage() {
            const url = window.prompt('URL')

            if (url) {
                this.editor.chain().focus().setImage({ src: url }).run()
            }
        },
        create() {
            const payload = {
                forum_id: this.$route.params.id,
                title: this.title,
                content: this.editorContent
            }

            this.client.postForumThread(payload).then((response) => {
                if (response.status === 201) {
                    this.$router.push('/forums/')
                }
            }).catch((err) => {
                if (err.response && err.response.data) {
                    this.errors = err.response.data
                }
            })
        }
    }
}
</script>

<style>
.ProseMirror:focus {
  outline: none;
}
</style>