<template>
    <main>
        <div class="container">
            <form>

                <div class="main-content-box">
                    <div class="row g-4">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                            <h3 class="page-title">Credit Packages</h3>
                            <div v-if="productError" class="mb-3 error-message text-center">
                                <p>{{ productError }}</p>
                            </div>
                            <Loading v-if="loading"/>
                            <div v-else class="credit-store--coins-grid">
                                <label v-for="product in products" :key="product.id" class="custom-radio">
                                    <input type="radio" name="credits-amount" :value="product.id" v-model="selectedProduct">
                                    <div class="card credit-store--coins-box custom-radio--inner">
                                        <img :src="product.imageUrl" alt="credits-1">
                                        <h5><span>{{ product.credits }}</span> Credits</h5>
                                        <p>for <span>${{ product.price }}</span> USD</p>
                                        <p v-if="product.bonusCredits > 0" class="credit-bonus">+ {{ product.bonusCredits }} Free Bonus Credits</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 col-lg-4 col-xl-4">
                            <h3 class="page-title center">Payment Methods</h3>
                            <div class="card thin credit-store--payments-grid">
                                <ul>
                                    <!-- <li>
                                        <p class="credit-store--payments-title">Credit Cards</p>
                                        <div class="credit-store--payments-row">
                                            <label class="custom-radio">
                                                <input type="radio" name="payment-method" value="stripe" v-model="paymentMethod">
                                                <div class="custom-radio--inner credit-store--payments-image">
                                                    <img src="@/assets/img/payment-logos/stripe-gateway.svg">
                                                </div>
                                            </label>
                                        </div>
                                    </li> -->
                                    <li>
                                        <p class="credit-store--payments-title">Cryptocurrency</p>
                                        <div class="credit-store--payments-row" style="border: 1px solid #4b3a1c">
                                            <label class="custom-radio">
                                              <img src="@/assets/img/payment-logos/coinbase-gateway.svg">
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <p class="credit-store--payments-title">PayPal</p>
                                        <div class="credit-store--payments-row" style="border: 1px solid #4b3a1c">
                                            <label class="custom-radio">
                                              <img src="@/assets/img/payment-logos/paypal-gateway.svg">
                                            </label>
                                        </div>
                                    </li>
                                    <li>

                                        <p class="credit-store--payments-title">OSRS Gold</p>
                                        <div class="credit-store--payments-row" style="border: 1px solid #4b3a1c">
                                            <label class="custom-radio">

                                              <img src="@/assets/img/payment-logos/osrs-gold-gateway.svg">
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                              <br>
                              <br>
                              <br>
                              <h5>You can only purchase <span>credit packages</span> through the in-game store (type <span><i>::store</i></span> in chat).</h5>
                              <br>
                              <br>
                              <br>
                            </div>
                        </div>
                    </div>

                    <div class="credit-store--complete-payment">
                        <!-- <p>Click below to proceed to payment with <span>{{ paymentMethod === 'paypal' ? 'PayPal' : toTitleCase(paymentMethod) }}</span></p> -->
<!--                        <button type="button" class="custom-button mb-3 mt-4" @click="purchase">Purchase</button>-->

                    </div>

                    <div class="modal" tabindex="-1" ref="stripeModal">
                        <StripeCreditPaymentModal :balance="credits" :product="getProductForId(selectedProduct)"/>
                    </div>

                    <div class="modal" tabindex="-1" ref="paypalModal">
                        <PayPalCreditPaymentModal :balance="credits" :product="getProductForId(selectedProduct)"/>
                    </div>

                    <div class="modal" tabindex="-1" ref="coinbaseModal">
                        <CoinbaseCreditPaymentModal :balance="credits" :product="getProductForId(selectedProduct)"/>
                    </div>

                    <!-- <div class="modal" tabindex="-1" id="completePaymentModal">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Order Summary</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                                </div>
                                <div class="modal-body">
                                    <div class="credit-modal--body">
                                        <ul>
                                            <li><p>Current Balance:</p><p><img src="resources/img/credits/nr-token.png" class="mr-2" lazy> 1300 NRP</p></li>
                                            <li><p>Order:</p><p><img src="resources/img/credits/nr-token.png" class="mr-2" lazy> 1300 NRP</p></li>
                                            <li class="new-balance"><p>New Balance:</p><p><img src="resources/img/credits/nr-token.png" class="mr-2" lazy> 1300 NRP</p></li>
                                            <li><hr></li>
                                            <li><p>Price:</p><p>$100.00 USD</p></li>
                                            <li><p>Taxes & Fees:</p><p>$4.00 USD</p></li>
                                            <li class="discount"><p>Discount:</p><p>$0.00 USD</p></li>
                                            <li class="total"><p>Total:</p><p>$104.00 USD</p></li>
                                            <li class="payment-method"><p>Payment Method:</p><p>Bitcoin</p></li>
                                        </ul>

                                    </div>
                                    <hr>
                                    <div class="store-modal--price">
                                        <p>Due: $104.00 USD</p>
                                        <a href="#" class="custom-button">Purchase</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </form>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import StripeCreditPaymentModal from "@/components/modals/StripeCreditPaymentModal"
import PayPalCreditPaymentModal from "@/components/modals/PayPalCreditPaymentModal"
import CoinbaseCreditPaymentModal from "@/components/modals/CoinbaseCreditPaymentModal"

export default {
    components: {
        Loading,
        StripeCreditPaymentModal,
        PayPalCreditPaymentModal,
        CoinbaseCreditPaymentModal
    },
    created() {
        this.client = new APIClient();
        this.getCreditProducts()
        this.getCreditAmount()
    },
    mounted() {
        document.title = 'Credit Store - Near-Reality'

        this.stripeModal = new window.bootstrap.Modal(this.$refs.stripeModal)
        this.paypalModal = new window.bootstrap.Modal(this.$refs.paypalModal)
        this.coinbaseModal = new window.bootstrap.Modal(this.$refs.coinbaseModal)
    },
    data() {
        return {
            client: null,
            loading: true,
            products: [],
            credits: 0,
            selectedProduct: null,
            productError: null,
            paymentMethod: 'paypal',
            paymentMethodError: null,
            stripeModal: null,
            paypalModal: null,
            coinbaseModal: null
        }
    },
    methods: {
        getCreditProducts() {
            this.client.getCreditProducts().then((response) => {
                this.loading = false
                this.products = response.data.packages
            })
        },
        getCreditAmount() {
            this.client.getStoreCreditAmount().then((response) => {
                this.credits = response.data.credits;
            })
        },
        getProductForId(id) {
            return this.products.filter(product => product.id === id)[0]
        },
        purchase() {
            this.productError = null;
            this.paymentMethodError = null;

            if (this.selectedProduct === null) {
                this.productError = 'You must select a credit package in order to proceed with checkout.'
                return
            }

            if (this.paymentMethod === null) {
                this.paymentMethodError = 'You must select a payment method in order to proceed with checkout.'
                return
            }

            if (this.paymentMethod === 'stripe') {
                this.stripeModal.show()
            } else if (this.paymentMethod === 'paypal') {
                this.paypalModal.show()
            } else if (this.paymentMethod === 'coinbase') {
                this.coinbaseModal.show()
            }
        }
    }
}
</script>
