<template>
  <main>
    <div class="container">
      <div class="main-content-box">
        <div class="top-page-divider">
          <h3 class="page-title center">Near Reality Rules</h3>
          <p>Rules & Regulations</p>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <h5 class="content-box--heading">Contents</h5>
            <div class="table-of-contents">
              <ul>
                <li>
                  <a href="#1">1. Flaming, Racism, Harassment, and Disrespect</a>
                </li>
                <li>
                  <a href="#2">2. Doxing/Sharing Personal Information</a>
                </li>
                <li>
                  <a href="#3">3. Advertising Other Servers</a>
                </li>
                <li>
                  <a href="#4">4. Spamming</a>
                </li>
                <li>
                  <a href="#5">5. Hacking & Threats</a>
                </li>
                <li>
                  <a href="#6">6. Encouraging Others to Break Rules</a>
                </li>
                <li>
                  <a href="#7">7. Bug Abuse</a>
                </li>
                <li>
                  <a href="#8">8. Player Vs. Player (PvP)</a>
                </li>
                <li>
                  <a href="#9">9. 3rd Party Software</a>
                </li>
                <li>
                  <a href="#10">10. Impersonation</a>
                </li>
                <li>
                  <a href="#11">11. Scams/Gambling/Refunds</a>
                </li>
                <li>
                  <a href="#12">12. Real-World Trading</a>
                </li>
                <li>
                  <a href="#13">13. Services</a>
                </li>
                <li>
                  <a href="#14">14. Yell Channel</a>
                </li>
                <li>
                  <a href="#15">15. Vote System</a>
                </li>
                <li>
                  <a href="#16">16. Punishment Evasion</a>
                </li>
                <li>
                  <a href="#17">17. Maximum of 3 Alternate Accounts in AFK Zones</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-9">
            <h5 class="paragraph--heading" id="1">1. Flaming, Racism, Harassment, and Disrespect</h5>
            <p>Near-Reality is a cultural and diverse community with players spanning across the world. We must protect the integrity of this community by not allowing racism, harassment, homophobic slurs, flaming others, etc. Members flaming one another, using inappropriate language, slander, etc., will not be tolerated.</p>
            <p>Use of inappropriate language, slanderous behavior, racism, or any other form of disrespect towards race, religion, ethics, sexual orientation, etc., will not be tolerated.</p>
            <p>Harassment is defined as targeting a specific group or individual by being a nuisance, flaming, racism of any kind, disrespecting, etc.</p>
            <p>Using Discord to single out another player or group to defame their reputation, character, or flame them will also be considered harassment and/or disrespect.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">24hr Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">48hr Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status orange mb-0">Permanent Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">4th Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
            </div>
            <hr id="2">
            <h5 class="paragraph--heading">2. Doxing/Sharing Personal Information</h5>
            <p>Sharing personal information such as names, family member info, home addresses, phone numbers, etc., is forbidden.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent IP/Hardware Ban</p>
                </div>
              </div>
            </div>
            <hr id="3">
            <h5 class="paragraph--heading">3. Advertising Other Servers</h5>
            <p>Advertisement on the Discord and/or server is forbidden. Those who have other situations that do NOT relate to RuneScape Private Relations can privately message a member of management for permission to advertise; otherwise, all advertisements will be dealt with in the same manner.</p>
            <p>Commonly known links (e.g. RuneLocus, Rune-Server, and YouTube) will be permitted provided this exception is not taken for granted and does not advertise another community.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status red mb-0">IP-Ban/Mac Ban</p>
                </div>
              </div>
            </div>
            <hr id="4">
            <h5 class="paragraph--heading">4. Spamming</h5>
            <p>Spamming on Near-Reality is not allowed; this includes but is not limited to causing a nuisance in the clan-chat system, populated areas, spamming the same message over-and-over, etc.</p>
            <p>Auto-Typers are allowed within the following parameter: the timer should be set to a minimum of 15 seconds.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">24hr Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">48hr Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status orange mb-0">48hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">4th Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
            </div>
            <hr id="5">
            <h5 class="paragraph--heading">5. Hacking & Threats</h5>
            <p>Misleading links are not allowed on Discord; if posting a link to a YouTube video, it should lead to YouTube.</p>
            <p>Performing any type of hacking (accounts, server, etc.) will result in a permanent removal from the community.</p>
            <p>Threatening any member with anything will result in a suitable punishment, up to a permanent IP-ban.</p>
            <p>a. DDOS threats towards players or management will result in an immediate removal from the community – permanently.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent IP Ban</p>
                </div>
              </div>
            </div>
            <hr id="6">
            <h5 class="paragraph--heading">6. Encouraging Others to Break Rules</h5>
            <p>Encouraging other members to perform any action that results in breaking the rules (whether the member knows it's against the rules or not) will result in punishment. It's the responsibility of all members of this community to know and understand the rules before registering and playing Near-Reality.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">24hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">Permanent Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
            </div>
            <hr id="7">
            <h5 class="paragraph--heading">7. Bug Abuse</h5>
            <p>Abusing an exploit on Near-Reality will not be tolerated and will result in a permanent removal from the community.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">24hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">72hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
            </div>
            <hr id="8">
            <h5 class="paragraph--heading">8. Player Vs. Player (PvP)</h5>
            <p>We do not have set rules for the wilderness besides the ones laid out regarding harassment, threats, and personal threats (DDOS, Doxing, etc.), so use the wilderness at your own risk.</p>
            <p>Farming consists of killing your own/clan alternate accounts or requesting kills from a player (regardless of if compensation is involved).</p>
            <p>Farming kills for the purpose of gaining rewards or advancing in any form will not be tolerated.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">24hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">72hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div the="content-box thin">
                  <p the="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
            </div>
            <hr id="9">
            <h5 class="paragraph--heading">9. 3rd Party Software</h5>
            <p>We will not tolerate cheating of ANY kind as we know that it causes unfair advantages over other members that are attempting to play the server in a fair manner. This includes the use of auto-clickers, cheat clients, bots, and more.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">IP Ban</p>
                </div>
              </div>
            </div>
            <hr id="10">
            <h5 class="paragraph--heading">10. Impersonation</h5>
            <p>Impersonating any staff member will not be tolerated and will result in strict punishment. Players are often scammed, hacked, or misled by others impersonating staff members.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
            </div>
            <hr id="11">
            <h5 class="paragraph--heading">11. Scams/Gambling/Refunds</h5>
            <p>a. Items lost due to disconnects/bugs are refunded at the discretion of management. Evidence in the form of video footage/screenshots can be provided to aid management in locating the relevant logs.</p>
            <p>b. Donations will be refunded with proof of purchase, details of the donation (date/time/screenshots), and at the discretion of management.</p>
            <p>c. Scam reports are handled accordingly, but we do not offer refunds. Offenders will be punished if concrete evidence (video proof) is provided.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">IP Ban</p>
                </div>
              </div>
            </div>
            <hr id="12">
            <h5 class="paragraph--heading">12. Real-World Trading</h5>
            <p>Near-Reality does not allow real-world trading because it negatively influences the economy. Real-world trading is defined as selling/buying real-life money, RuneScape gold, or any other form of payment other than NRGP for Near Reality-related items. The ONLY exception is the buying and selling of donator pins with RuneScape gold.</p>
            <p>Using a loophole (e.g., Player A sells a pin to Player B for RSGP/OSRSGP and then buys the pin back for NRGP) is considered real-world trading. Selling the pin bought with RSGP/OSRSGP to someone else for NRGP is allowed, as long as pre-made agreements of RWT are not made.</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status red mb-0">Permanent Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">IP Ban</p>
                </div>
              </div>
            </div>
            <hr id="13">
            <h5 class="paragraph--heading">13. Services</h5>
            <p>Near-Reality will NOT be enforcing any rules regarding services, these include but are not limited to:</p>
            <ul class="bulletpoints">
              <li>Skill training</li>
              <li>Minigame training</li>
              <li>Inferno services</li>
            </ul>
            <hr id="14">
            <h5 class="paragraph--heading">14. Yell Channel</h5>
            <p>Use of the Yell chat includes but is not limited to:</p>
            <ul class="bulletpoints">
              <li>Advertising content creator's channels; keep a minimum of five (5) minutes between announcements.</li>
              <li>Advertising another clan-chat; keep a minimum of five (5) minutes between announcements.</li>
              <li>Using ONLY English in the help CC</li>
            </ul>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">24hr Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">48hr Mute</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status red mb-0">Permanent Mute</p>
                </div>
              </div>
            </div>
            <hr id="15">
            <h5 class="paragraph--heading">15. Vote System</h5>
            <p>Abuse of the vote system in any form or fashion that results in an unfair advantage will result in a permanent ban.</p>
            <hr id="16">
            <h5 class="paragraph--heading">16. Punishment Evasion</h5>
            <p>All punishments are handed out with enough evidence to show that you should either no longer be able to use certain services or be a part of the community. If you evade your punishment, harsher punishments will be handed out. We advise members to make an appeal instead of evading the punishment.</p>
            <hr id="17">
            <h5 class="paragraph--heading">17. Maximum of 3 Alternate Accounts in AFK Zones</h5>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">1st Offense</p>
                  <p class="status grey mb-0">72hr Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">2nd Offense</p>
                  <p class="status yellow mb-0">Permanent Ban</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="content-box thin">
                  <p class="text-faded text-bold text-uppercase mb-2">3rd Offense</p>
                  <p class="status red mb-0">IP Ban</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  created() {
    document.title = 'Rules - Near-Reality'
  }
}
</script>

