<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="row flex-row d-flex align-items-center justify-content-space-between top-page-divider">

                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <h3 class="page-title">Store</h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="page-title-box-right store-profile-credits">
                            <h5>You Have: <img src="@/assets/img/credits/nr-token.png" class="mr-1" lazy><span>{{ credits }}</span></h5>
                            <router-link to="/credits" class="custom-button"><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy>Buy {{ credits > 0 ? 'more' : '' }} Credits</router-link>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-5 col-lg-4 col-xl-3">
                        <div class="">
                            <a href="#" class="custom-button mb-4" data-bs-toggle="modal" data-bs-target="#donatorBenefitsModal">Donator Benefits</a>
                            <a href="#" class="custom-button mb-4" data-bs-toggle="modal" data-bs-target="#customDonationModal">Custom Donations</a>
                            <div class="store-filters">

                                <h5 class="content-box--heading">Categories</h5>
                                <div class="store-filters--categories">
                                    <div v-for="category in categories" :key="category.id" class="input-group">
                                        <div class="form-check custom-control custom-checkbox">
                                            <input class="form-check-input custom-control-input" type="radio" :value="category.id" :id="'filter-' + category.name" v-model="selectedCategory" name="filter">
                                            <label class="form-check-label custom-control-label" for="'filter-' + category.name">
                                                {{ category.name }}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <hr>

                                <div class="input-group input-group-flex">
                                    <input type="text" name="user_search" id="us" placeholder="Search...">
                                    <button type="submit"><i class="fa fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-7 col-lg-8 col-xl-9">
                        <div v-if="errorMessage" class="mb-3 error-message text-center">
                            <p>{{ errorMessage }}</p>
                        </div>
                        <div v-if="successMessage" class="mb-3 success-message text-center">
                            <p>{{ successMessage }}</p>
                        </div>
                        <Loading v-if="loading"/>
                        <div v-else-if="products.length === 0" class="mb-3 error-message text-center">
                            <p>No products found for selected category; try checking another one!</p>
                        </div>
                        <div v-else class="store-product--grid">
                            <div v-for="product in products" :key="product.id">
                                <a data-bs-toggle="modal" :data-bs-target="`#productModal-${product.id}`" href="#" class="card thin store-product--block" :class="{ 'ironman' : product.ironman, 'sale-low': product.sale === 'LOW', 'sale-medium': product.sale === 'MEDIUM', 'sale-high': product.sale === 'HIGH' }">
                                    <div v-if="saleActive(product)" class="discount-badge">{{ salePercentage(product) }}% OFF</div>
                                    <div class="store-product--block-image">
                                        <img :src="product.image_href" lazy>
                                    </div>
                                    <h5>{{ product.name }}</h5>
                                    <div class="store-product--block-price-row">
                                        <img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy><p class="reduced">{{ saleActive(product) ? product.sale_price : product.price }} <span v-if="saleActive(product)">{{ product.price }}</span></p>
                                    </div>
                                    <div class="corner-glows"></div>
                                </a>

                                <div class="modal" tabindex="-1" :id="`productModal-${product.id}`">
                                    <ProductModal :productId="product.id" :name="product.name" :imageHref="product.image_href" :category="product.category" :ironman="product.ironman" :description="product.description" :price="product.price" :salePrice="product.sale_price" :saleEnabled="saleActive(product)"/>
                                </div>

                                <div class="modal" tabindex="-1" :id="`productConfirmationModal-${product.id}`">
                                    <ProductConfirmationModal :productId="product.id" :imageHref="product.image_href" :price="product.price" :salePrice="product.sale_price" :saleEnabled="saleActive(product)" @purchase="purchase"/>
                                </div>
                            </div>
                        </div>
                        <nav v-if="!loading && products.length !== 0" class="pagination-row-center w-100" aria-label="Page navigation example">
                            <ul class="pagination m-auto">
                                <li class="page-item" :class="{ 'disabled': !previousPage }">
                                    <a class="page-link" href="#" aria-label="Previous" @click="page = Math.max(1, page - 1)">
                                        <span aria-hidden="true">&laquo; Previous Page</span>
                                    </a>
                                </li>
                                <li class="page-item active"><a class="page-link" href="#">{{ page }}</a></li>
                                <li class="page-item" :class="{ 'disabled': !nextPage }">
                                    <a class="page-link" href="#" aria-label="Next" @click="page = page + 1">
                                        <span aria-hidden="true">Next Page &raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="modal" tabindex="-1" id="donatorBenefitsModal">
                <DonatorBenefitsModal/>
            </div>

            <div class="modal" tabindex="-1" id="customDonationModal">
                <CustomDonationModal/>
            </div>

        </div>
    </main>
</template>

<script>
import Loading from "@/components/Loading"
import APIClient from '@/services/APIClient.js'
import DonatorBenefitsModal from "@/components/modals/DonatorBenefitsModal"
import ProductModal from '@/components/modals/ProductModal'
import ProductConfirmationModal from '@/components/modals/ProductConfirmationModal'
import CustomDonationModal from '@/components/modals/CustomDonationModal'

export default {
    components: {
        Loading,
        DonatorBenefitsModal,
        CustomDonationModal,
        ProductModal,
        ProductConfirmationModal
    },
    created() {
        document.title = 'Store - Near-Reality'

        this.apiClient = new APIClient()
        this.getCategories()

        this.$watch(
            () => this.$route.query.category,
            (value) => {
                const category = Number.parseInt(value) || 3
                this.getProducts(category)
            },
            { immediate: true }
        )
    },
    watch: {
        selectedCategory(value) {
            this.page = 1
            this.$router.push({ path: '/store', query: { 'category': value } })
        },
        page(value) {
            const category = Number.parseInt(this.$route.query.category) || 1
            this.getProducts(category)
        }
    },
    computed: {
        credits() {
            return this.$store.state.user.storeCredits - this.creditDecrease
        },
    },
    data() {
        return {
            loading: true,
            apiClient: null,
            categories: [],
            products: [],
            filters: [],
            selectedCategory: Number.parseInt(this.$route.query.category) || 1,
            page: 1,
            previousPage: null,
            nextPage: null,
            errorMessage: null,
            successMessage: null,
            productConfirmationModal: null,
            creditDecrease: 0
        }
    },
    methods: {
        getCategories() {
            this.apiClient.getStoreCategories().then(response => {
                this.categories = response.data
            })
        },
        getProducts(category) {
            this.loading = true
            this.apiClient.getStoreProducts(category, this.page).then(response => {
                this.loading = false;
                this.products = response.data.results

                this.previousPage = response.data.previous
                this.nextPage = response.data.next
            })
        },
        salePercentage(product) {
            if (product.sale === 'LOW') {
                return 10
            } else if (product.sale === 'MEDIUM') {
                return 25
            } else {
                return 50
            }
        },
        saleActive(product) {
            return product.sale === 'LOW' || product.sale === 'MEDIUM' || product.sale === 'HIGH'
        },
        purchase({id, price}) {
            const payload = {
                id: id
            }

            this.apiClient.postStorePurchase(payload).then((response) => {
                if (response.data && response.data.success) {
                    this.successMessage = 'Product successfully purchased - you may now talk to the donation manager at home to claim your purchase(s).'
                }

                this.creditDecrease += price
            }).catch((err) => {
                if (err.response && err.response.data && err.response.data.error) {
                    this.errorMessage = err.response.data.error
                }
            })
        }
    }
}
</script>
