<template>
    <main>
        <div class="container">
            <div class="main-content-box" id="highscores">

                <div class="row flex-row d-flex justify-content-space-between top-page-divider">
                        
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                            <h3 class="page-title">Hiscores</h3>
                        </div>            
                    
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div class="page-title-box-right">
                                <input type="text" v-model="playerSearch" @keyup.enter="search" name="player-search" id="us" placeholder="Search players...">
                                <button type="submit" @click="search" id="us-btn"><i class="fa fa-search"></i></button>
                            </div>
                        </div>
                </div>

                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 mb-2" id="filters">
                    <!-- Gamemode Dropdown -->
                    <div class="col">
                        <div class="card">
                            <div class="gamemode">
                                <select id="gamemodes" name="gamemode">
                                    <option value="0">Select Gamemode:</option>
                                    <option value="regular">Regular</option>
                                    <option value="ironman">Ironman</option>
                                    <option value="ultimate_IronMan">Ultimate Ironman</option>
                                    <option value="hardcore_Ironman">Hardcore Ironman</option>
                                    <option value="group_Ironman">Group Ironman</option>
                                </select>
                            </div>
                        </div>      
                    </div>
                    <!-- XP Boosts Dropdown -->
                    <div class="col">
                        <div class="card">
                            <div class="xp-mode">
                                <select id="xp-mode" name="xp-mode">
                                    <option value="0">Select XP Mode:</option>
                                    <option value="0X">Regular xp</option>
                                    <option value="10X">10X</option>
                                    <option value="15X">15X</option>
                                    <option value="25X">25X</option>
                                </select>

                            </div>
                        </div>     
                        
                    </div>
                    <!-- World Dropdown -->
                    <div class="col">
                        <div class="card">
                            <div class="world">
                                <select id="worlds" name="world">
                                    <option value="w1">World 1</option>
                                </select>

                            </div>
                        </div>     
                    </div>
                </div>
                <div class="row">


                    <div class="col-12 col-md-12 col-lg-4 col-xl-3 left-col">
                        <div class="card" id="not_dropdown">
                            <h5 class="content-box--heading">Categories</h5>
                            <ul class="highscores-categories main-categories">
                                <li class="highscores-categories--title">
                                    <h6>Skills</h6>
                                </li>
                                <li>
                                    <router-link class="overall" :class="{ active: skill.length === 0 }" to="/hiscores">Overall</router-link>
                                </li>
                                <li v-for="category in categories" :key="category">
                                    <router-link :class="[ category.toLowerCase(), { 'active': skill === category.toLowerCase() } ]" :to="`/hiscores/${category.toLowerCase()}`">{{ category }}</router-link>
                                </li>
                                <li class="highscores-categories--title">
                                    <h6>PvP</h6>
                                </li>
                                <li>
                                    <a class="pk-kills" onclick="#">
                                        Top Pkers
                                    </a>
                                </li>
                                <li>
                                    <a class="pk-streaks" onclick="#">
                                        Top Kill Streaks
                                    </a>
                                </li>
                            </ul>
                            
                        </div>

                    </div>

                    <div class="col-12 col-md-12 col-lg-8 col-xl-9 right-col">
                        <div class="card">
                            <div class="highscores-current-view">
                                <div class="skill-name">
                                    <img :src="require('@/assets/img/highscores/old-skills/' + (skill.length === 0 ? 'overall' : skill) + '.png')">
                                    <h5>{{ skill.length === 0 ? 'Overall' : skill }}</h5>
                                </div>

                                <div>
                                    <img src="@/assets/img/highscores/gamemodes/ironman.png">
                                    <p>Ironman</p>
                                </div>
                                <div>
                                    <img src="@/assets/img/highscores/gamemodes/xp-boost.png">
                                    <p>10x XP Boost</p>
                                </div>
                            </div>

                        </div>
                        <div class="card rank-table">
                            <div class="table-overflow">        
                                <table id="leaderboard">
                                    <thead>
                                        <tr>
                                            <th class="rank">
                                                Rank
                                            </th>
                                            <th class="username">
                                                Username
                                            </th>
                                            <th class="level">
                                                Level
                                            </th>
                                            <th class="right-col">
                                                Experience
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="loading">
                                            <td class="text-center" colspan="4">
                                                <Loading/>
                                            </td>   
                                        </tr>
                                        <tr v-else-if="!hiscores.length">
                                            <td class="text-center" colspan="4">No hiscores entries found.</td>    
                                        </tr>
                                        <tr v-for="hiscore in hiscores" :key="hiscore.id">
                                            <td class="rank" :class="{ 'first': hiscore.rank === 1, 'second': hiscore.rank === 2, 'third': hiscore.rank === 3 }">
                                                {{ hiscore.rank }}
                                            </td>
                                            <td class="username" :class="{ 'first': hiscore.rank === 1, 'second': hiscore.rank === 2, 'third': hiscore.rank === 3 }">
                                                <router-link :to="'/hiscores/player/' + hiscore.username ">{{ hiscore.username }}</router-link>
                                            </td>
                                            <td class="level">{{ hiscore.total_level }}</td>
                                            <td class="right-col">{{ hiscore.total_experience }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <nav class="pagination-row-center" aria-label="Page navigation example">
                                <ul class="pagination m-auto">
                                    <!-- page = Math.max(1, page - 1) -->
                                    <li class="page-item">
                                        <a class="page-link" @click="previousPage" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo; Preivous Page</span>
                                        </a>
                                    </li>
                                    <li class="page-item active"><a class="page-link" href="#">{{ page }}</a></li>
                                    <li class="page-item">
                                        <a class="page-link" @click="nextPage" href="#" aria-label="Next">
                                            <span aria-hidden="true">Next Page &raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
</template>

<script>
import Loading from "@/components/Loading"
import APIClient from "@/services/APIClient.js"

export default {
    components: {
        Loading
    },
    created() {
        document.title = 'Hiscores - Near-Reality'

        this.apiClient = new APIClient();

        this.$watch(
            () => this.page,
            () => {
                if (this.page <= 0) {
                    this.page = 1
                }

                this.hiscores = []
                this.getHiscores(this.page)
            },
            { immediate: true }
        );
    },
    data() {
        return {
            apiClient: null,
            loading: true,
            hiscores: [],
            pageSize: 25,
            categories: [
                'Attack',
                'Defence',
                'Strength',
                'Hitpoints',
                'Ranged',
                'Prayer',
                'Magic',
                'Cooking',
                'Woodcutting',
                'Fletching',
                'Fishing',
                'Firemaking',
                'Crafting',
                'Smithing',
                'Mining',
                'Herblore',
                'Agility',
                'Thieving',
                'Slayer',
                'Farming',
                'Runecrafting',
                'Hunter',
                'Construction'
            ],
            playerSearch: ''
        }
    },
    // watch: {
    //     page(val) {
    //         this.getHiscores(val)
    //     }
    // },
    computed: {
        skill() {
            return this.$route.params.skill
        },
        page() {
            if (!this.$route.query.page) {
                return 1
            }

            const page = Number.parseInt(this.$route.query.page)
            if (Number.isNaN(page)) {
                return 1
            }

            return page
        }
    },
    methods: {
        getHiscores(page) {
            this.loading = true;

            this.apiClient.getHiscores(page).then((response) => {
                this.loading = false;
                this.hiscores = response.data;
            })
        },
        nextPage() {
            this.$router.push({ path: '/hiscores', query: { page: this.page + 1 } })
        },
        previousPage() {
            this.$router.push({ path: '/hiscores', query: { page: Math.max(1, this.page - 1) } })
        },
        search() {
            if (this.playerSearch === '') {
                return;
            }

            this.$router.push('/hiscores/player/' + this.playerSearch)
        }
    }
}
</script>
