<template>
    <main>
        <div class="container very-narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Create a Ticket</h3>
                </div>
                
                <p class="text-center mb-1">Need support or technical help? We're here to help you!</p>
                <p class="text-center">Submit a ticket and one of our staff members will get back to you.</p>

                <form @submit.prevent="create">
                    <div class="mb-3">
                        <label for="ticketChooseType" class="form-label">Ticket Type</label>
                        <select v-model="type" class="form-select" id="ticketChooseType" aria-label="Select a ticket type" required>
                            <option disabled selected :value="null">Select a ticket type</option>
                            <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="ticketDescription" class="form-label">Title</label>
                        <input v-model="title" class="form-control" type="text" id="ticketTitle" placeholder="Enter a brief description of your ticket.">
                    </div>
                    <div class="mb-3">
                        <label for="ticketDescription" class="form-label">Description / Message</label>
                        <textarea v-model="description" class="form-control" id="ticketDescription" rows="4" placeholder="Enter a message that one of our staff members will read before assisting you."></textarea>
                    </div>
                    <!-- <div class="mb-3">
                        <label for="ticketFileMultiple" class="form-label">Attachments / Media</label>
                        <input class="form-control" type="file" id="ticketFileMultiple" multiple>
                    </div> -->

                    <button type="submit" class="btn full-width large green">Create Ticket</button>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"

export default {
    created() {
        document.title = 'Create Ticket - Near-Reality'

        this.client = new APIClient()
    },
    data() {
        return {
            client: null,
            type: null,
            title: '',
            description: '',
            types: [
                'In-game Support',
                'Website Support',
                'General Inquiry',
                'Report Bug',
                'Report Player',
                'RSGP Store Purchases'
            ]
        }
    },
    methods: {
        create() {
            const payload = {
                'type': this.type,
                'title': this.title,
                'description': this.description
            }

            this.client.postTicket(payload).then(response => {
                this.$router.push('/account/tickets/')
            })
        }
    }
}
</script>
