import moment from 'moment';

export const dateMixin = {
    methods: {
        formatDate(date) {
            return moment(String(date)).format('MM/DD/YYYY hh:mm A')
        },
        formatDateSimple(date) {
            return moment(String(date)).format('MMMM YYYY')
        }
    }
}