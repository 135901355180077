<template>
    <main>
        <div class="container super-narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Reset Your Password</h3>
                </div>
                <p v-if="successMessage" class="success-message m-auto mb-4"><i class="fas fa-check-circle mr-1"></i>{{ successMessage }}</p>
                <div v-if="errorMessage" class="error-message mb-4">
                    <p>{{ errorMessage }}</p>
                </div>
                <p class="text-center">Enter the new password that you wish to set on your account. If we are able to properly authenticate your request, your password will be chagned and you should be able to login with your new credentials.</p>
                <form @submit.prevent="reset">
                    <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" v-model="password" class="form-control" id="password" placeholder="Enter password..." required>
                        <template v-if="errors.password">
                          <small v-for="error in errors.password" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                    </div>
                    <div class="mb-3">
                        <label for="passwordConfirmation" class="form-label">Password Confirmation</label>
                        <input type="password" v-model="passwordConfirmation" class="form-control" id="passwordConfirmation" placeholder="Confirm password..." required>
                        <template v-if="errors.password_confirmation">
                          <small v-for="error in errors.password_confirmation" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                    </div>
                    <button type="submit" class="btn full-width large green" :disable="loading">{{ loading ? 'Loading...' : 'Reset Password' }}</button>
                    <p class="text-center mt-3 mb-0">Need additional help? <a href="https://discord.com/invite/nrpk">Join Discord</a>.</p>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"

export default {
    created() {
        this.client = new APIClient()
    },
    data() {
        return {
            client: null,
            loading: false,
            password: '',
            passwordConfirmation: '',
            successMessage: null,
            errorMessage: null,
            errors: []
        }
    },
    methods: {
        reset() {
            this.loading = true;

            this.successMessage = null;
            this.errorMessage = null;

            const payload = {
                uid: this.$route.query.uid,
                token: this.$route.query.token,
                password: this.password,
                passwordConfirmation: this.passwordConfirmation
            }

            this.client.postResetPassword(payload).then((response) => {
                this.loading = false;

                if (response.status === 201) {
                    this.successMessage = 'Success! We were able to successfully reset your password, and you should now be able to log in using your new credentials.'
                } else {
                    this.errorMessage = 'Error! Looks like something went wrong on the server. Try again or contact a staff member for help.'
                }
            }).catch((err) => {
                this.loading = false;
                if(err.response.status === 429)
                  this.errors = {details: "You have been rate limited, please try again later."}
                else
                  this.errors = err.response.data
            })
        }
    }
}
</script>
