<template>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Product Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div class="store-modal--body">
                    <img class="product-image" :src="imageHref">
                    <div>
                        <h5>{{ name }}</h5>
                        <p class="category">{{ category }}</p>
                        <p v-if="ironman" class="ironman-availability">Available for <img src="@/assets/img/highscores/gamemodes/ironman.png">Ironman</p>
                        <p>{{ description }}</p>
                    </div>
                </div>
                <hr>
                <div class="store-modal--price">
                    <div class="store-product--block-price-row">
                        <img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy><p class="reduced">{{ saleEnabled ? salePrice : price }} <span v-if="saleEnabled">{{ price }}</span></p>
                    </div>
                    <a href="#" class="custom-button" :data-bs-target="`#productConfirmationModal-${productId}`" data-bs-toggle="modal" data-bs-dismiss="modal">Purchase</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        productId: Number,
        name: String,
        imageHref: String,
        category: String,
        ironman: Boolean,
        description: String,
        price: Number,
        salePrice: Number,
        saleEnabled: Boolean
    }
}
</script>