<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Privacy Policy</h3>
                </div>
                <p>Version 0.1</p>
                <p>Date: February 2022</p>
                <hr>
                <p>Near Reality respects the privacy of its players and recognizes the importance of providing a secure environment for their data. We have adopted this Privacy Policy (“Privacy Policy”) that explains how and why we collect, store and use the information you provide us.</p>
                <p>We treat all information associated with your account as personal information.</p>
                <p>We collect categories of data depending on which product(s) or service(s) you are interested in or interacting with.</p>
                <p>We usually retain data for only as long as necessary to provide you with products, services, and an immersive entertainment experience.</p>
                <hr>
                <h5 class="mt-4 mb-4">Privacy FAQ</h5>
                <p><u>Who is processing your data?</u></p>
                <p>Unity Games Studio is the data controller for the processing of your personal data. Your data may be controlled in the United Status for the performance of certain games and platform services.</p>
                <p><u>What information do we collect, and how long do we keep it?</u></p>
                <p>We collect information from or about you in the following ways:</p>
                <p><b>1. Information provided by you</b></p>
                <p>Information provided by you when you create or update your account(s); while you use our social features and channels to communicate with others; when you use any of our services for purchases or financial transactions such as store purchases, in-game purchases, and/or prize processing; and while you are receiving customer service support.</p>
                <p><b>2. Information we get or generate from your use of our games or services</b></p>
                <p>We collect data related to: your game play and in-game activity, the services that you use and how you use them, and your devices and activities when you install or access our services.</p>
                <p><b>3. Information collected from external sources</b></p>
                <p>We may receive information about you and your activities from third parties when you link to external accounts (such as YouTube or Twitch) and from advertisers or service providers about your experiences or interactions with them. We might also obtain information from public sites on the internet. For example, if you login to a site or app using Facebook Connect you may be asked if you wish to share information from your Facebook account with us.</p>
                <p>If you participate in a game or other feature where your score is posted on social media content, or if you use a “like” or a “share” button for a feature on our sites or apps, then the third party may share information with us.</p>
                <p><ul>Why do we process your data?</ul></p>
                <p>We collect and use your data for the following purposes:</p>
                <p><b>1. Gameplay Experience</b></p>
                <p>Creating the well-designed, highly enjoyable entertainment experiences you can find within our games!</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Progress and History</u>
                        <p>Examples of data used: Game stats, player history, quest progress, etc</p>
                
                    </li>
                    <li><u>In-game social</u>
                        <p>Examples of data used: Friend & ignore lists, friend chat, etc</p>
                
                    </li>
                    <li><u>Unlocks</u>
                        <p>Examples of data used: Inventory items, skills, etc</p>
                
                    </li>
                    <li><u>Game currency</u>
                        <p>Examples of data used: Earnings history, purchase history, etc</p>
                
                    </li>
                </ul>

                <p><b>2. Business optimization and service development</b></p>
                <p>We conduct troubleshooting, research, analysis and product and service development to provide you with the best gameplay experiences and services.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Analytics & Data Segmentation</u>
                        <p>Examples of data used: Account, country, IP address, chat records</p>
                
                    </li>
                    <li><u>Training & Development</u>
                        <p>Examples of data used: Character name, account notices, customer service chat log</p>
                
                    </li>
                    <li><u>Reporting</u>
                        <p>Examples of data used: Account region, preferred language, IP address</p>
                
                    </li>
                </ul>

                <p><b>3. Global Customer Service</b></p>
                <p>We provide you with personalized support to investigate and address any possible difficulty you might experience with our games, your account or your purchases.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Customer Service</u>
                        <p>Examples of data used: Country, language, region, chat records, etc</p>
                
                    </li>
                    <li><u>Moderation</u>
                        <p>Examples of data used: Chat records, forum posts, etc</p>
                
                    </li>
                </ul>

                <p><b>4. Business Operations</b></p>
                <p>We process orders for our products and services.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Store Purchase</u>
                        <p>Examples of data used: Email address, country, language, invoices, refund details, etc</p>
                
                    </li>
                </ul>

                <p><b>5. Social Features</b></p>
                <p>We enable communications and experience sharing between our players.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Chat Applications</u>
                        <p>Examples of data used: Chat records, friend chats, friend & ignore lists, etc</p>
                
                    </li>
                    <li><u>Site Membership</u>
                        <p>Examples of data used: Created support tickets, general inquiries, etc</p>
                
                    </li>
                    <li><u>Forums</u>
                        <p>Examples of data used: Character name, account, etc</p>
                
                    </li>
                </ul>

                <p><b>6. Communications with our players</b></p>
                <p>We communicate with our players about products, services, promotions, studies, surveys, news, updates and events. We may also use the information to promote and process contests and sweepstakes, fulfill any related awards and serve you relevant ads and content about our products and games.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Surveys</u>
                        <p>Examples of data used: Age, country, gender, IP address, etc</p>
                
                    </li>
                    <li><u>Analytics & Data Segmentation</u>
                        <p>Examples of data used: Country, related players, device ID, IP address, etc</p>
                
                    </li>
                    <li><u>Transactional and Publishing Campaigns and Promotions</u>
                        <p>Examples of data used: Country, email address, account region, etc</p>
                
                    </li>
                </ul>

                <p><b>7. Unethical Behavior Prevention</b></p>
                <p>We devote resources to minimize fraud, cheating and abusive behaviour.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Abuse and Cheat Detention</u>
                        <p>Examples of data used: Account name, IP address, device ID, chat records, related players, etc</p>
                
                    </li>
                    <li><u>Account Compromise Prevention</u>
                        <p>Examples of data used: Account name, country, date of birth, IP address, etc</p>
                
                    </li>
                    <li><u>Account Restrictions and Penalties</u>
                        <p>Examples of data used: Account restrictions history, penalty history, chat records, etc</p>
                
                    </li>
                </ul>
                <p><b>8. Legal proceedings and requirements</b></p>
                <p>We may use the information we collect to investigate or address claims or disputes relating to your use of our services or as otherwise allowed or required by applicable law.</p>
                <p>This includes:</p>
                <ul class="bulletpoints">
                    <li><u>Litigation or Pre-litigationn</u>
                        <p>Examples of data used: Account notes, case file, name, etc</p>
                
                    </li>
                    <li><u>Security Monitoring and Investigations</u>
                        <p>Examples of data used: Email address, country, IP address, device ID, chat records, etc</p>
                
                    </li>
                    <li><u>Legal Compliance</u>
                        <p>Examples of data used: Age, name, etc</p>
                
                    </li>
                </ul>
                <hr>
                <p><u>What are our legal bases for data processing?</u></p>
                <p>We process the above in accordance with the legal bases determined as follows:</p>
                <p><b>1. Necessary for the performance of your game contract or any other feature you request or enable.</b></p>
                <p>These are required, and ceasing their processing will remove access to certain features or to the game service altogether.</p>
                <p>This includes using information for:</p>
                <ul class="bulletpoints">
                    <li>Abuse and Cheat Detention and Sanctions</li>
                    <li>Account functionality</li>
                    <li>Account Compromise Prevention</li>
                    <li>Chat Applications</li>
                    <li>Contest Participation</li>
                    <li>Global Customer Service</li>
                    <li>Forums</li>
                    <li>Game functionality</li>
                    <li>Platform functionality</li>
                    <li>Enrolled promotions/contests</li>
                    <li>Purchases</li>
                </ul>

                <p><b>2. Legitimate interest.</b></p>
                <p>We use your data for purposes that are not harmful to your privacy and that can be reasonably expected within the context of your relationship with us.</p>
                <p>This includes using information for:</p>
                <ul class="bulletpoints">
                    <li>Ad Targeting via ‘Paid Media’</li>
                    <li>Ad Targeting via ‘Custom Audience’ and similar systems—Appropriate ads for players considering their previous purchases (Your unencrypted personal data are never shared with third parties without your consent.)</li>
                    <li>Analytics & Data Segmentation</li>
                    <li>Business Optimization and Service Development</li>
                    <li>Publishing Email - Marketing Campaigns</li>
                    <li>Research Groups</li>
                    <li>Training & Development</li>
                    <li>Customer Support</li>
                </ul>
            
                
                <p><b>3. Legal obligation.</b></p>
                <p>We process your data due to a legal obligation or right.</p>
                <p>This includes using information for:</p>
                <ul class="bulletpoints">
                    <li>Legal Compliance</li>
                    <li>Litigation or Pre-litigation</li>
                    <li>Security Monitoring and Investigations</li>
                </ul>

                <hr>
                <p><u>With whom may we share your data?</u></p>
                <p>Our websites have message boards, forums, and/or chat areas, where users can exchange ideas and communicate with one another. When posting to a message board, forum, or chat area, please be aware the information is being made publicly available on-line and the user does so at their own risk.</p>
                <p>Some of our products, services and features require that we share information with other users, our partners and service providers, or for legal reasons or in the event of a dispute.</p>
                <p>1. <b>With partners and service providers.</b> We may provide information to vendors, consultants, marketing partners, research firms and other service providers or business partners. For example, we may provide information to such parties to conduct surveys on our behalf or process payments for our products and/or games.</p>
                <p>2. <b>With other players and/or the general public.</b>  Information may be viewable by your friends, people in games, or the public in general if you play our games, use certain features in our games, use certain features on our platforms (such as chat applications in game) or make posts in the forums.</p>
                <p>3. <b>With subsidiaries and affiliates.</b> We share information with our subsidiaries and affiliates to help us provide our services or conduct data processing on our behalf.</p>
                <p>4. <b>For legal reasons or in the event of a dispute.</b> We may share your information if we believe it is required by applicable law, regulation, operating agreement, legal process or governmental request. This includes sharing your information with law enforcement officials, government authorities or other third parties as necessary to enforce our Terms of Service, user agreements or other policies to protect our rights or property or the rights or property of others, or in the event of a claim or dispute relating to your use of our services.</p>

                <p><u>What are tracking technologies, and how are they used?</u></p>
                <p>We, as well as our marketing partners, may use tracking technologies like cookies, beacons, tags, scripts and unique codes used in apps. These technologies are used in analyzing trends, administering the websites or apps, tracking users’ movements around the websites or apps and gathering demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual, as well as an aggregated, basis.</p>
                <p>We use this technology for various purposes, including our shopping cart, maintenance of user settings (e.g. language preference) and authentication. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our websites, but your ability to use some features or areas of our websites may be limited.</p>
                <p>We, and third parties with whom we partner to provide certain features on our websites or to display advertising based on your web browsing activity, use Local Shared Objects such as Flash cookies and Local Storage such as HTML5 to store content information and preferences. Various browsers may offer their own management tools for removing HTML5.</p>
                <p>We partner with third party ad networks to either display advertising on our websites or to manage our advertising on other sites. Our ad network partners may use tracking technologies to collect information about your activities on this and other websites to provide you targeted advertising based upon your interests.</p>
                <p><u>What level of security is applied to your personal information?</u></p>
                <p>To help protect the privacy of the data and personally identifiable information you provide to us and that we learn from you, we maintain physical, technical and administrative safeguards. We update and test our security technology on an ongoing basis. In addition, we will take reasonable steps to assure that third parties to whom we transfer any data will provide sufficient protection of personal information. We restrict access to your personal data to those employees indicated above. In addition, we train our employees about the importance of confidentiality and maintaining the privacy and security of your information.</p>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    created() {
        document.title = 'Privacy Policy - Near-Reality'
    }
}
</script>