<template>
	<div class="modal-dialog wide-width modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Custom Donations</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
					<i class="fas fa-times"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="card">
					<p
						class="large-para is--lighter text-center mb-0"
					>A Custom Donation is separate from a regular donation for donator pins or buying credits directly off the shop. The price is $200 dollars for one custom item of your choice and $400 dollars for one custom set of your choice. Other prices may vary according to the item. You will NOT receive any credits.</p>
				</div>
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 align-content-stretch flex-wrap">
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/sled.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Sled</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/zaniks-crate.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Zanik's Crate</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/skis.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Skis</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/flower-crown.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Flower Crown</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/jack-lantern-mask.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Jack Lantern Mask</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/scarves.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Scarves (Pick color)</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/grim-reaper-hood.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Grim Reaper Hood</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/haunted-wine-bottle.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Haunted Wine Bottle</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/cursed-banana.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Cursed Banana</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/cat-ears.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Cat Ears</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/hell-cat-ears.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Hell Cat Ears</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/propeller-hat.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Propeller Hat</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/banana-hat.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Banana Hat</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/candy-cane.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Candy Cane</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/thumb/Anger_sword_detail.png/150px-Anger_sword_detail.png?cae85"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Anger Sword</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/thumb/Anger_spear_detail.png/150px-Anger_spear_detail.png?531d1"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Anger Spear</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/thumb/Anger_mace_detail.png/150px-Anger_mace_detail.png?cae85"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Anger Mace</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/thumb/Anger_battleaxe_detail.png/150px-Anger_battleaxe_detail.png?8d292"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Anger Battleaxe</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/thumb/Boxing_gloves_%28red%29_detail.png/130px-Boxing_gloves_%28red%29_detail.png?fc234"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Boxing Gloves</h5>
							<p class="mb-0 text-bold text-primary">$200.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/thumb/Easter_basket_detail.png/150px-Easter_basket_detail.png?caf39"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Easter Basket</h5>
							<p class="mb-0 text-bold text-primary">$250.00</p>
						</div>
					</div>
				</div>
				<hr />
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 align-content-stretch flex-wrap">
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/skeleton-set.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Skeleton Set</h5>
							<p class="mb-0 text-bold text-primary">$400.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/chicken-outfit.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Chicken Outfit</h5>
							<p class="mb-0 text-bold text-primary">$400.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="@/assets/img/custom-orders/mime-outfit.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Mime Outfit</h5>
							<p class="mb-0 text-bold text-primary">$400.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/Gravedigger_mask_%28male%29_chathead.png?2aa72"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Gravedigger Outfit</h5>
							<p class="mb-0 text-bold text-primary">$400.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://oldschool.runescape.wiki/images/Banshee_mask_chathead.png?1daf8"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Banshee Outfit</h5>
							<p class="mb-0 text-bold text-primary">$400.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://i.imgur.com/C5ZMKgj.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Blue Ankou Outfit</h5>
							<p class="mb-0 text-bold text-primary">$450.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://i.imgur.com/gf6C9zE.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Green Ankou Outfit</h5>
							<p class="mb-0 text-bold text-primary">$450.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://i.imgur.com/w7KYZsq.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>Yellow Ankou Outfit</h5>
							<p class="mb-0 text-bold text-primary">$450.00</p>
						</div>
					</div>
					<div class="col">
						<div class="card text-center">
							<div
								class="card p-1 m-auto mb-3 d-flex flex-row align-items-center"
								style="height: 80px; width: 80px;"
							>
								<img
									src="https://i.imgur.com/vZyhCoA.png"
									class="img-fluid m-auto mh-100 object-fit-cover"
									lazy
								/>
							</div>
							<h5>White Ankou Outfit</h5>
							<p class="mb-0 text-bold text-primary">$450.00</p>
						</div>
					</div>
				</div>
				<div class="modal-footer-fixed">
					<hr />
					<h5 class="paragraph--heading text-center mb-3">
						Message
						<span class="rank-styles owner mb-0">Jacmob</span> (Jacmob#4915) on Discord to make a custom donation
					</h5>
					<div class="d-flex flex-row justify-content-center pb-4">
						<a
							href="https://discord.com/invite/nrpk"
							target="_blank"
							class="custom-button ml-2 mr-2"
							style="filter: hue-rotate(152deg);"
						>Join Discord</a>
						<a
							href="#"
							class="custom-button ml-2 mr-2"
							data-bs-dismiss="modal"
							aria-label="Close"
						>Back to Store</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>