<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="row flex-row d-flex justify-content-space-between top-page-divider">
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6 flex-column flex-lg-row justify-content-center justify-content-lg-start d-flex align-items-center">
                        <h3 class="page-title">Forums</h3>
                    </div>
                    
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <!-- <div class="page-title-box-right">
                            <input type="text" name="thread_search" id="search-forums" placeholder="Search Threads...">
                            <button type="submit" id="us-btn"><i class="fa fa-search"></i></button>
                        </div> -->
                    </div>

                </div>
                <ul class="forum--breadcrumb">
                    <li><router-link to="/forums">Forums</router-link></li>
                </ul>
                <section>
                    <Loading v-if="loading"/>
                    <details v-for="category in categories" :key="category.id" class="forum-category-group" open>
                        <summary class="forum-category-title">
                            <h5>{{ category.name }}</h5>
                        </summary>
                        <article v-for="forum in category.forums" :key="forum.id" class="forum-category-threads">
                            <router-link :to="`/forums/${forum.id}`">
                                <div class="category-col category-row--img">
                                    <div class="category-row--img-container">
                                        <img src="@/assets/img/forums/category-icon-gem.png" lazy>
                                    </div>
                                </div>
                                <div class="category-col category-row--desc">
                                    <h5 class="category-row--light-text">{{ forum.name }}</h5>
                                    <p class="category-row--faded-text">{{ forum.description }}</p>
                                </div>
                                <div class="category-col category-row--details">
                                    <span class="category-row--light-text">{{ forum.thread_count }}</span>
                                    <p class="category-row--faded-text">Threads</p>
                                </div>
                                <div class="category-col category-row--details">
                                    <span class="category-row--light-text">{{ forum.post_count }}</span>
                                    <p class="category-row--faded-text">Replies</p>
                                </div>
                                <div class="category-col category-row--latest">
                                    <span v-if="forum.latest_thread" class="category-row--light-text">{{ formatDate(forum.latest_thread.date) }}</span>
                                    <p v-if="forum.latest_thread" class="category-row--faded-text">Latest Thread: {{ forum.latest_thread.title }}</p>
                                    <p v-if="!forum.latest_thread" class="category-row--light-text">No threads found.</p>
                                </div>
                            </router-link>
                        </article>
                    </details>
                </section>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import moment from "moment"

export default {
    components: {
        Loading
    },
    created() {
        document.title = 'Forums - Near-Reality'

        this.client = new APIClient();
        this.getCategories();
    },
    data() {
        return {
            client: null,
            loading: true,
            categories: []
        }
    },
    methods: {
        getCategories() {
            this.client.getCategories().then((response) => {
                this.loading = false
                this.categories = response.data;
            })
        },
        formatDate(date) {
            return moment(String(date)).format('MM/DD/YYYY hh:mm')
        }
    }
}
</script>