<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Viewing Ticket</h3>
                    <p v-if="ticket" class="ticket-id-title">{{ ticket.title }}</p>
                </div>
                <Loading v-if="loading"/>
                <div v-else class="row">
                    <div class="col-12 col-md-12 col-lg-8 col-xl-9">
                        <router-link to="/account/tickets" class="text-uppercase mb-2"><i class="fas fa-long-arrow-alt-left mr-2"></i>See all Tickets</router-link>

                        <h5 class="ticket-title">Ticket Response:</h5>
                        <p class="mb-0">Read and respond to the ticket below</p>
                        <hr>
                        <div class="ticket-chat-log">
                            <div class="ticket-chat-message">
                                <div class="ticket-conversation">
                                    <p>{{ ticket.description }}</p>
                                </div>
                                
                                <div class="ticket-chat-message--footer">
                                    <p class="post-by">Created by <span class="rank-styles regular">{{ ticket.user }}</span> <span class="date">(Date: {{ formateDate(ticket.created) }})</span> </p>
                                </div>
                            </div>
                            <div v-for="comment in ticket.comments" :key="comment.id" class="ticket-chat-message">
                                <div class="ticket-conversation">
                                    <p>{{ comment.content }}</p>
                                </div>
                                
                                <div class="ticket-chat-message--footer">
                                    <p class="post-by">Responded by <span class="rank-styles" :class="{ 'administrator': comment.staff }">{{ comment.user }}</span> <span class="date">(Date: {{ formateDate(comment.date) }})</span> </p>
                                    <div v-if="comment.staff" class="rank-label rank-styles administrator">Staff</div>
                                </div>
                            </div>
                            <div v-if="ticket.closed" class="ticket-chat--announcement warning">
                                <i class="fas fa-exclamation-circle mr-2"></i>This ticket has been closed.
                            </div>
                        </div>

                        <div v-if="!ticket.closed">
                            <hr>
                            <form class="add-response" @submit.prevent="postComment">
                                <div class="mb-3">
                                    <label for="ticketDescription" class="form-label">Add a Message Response</label>
                                    <textarea v-model="comment" class="form-control" id="ticketDescription" rows="2" placeholder="Type a response..."></textarea>
                                </div>
                                <button type="submit" class="btn full-width">Submit Response</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col-xl-3">
                            <div class="ticket-tag--block">
                                <p class="ticket-tag--title">
                                    Ticket ID
                                </p>
                                <p class="ticket-tag--text">
                                    #{{ ticket.id }}
                                </p>
                            </div>
                            <div class="ticket-tag--block">
                                <p class="ticket-tag--title">
                                    Status
                                </p>
                                <p class="ticket-tag--text">
                                    <span class="status" :class="{ 'closed': ticket.closed, 'open': !ticket.closed }">{{ ticket.closed ? 'Closed' : 'Open'}}</span>
                                </p>
                            </div>
                            <div class="ticket-tag--block">
                                <p class="ticket-tag--title">
                                    Last Updated
                                </p>
                                <p class="ticket-tag--text">
                                    {{ formateDate(ticket.updated) }}
                                </p>
                            </div>
                            <div class="ticket-tag--block">
                                <p class="ticket-tag--title">
                                    Created on
                                </p>
                                <p class="ticket-tag--text">
                                    {{ formateDate(ticket.created) }}
                                </p>
                            </div>
                            <div v-if="$store.state.user.is_staff" class="card">
                                <h5 class="content-box--heading">Staff Controls</h5>
                                <button v-if="ticket.closed" @click="openTicket" class="btn full-width small">Open</button>
                                <button v-else @click="closeTicket" class="btn full-width red small">Close</button>
                            </div> 
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import moment from "moment"

export default {
    components: {
        Loading
    },
    created() {
        document.title = 'Ticket - Near-Reality'

        this.client = new APIClient();
        this.getTicket();
    },
    data() {
        return {
            client: null,
            loading: true,
            ticket: null,
            comment: ''
        }
    },
    methods: {
        getTicket() {
            this.client.getTicket(this.$route.params.id).then((response) => {
                this.ticket = response.data;
                this.loading = false;
            }).catch((err) => {
                if (err.response.status === 403) {
                    this.$router.push('/404')
                }
            })
        },
        postComment() {
            const payload = {
                ticket: this.ticket.id,
                content: this.comment
            }

            this.client.postComment(payload).then((response) => {
                if (response.status === 201) {
                    if (response.data.comment && this.ticket) {
                        this.ticket.comments.push(response.data.comment)
                    }
                }
            })
        },
        closeTicket() {
            const payload = {
                closed: true
            }

            this.client.patchTicket(this.ticket.id, payload).then((response) => {
                if (response.status === 200) {
                    this.ticket.closed = true;
                }
            })
        },
        openTicket() {
            const payload = {
                closed: false
            }

            this.client.patchTicket(this.ticket.id, payload).then((response) => {
                if (response.status === 200) {
                    this.ticket.closed = false;
                }
            })
        },
        formateDate(date) {
            const temp = moment(date).format('MMMM Do YYYY, h:mm a')
            return `${temp}`
        }
    }
}
</script>