<template>
    <main>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-heading">
                        <h2>Download <span>Near reality</span></h2>
                        <p>You can find numerous ways to Explore the world of Near Reality below. If you're unsure, and you're on Windows, try the exe installer, as it comes bundled with java.</p>
                        <p>It's a hassle free way to jump right into the game.</p>
                        <p>The <span>.jar</span> client should have functionality across all OS.</p>
                    </div>
                </div>
            </div>
            <div class="main-content-box">
            <h3 class="page-title center">Choose a client</h3>
                <div class="download--grid">
                    <div class="col">
                        <div class="card h-100 mb-0 download-box--current-os"> <!-- highlight glow for current OS -->
                            <div class="download-box">
                                <h5 class="content-box--heading">Windows<br>Installer</h5>
                                <p>
                                    <small>
                                        .exe | 28mb
                                    </small>
                                </p>
                                <i class="fab fa-windows download-box--icon"></i>
                                <div class="windows-buttons">
                                    <a href="https://static.near-reality.com/NearReality.exe" target="_blank" class="btn full-width download-box--link" file>
                                        32-BIT
                                    </a>
                                    <a href="https://static.near-reality.com/NearReality.exe" target="_blank" class="btn full-width download-box--link" file>
                                        64-BIT
                                    </a>
                                </div>
                                <hr>
                                <h6>Supports:</h6>
                                <ul>
                                    <li class="card p-1 mb-1 shadow-none">Windows 11</li>
                                    <li class="card p-1 mb-1 shadow-none">Windows 10</li>
                                    <li class="card p-1 mb-1 shadow-none">Windows 8</li>
                                    <li class="card p-1 mb-0 shadow-none">Windows 7</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div class="col">
                        <div class="card h-100 mb-0">
                            <div class="download-box">
                                <h5 class="content-box--heading">JAR<br>Launcher</h5>
                                <p>
                                    <small>
                                        .jar | 2.5MB
                                    </small>
                                </p>
                                <i class="fab fa-java download-box--icon"></i>
                                <a href="https://static.near-reality.com/NearReality.jar" target="_blank" class="btn full-width download-box--link" file>
                                    JAR LAUNCHER
                                </a>
                                <hr>
                                <h6>Requires:</h6>
                                <ul>
                                    <li class="card p-1 mb-1 shadow-none">Requires Java 11 installed on your system.</li>
                                    <li class="card p-1 mb-1 shadow-none"><a href="https://www.oracle.com/java/technologies/downloads/#java11-windows" target="_blank">Download Java 11<i class="fas fa-link ml-1"></i></a></li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    <div class="col">
                        <div class="card h-100 mb-0">
                            <div class="download-box">
                                <h5 class="content-box--heading">Mac<br>Launcher</h5>
                                <p>
                                    <small>
                                        .app.zip | 41MB
                                    </small>
                                </p>
                                <i class="fab fa-apple download-box--icon"></i>
                                <a href="https://static.near-reality.com/Near-Reality.app.zip" target="_blank" class="btn full-width download-box--link" file>
                                    MAC OSX
                                </a>
                                <hr>
                                <h6>Supports:</h6>
                                <ul>
                                    <li class="card p-1 mb-1 shadow-none">macOS 12</li>
                                    <li class="card p-1 mb-1 shadow-none">macOS 11</li>
                                    <li class="card p-1 mb-1 shadow-none">macOS 10.15</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    created() {
        document.title = 'Play - Near-Reality'
    }
}
</script>
