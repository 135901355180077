<template>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Two Factor Authentication - Deactivate</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div v-if="form.errorMessage" class="mb-3 error-message text-center">
                    <p>{{ form.errorMessage }}</p>
                </div>
                <div>
                    <p>To deactivate two-factor authentication on your account, please provide the code generated by your authentication provider below.</p>
                    <form>
                        <label class="form-label">Current Authentication Code</label>
                        <input v-model="form.data.code" required placeholder="Enter the 6-digit code shown in your authentication app" class="form-control mb-0">
                    </form>
                </div>
                <hr>
                <div>
                    <button @click="deactivate" class="btn red">
                        <div v-if="form.processing" class="spinner-border spinner-border-sm mr-2"/>
                        Deactivate
                    </button>
                    <a class="btn" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APIClient from "@/services/APIClient.js"

export default {
    created() {
        this.apiClient = new APIClient()
    },
    data() {
        return {
            apiClient: null,
            form: {
                errorMessage: '',
                processing: false,
                data: {
                    code: ''
                }
            }
        }
    },
    methods: {
        deactivate() {
            this.form.errorMessage = ''
            this.form.processing = true
            
            this.apiClient.postTwoFactorDeactivation(this.form.data).then((response) => {
                this.form.processing = false

                if (response.data && response.data.success) {
                    this.$emit('deactivated')
                }
            }).catch((err) => {
                this.form.processing = false

                if (err.response.data && err.response.data.error) {
                    this.form.errorMessage = err.response.data.error
                }
            })
        }
    }
}
</script>