<template>
    <main>
        <div class="container narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <div class="ticket-article--header">
                        <h3 class="page-title">Ticket Support Article Title Here</h3>
                        <div class="profile-avatar center mt-4">
                            <div class="avatar-image small">
                                <img class="rank-icon" src="resources/img/ranks/admin.png">
                            </div>
                                
                                <div>
                                    <h5 class="mb-0 rank-styles admin">Player Name</h5>
                                    <p class="mt-0">Created: 03/02/2022 7:32 PM</p>
                                </div>
                        </div>
                    </div>
                </div>

                <div>
                    <p>Aliquip pariatur culpa deserunt proident amet deserunt ut. Non fugiat ipsum ea nulla aute ex dolor esse ullamco cupidatat cillum adipisicing. Enim do qui quis laborum aute. Enim sint consectetur cupidatat laboris proident id mollit amet anim laboris enim sit laboris. Non dolor fugiat esse incididunt ex irure ea amet dolor et incididunt aliquip tempor do. Proident reprehenderit laborum non non pariatur id.</p>

                </div>
                <hr>
                <section class="still-having-issues-section">
                    <h4>Still having issues?</h4>
                    <p>Create a support ticket and one of our team will get back to you.</p>
                    <a href="#" class="btn green">Create Ticket</a>
                </section>
                <hr>
                    <a href="#" class="btn large dark full-width">Go back to all support articles</a>

                
            </div>
        </div>
    </main>
</template>