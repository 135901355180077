<template>
    <div class="modal-dialog modal-dialog-centered store-modal--confirmation">
        <div class="modal-content red">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            <div class="modal-body">
                <div>
                    <h3 class="content-box--heading center">Are you sure?</h3>
                    <div class="store-modal--price">
                        <img :src="imageHref" class="product-image" lazy>
                        <div class="store-product--block-price-row">
                            <img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy><p class="reduced">{{ saleEnabled ? salePrice : price }} <span v-if="saleEnabled">{{ price }}</span></p>
                        </div>
                    </div>
                    <hr>
                    <div class="store-modal--confirmation-buttons">
                        <button @click="purchase" data-bs-dismiss="modal" class="btn full-width large green">Yes</button>
                        <a class="btn full-width large red" data-bs-dismiss="modal" aria-label="Close">No</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APIClient from "@/services/APIClient.js"

export default {
    props: {
        productId: Number,
        imageHref: String,
        price: Number,
        salePrice: Number,
        saleEnabled: Boolean
    },
    created() {
        this.client = new APIClient();
    },
    data() {
        return {
            client: null
        }
    },
    methods: {
        purchase() {
            this.$emit('purchase', { id: this.productId, price: this.saleEnabled ? this.salePrice : this.price })
        }
    }
}
</script>