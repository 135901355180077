<template>
    <main>
        <div class="container super-narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Request Password Reset</h3>
                </div>
                <p v-if="successMessage" class="success-message m-auto mb-4"><i class="fas fa-check-circle mr-1"></i>{{ successMessage }}</p>
                <div v-if="errorMessage" class="error-message mb-4">
                    <p>{{ errorMessage }}</p>
                </div>
                <p class="text-center">Enter the username of the account that you intend to reset the password for. If we recognize your username as a valid account in our system, you will be sent an email with instructions to reset your password.</p>
                <form @submit.prevent="reset">
                    <div class="mb-3">
                        <label for="username" class="form-label">Username</label>
                        <input type="text" v-model="username" class="form-control" id="username" placeholder="Enter username..." required>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="text" v-model="email" class="form-control" id="email" placeholder="Enter email..." required>
                    </div>
                    <button type="submit" class="btn full-width large green" :disable="loading">{{ loading ? 'Loading...' : 'Reset Password' }}</button>
                    <p class="text-center mt-3 mb-0">Need additional help? <a href="https://discord.com/invite/nrpk">Join Discord</a>.</p>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"

export default {
    created() {
        this.client = new APIClient()
    },
    data() {
        return {
            client: null,
            loading: false,
            username: '',
            email: '',
            successMessage: null,
            errorMessage: null
        }
    },
    methods: {
        reset() {
            this.loading = true;

            this.successMessage = null;
            this.errorMessage = null;

            const payload = {
                username: this.username,
                email: this.email
            }

            this.client.postRequestPasswordReset(payload).then((response) => {
                this.loading = false;

                if (response.status === 200) {
                    this.successMessage = 'Success! If the provided username belonged to an active account, you should have received an email with instructions on how to reset your password.'
                } else {
                    this.errorMessage = 'Error! Looks like something went wrong on the server. Try again or contact a staff member for help.'
                }
            }).catch((err) => {
              this.loading = false
              this.errorMessage = err.response.data.details
            })
        }
    }
}
</script>
