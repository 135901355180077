<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Latest News & Updates</h3>
                    <p>Viewing: {{ monthNames[month - 1] }} {{ year }}</p>
                </div>

                <div class="news-archives">
                    <div class="news-archives--list year-list">
                        <router-link :to="{ query: { year: 2022, month: month } }" class="btn" :class="{ 'active': year === 2022 }">2022</router-link>
                    </div>
                    <div class="news-archives--list year-list">
                        <router-link :to="{ query: { year: year, month: monthToNumber(monthName) } }" class="btn" :class="{ 'active': monthName === monthNames[month - 1] }" v-for="monthName in monthNames" :key="monthName">{{ monthName }}</router-link>
                    </div>
                </div>

                <div class="top-page-divider reverse">
                </div>
                    
                <div class="news-archive">
                    <Loading v-if="loading"/>

                    <div v-if="!loading && !news.length" class="error-message text-center">No archived news found for {{ monthNames[month - 1] }} {{ year }}.</div>

                    <article v-for="post in news" :key="post.id" class="news-archive--article">
                        <div class="news-archive--image">
                            <router-link :to="`/forums/thread/${post.thread.id}`">
                                <img :src="post.image" lazy>
                            </router-link>
                        </div>
                        <div class="news-archive--post">
                            <router-link :to="`/forums/thread/${post.thread.id}`"><h5>{{ post.thread.title }}</h5></router-link>
                            <div class="news-archive--post--meta">
                                <p class="news-archive--post--meta-date">{{ post.thread.date }}</p>
                                <p class="news-archive--post--meta-category">{{ post.thread.forum }}</p>
                            </div>
                            <div class="news-archive--post--description">
                                <p>{{ post.thread.preview }}</p>
                            </div>
                            <router-link :to="`/forums/thread/${post.thread.id}`" class="btn small">Read More</router-link>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import moment from 'moment'

export default {
    components: {
        Loading
    },
    created() {
        document.title = 'News Archive - Near-Reality'

        this.client = new APIClient()
        this.getNews()

        this.$watch('year', () => { this.getNews() })
        this.$watch('month', () => { this.getNews() })
    },
    computed: {
        year() {
            return Number.parseInt(this.$route.query.year) || moment().year()
        },
        month() {
            return Number.parseInt(this.$route.query.month) || moment().month()
        }
    },
    data() {
        return {
            client: null,
            loading: true,
            monthNames: moment.months(),
            news: [],
        }
    },
    methods: {
        getNews() {
            this.loading = true
            
            this.client.getNews(this.year, this.month).then((response) => {
                this.loading = false;
                this.news = response.data
            }).catch((err) => {
                this.loading = false
            })
        },
        monthToNumber(month) {
            return moment().month(month).format('M')
        }
    }
}
</script>