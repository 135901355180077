<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="row flex-row d-flex justify-content-between top-page-divider">
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6 flex-column flex-lg-row justify-content-center justify-content-lg-start d-flex align-items-center">
                        <h3 class="page-title">Forums</h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="page-title-box-right">
                            <input type="text" name="thread_search" id="search-forums" placeholder="Search Threads...">
                            <button type="submit" id="us-btn"><i class="fa fa-search"></i></button>
                        </div>
                    </div>

                </div>
                <ul class="forum--breadcrumb">
                    <li><router-link to="/forums">Forums</router-link></li>
                    <li>
                    <a href="#">News Discussion</a>
                    </li>
                </ul>
                <div class="forum-threads-heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
                    <h5 class="mb-2 mb-md-0">News Discussion</h5>
                    <router-link :to="`/forums/${this.$route.params.id}/create`" class="btn small">Create Thread</router-link>
                </div>

                <Loading v-if="loading"/>

                <div v-if="!loading && !threads.length" class="mb-3 error-message text-center">
                    <p>No forum threads found.</p>
                </div>

                <section v-if="threads">
                    <article v-for="thread in threads" :key="thread.id" class="forum-threads-row">
                        <router-link :to="`/forums/thread/${thread.id}`">
                            <div class="thread-col thread-row--actions">
                            <i v-if="thread.closed" class="fas fa-lock" data-toggle="tooltip" data-placement="top" title="Closed"></i>
                            <i v-if="thread.pinned" class="fas fa-thumbtack" data-toggle="tooltip" data-placement="top" title="Pinned"></i>
                            </div>
                            <div class="thread-col thread-row--desc">
                                <h5 class="category-row--light-text">{{ thread.title }}</h5>
                                <p class="category-row--faded-text">Created by {{ thread.user.name }}</p>
                            </div>
                            <div class="thread-col thread-row--details">
                                <span class="category-row--light-text">{{ thread.post_count }}</span>
                                <p class="category-row--faded-text">Posts</p>
                            </div>
                            <div class="thread-col thread-row--latest">
                                <span v-if="thread.latest_post" class="category-row--light-text">{{ formatDate(thread.date) }}</span>
                                <p v-if="thread.latest_post" class="category-row--faded-text">Latest Post by {{ thread.user.name }}</p>
                                <p v-if="!thread.latest_post" class="category-row--light-text">No posts found.</p>
                            </div>
                        </router-link>
                    </article>
                </section>
                <nav class="pagination-row-center w-100 mt-4" aria-label="Page navigation example">
                    <ul class="pagination m-auto">
                        <li class="page-item" :class="{ 'disabled': !nextPage }">
                            <router-link :to="{ query: { page: Math.max(1, page - 1) } }" class="page-link" aria-label="Previous">
                                <span aria-hidden="true">&laquo; Previous Page</span>
                            </router-link>
                        </li>
                        <li class="page-item active"><a class="page-link" href="#">{{ page }}</a></li>
                        <li class="page-item" :class="{ 'disabled': !previousPage }">
                            <router-link :to="{ query: { page: page + 1 } }" class="page-link" aria-label="Next">
                                <span aria-hidden="true">Next Page &raquo;</span>
                            </router-link>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import { dateMixin } from "@/mixins/dateMixin.js"

export default {
    components: {
        Loading
    },
    mixins: [ dateMixin ],
    created() {
        document.title = 'Forums - Near-Reality'

        this.client = new APIClient()
        this.getForumThreads(this.page);

        this.$watch('page', (page) => { this.getForumThreads(page) }, { immediate: true })
    },
    data() {
        return {
            client: null,
            loading: true,
            threads: [],
            previousPage: null,
            nextPage: null
        }
    },
    computed: {
        page() {
            return Number.parseInt(this.$route.query.page) || 1
        }
    },
    methods: {
        getForumThreads(page) {
            this.client.getForumThreads(this.$route.params.id, page).then((response) => {
                this.loading = false
                this.threads = response.data.results

                this.previousPage = response.data.previous
                this.nextPage = response.data.next
            }).catch((err) => {

            })
        }
    }
}
</script>
