<template>
    <main>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-heading">
                        <h2><span>Near reality</span> Partnerships</h2>
                        <p>Near Reality is proud to partner with Mafioso Raids and Mafioso Services.</p>
                    </div>
                </div>
            </div>
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Discord Servers</h3>
                </div>
            
                <div class="partnerships--grid">
                    <div class="partnerships--col card">
                        <img src="@/assets/img/mafioso-discord-icon.gif" class="discord-server-icon">
                        <h5>Mafioso Services</h5>
                        <a href="https://discord.com/invite/Mafioso" target="_target" class="btn large discord"><i class="fab fa-discord"></i>Join Discord</a>
                    </div>
                    <div class="partnerships--col card">
                        <img src="@/assets/img/mafioso-raids-discord-icon.png" class="discord-server-icon">
                        <h5>Mafioso Raids</h5>
                        <a href="https://discord.com/invite/Raids" target="_target" class="btn large discord"><i class="fab fa-discord"></i>Join Discord</a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>