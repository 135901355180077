<template>
    <main>
        <div class="container super-narrow">
            <div class="main-content-box text-center">
                <div class="top-page-divider">
                    <h3 class="page-title center">Registration Failed</h3>
                </div>
              <p class="error-message m-auto mb-4"><i class="fas fa-times-circle mr-1"></i>Something went wrong in the creation of your account!</p>
                <p>It might be the registration token send to your email has expired!</p>
                <router-link to="/register" class="btn large mb-4">Try again</router-link>
            </div>
        </div>
    </main>
</template>
