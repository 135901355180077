<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title">Game Support & Tickets</h3>
                </div>

                <div class="knowledge-base-section">
                    <a href="#" class="knowledge-base--block">
                        <h5 class="content-box--heading">Billing<br>Help</h5>
                        <div class="knowledge-base--block--icon">
                            <img src="@/assets/img/tickets/billing.png">
                        </div>
                    </a>
                    <a href="#" class="knowledge-base--block">
                        <h5 class="content-box--heading">Technical<br>Support</h5>
                        <div class="knowledge-base--block--icon">
                            <img src="@/assets/img/tickets/technical.png">
                        </div>
                    </a>
                    <a href="#" class="knowledge-base--block">
                        <h5 class="content-box--heading">Account<br>Recovery</h5>
                        <div class="knowledge-base--block--icon">
                            <img src="@/assets/img/tickets/recover.png">
                        </div>
                    </a>
                    <a href="#" class="knowledge-base--block">
                        <h5 class="content-box--heading">Report a<br>Player</h5>
                        <div class="knowledge-base--block--icon">
                            <img src="@/assets/img/tickets/report.png">
                        </div>
                    </a>
                </div>
            </div>

            <div class="submit-ticket-section">
                <h4>Can't find what your looking for?</h4>
                <p>Create a support ticket and one of our team will get back to you.</p>
                <router-link to="/tickets/create" class="btn large green">Create Ticket</router-link>
            </div>

        </div>
    </main>
</template>

<script>
export default {
    created() {
        document.title = 'Tickets - Near-Reality'
    }
}
</script>