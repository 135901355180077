<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="row flex-row d-flex justify-content-space-between top-page-divider">
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <h3 class="page-title">Purchase History</h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="page-title-box-right">
                            <router-link to="/account" class="btn"><i class="fas fa-long-arrow-alt-left mr-2"></i>Back to Account</router-link>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <div class="col">
                        <div class="card purchased-credits-block">
                            <div class="purchased-credits-block--icon"><i class="far fa-credit-card"></i></div>
                            <h5 class="content-box--heading">Total Spent</h5>
                            <div class="purchased-credits-block--amount"><p>${{ $store.state.user.totalSpent }}</p></div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card purchased-credits-block">
                            <div class="purchased-credits-block--icon"><i class="fas fa-coins"></i></div>
                            <h5 class="content-box--heading">Available Credits</h5>
                            <div class="purchased-credits-block--amount"><p>{{ $store.state.user.storeCredits }}</p></div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card purchased-credits-block">
                            <div class="purchased-credits-block--icon"><i class="fas fa-coins"></i></div>
                            <h5 class="content-box--heading">Redeemed Credits</h5>
                            <div class="purchased-credits-block--amount"><p>0</p></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-4">
                            <h5 class="content-box--heading">Your Purchases</h5>
                            <ul class="nav nav-tabs nav-fill" id="userActivity" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <div class="btn nav-link" :class="{ 'active': type === 'credits' }" id="credit-purchases" role="tab" aria-controls="profile" aria-selected="false"><i class="far fa-credit-card mr-2"></i> Credit Purchases</div>
                                </li>
                            </ul>

                            <div class="tab-content" id="userActivityContent">
                                <div class="tab-pane fade show active" id="purchases" role="tabpanel" aria-labelledby="purchases-tab">
                                    <div class="table-overflow">
                                        <table class="purchaseHistory">
                                            <thead>
                                                <tr>
                                                    <th class="product">
                                                        Product
                                                    </th>
                                                    <th class="cost">
                                                        Cost
                                                    </th>
                                                    <th class="time">
                                                        Time
                                                    </th>
                                                    <th class="status">
                                                        Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="purchases.length <= 0">
                                                    <td colspan="4" class="text-center">No purchase history found.</td>
                                                </tr>
                                                <tr v-for="purchase in purchases" :key="purchase.id" :class=" purchase.status === 'PAID' ? 'claimed' : 'pending' ">
                                                    <td class="product">
                                                        {{ purchase.creditPackage.credits }}x {{ purchase.creditPackage.bonusCredits > 0 ? `(+${purchase.creditPackage.bonusCredits} Bonus)`: '' }} Credit Package
                                                    </td>
                                                    <td class="cost">
                                                        {{ purchase.creditPackage.price }}
                                                    </td>
                                                    <td class="time">
                                                        {{ convertDate(purchase.time) }}
                                                    </td>
                                                    <td class="status">
                                                        {{ purchase.status }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- <nav class="pagination-row-center w-100" aria-label="Page navigation example">
                                        <ul class="pagination m-auto">
                                            <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                            </li>
                                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                            </li>
                                        </ul>
                                    </nav> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import { ref } from 'vue'
import APIClient from "@/services/APIClient.js"

//get the apiclient
const apiClient = new APIClient()

const purchases = ref([])
apiClient.getStorePurchases('credits').then(response => {
    purchases.value = response.data
console.log(purchases.value[0])
})

const convertDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
}



</script>
