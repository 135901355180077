<template>
    <div class="modal-dialog modal-dialog-centered">
        <div v-if="product" class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Credit Payment - Stripe</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div class="credit-modal--body mb-2">
                    <ul>
                        <li><p>Current Balance:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ balance }} Credits</p></li>
                        <li><p>Order:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ product.amount }} {{ product.bonus_amount > 0 ? `(+${product.bonus_amount} Bonus)` : '' }} Credits</p></li>
                        <li class="new-balance"><p>New Balance:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ balance + product.amount + product.bonus_amount}} Credits</p></li>
                        <li><hr></li>
                        <li class="total"><p>Total:</p><p>${{ product.price }} USD</p></li>
                        <li class="payment-method"><p>Payment Method:</p><p class="stripe-color">Stripe</p></li>
                    </ul>
                </div>
                <hr>
                <form @submit.prevent="purchase">
                    <div class="mb-3">
                        <label for="fullName" class="form-label">Full Name</label>
                        <input v-model="fullName" type="text" class="form-control" id="fullName" required>
                    </div>
                    <div class="mb-3">
                        <label for="loginPassword" class="form-label">Email</label>
                        <input v-model="email" type="email" class="form-control" id="email" required>
                    </div>
                    <div class="mb-3">
                        <label for="card-element" class="form-label">Card Information</label>
                        <div id="card-element" class="form-control mb-0"></div>
                        <small class="text-muted">Note: Your payment information is never stored on our servers and is only sent to Stripe for processing, a global service trusted by millions.</small>
                    </div>
                    <hr>
                    <div class="text-center">
                        <!-- <button @click="createPurchase" class="btn mr-2 stripe-color">Pay $100.00 with Stripe</button> -->
                        <LoadingButton :loading="loading" :classes="['mr-2', 'stripe-color']">Pay ${{ product.price }} with Stripe</LoadingButton>
                        <a class="btn red" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                    </div>
                </form>
            </div>
        </div>
        <div v-else class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Credit Payment - Stripe</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <p>Sorry, but it looks like we ran into trouble while creating your PayPal checkout session. Please report this to a staff member for help.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.stripe-color {
    color: #4379FF;
}
</style>

<script>
import LoadingButton from "@/components/LoadingButton"
import APIClient from "@/services/APIClient.js"
import { loadStripe } from '@stripe/stripe-js';
import { nextTick } from 'vue'

export default {
    components: {
        LoadingButton
    },
    props: {
        product: Object,
        balance: Number
    },
    watch: {
        async product(newVal, oldVal) {
            if (newVal) {
                var style = {
                    base: {
                        iconColor: '#4379FF',
                        color: '#4379FF',
                        fontWeight: '500',
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '16px',
                        padding: '10px',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                            color: '#4379FF',
                        },
                        '::placeholder': {
                            color: '#4379FF',
                        },
                    },
                };
                await nextTick()
                const elements = this.stripe.elements();
                const card = elements.create('card', { style: style })
                this.card = card;
                card.mount('#card-element')
            }
        }
    },
    created() {
        this.client = new APIClient();
        loadStripe('pk_live_NS7OnJpbGXJHwPfF5wPqPSSf00QSTM4Y2n').then((result) => {
            this.stripe = result
        })
    },
    data() {
        return {
            client: null,
            loading: false,
            stripe: null,
            card: null,
            fullName: '',
            email: '',
            errorMessage: '',
            successMessage: ''
        }
    },
    methods: {
        purchase() {
            const payload = {
                id: this.product.id,
                method: 'stripe'
            }

            this.loading = true;

            this.client.postCreditPurchase(payload).then((response) => {
                this.loading = false;
                if (response.status === 201) {
                    const clientSecret = response.data.client_secret
                    this.stripe.confirmCardPayment(clientSecret, {
                        payment_method: {
                            card: this.card,
                            billing_details: {
                                name: this.fullName,
                                email: this.email
                            }
                        }
                    }).then((result) => {
                        if (result.error) {
                            this.errorMessage = result.error.message
                        } else {
                            this.$router.push('/store/success')
                        }
                    })
                }
            })
        }
    }
}
</script>