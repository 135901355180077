export const rankMixin = {
    methods: {
        getDisplayedRank(rank, memberRank) {
            if (rank !== undefined || memberRank !== undefined) {
                if (rank === 'PLAYER') {
                    if (memberRank === 'NONE') {
                        return rank;
                    } else {
                        return memberRank.toLowerCase();
                    }
                } else {
                    return rank.toLowerCase();
                }
            }
        },
        getRankSelector(rank, memberRank) {
            if (rank !== undefined || memberRank !== undefined) {
                if (rank === 'PLAYER') {
                    return memberRank.toLowerCase();
                } else {
                    return rank.toLowerCase();
                }
            }
        }
    }
}
