<template>
    <main>
        <div class="container super-narrow">
            <div class="main-content-box text-center">
                <div class="top-page-divider">
                    <h3 class="page-title center">Welcome to Near Reality</h3>
                </div>
                <p class="success-message m-auto mb-4"><i class="fas fa-check-circle mr-1"></i>Account Successfully Created!</p>
                <p>You can now begin your adventure on Near Reality!</p>
                <div class="welcome-page--icon mt-4 mb-4">
                    <img src="@/assets/img/welcome-icon.png" eager>
                </div>
                <router-link to="/login" class="btn large mb-4">Login to your account</router-link>
                <p>Ready to get started? <router-link to="/play">Download Near-Reality</router-link>.</p>
            </div>
        </div>
    </main>
</template>