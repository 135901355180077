<template>
    <div class="text-center p-5">
        <div class="spinner spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h3 class="mt-4 page-title">Loading...</h3>
    </div>
</template>

<style scoped>
.spinner {
    width: 10rem;
    height: 10rem; 
}
</style>