<template>
    <div class="page-404-load"></div>
    <main class="page-404-flex text-center">
        <div class="container super-narrow">
            <div class="main-content-box">
                <div class="error-404">
                    <div class="top-page-divider">
                        <img src="@/assets/img/logo.png" class="w-100 m-auto mb-4" eager>
                        <h1>404</h1>
                        <h5 class="page-title">Page Not Found</h5>
                    </div>
                    <router-link class="btn full-width green small" to="/"><i class="fas fa-long-arrow-alt-left mr-2"></i>Return Home</router-link>
                </div>
            </div>
        </div>
    </main>
</template>
