<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="row flex-row d-flex justify-content-space-between top-page-divider">
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6 flex-column flex-lg-row justify-content-center justify-content-lg-start d-flex align-items-center">
                        <h3 class="page-title">Forums</h3>
                    </div>
                    
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="page-title-box-right">
                            <input type="text" name="thread_search" id="search-forums" placeholder="Search Threads...">
                            <button type="submit" id="us-btn"><i class="fa fa-search"></i></button>
                        </div>
                    </div>

                </div>
                <ul v-if="thread" class="forum--breadcrumb">
                    <li>
                    <router-link to="/forums">Forums</router-link>  
                    </li>
                    <li>
                    <router-link :to="`/forums/${thread.forum.id}`">{{ thread.forum.name }}</router-link>  
                    </li>
                    <li>
                    <router-link :to="$route.path">{{ thread.title }}</router-link>  
                    </li>
                </ul>
                <h5 v-if="thread" class="content-box--heading">{{ thread.title }}</h5>
                <section v-if="thread">
                    <div class="row g-4 mb-4">
                        <div class="col-12 col-md-4 text-center text-md-left">
                            <p v-if="thread.closed || thread.pinned">Thread Status:</p>
                            <div class="thread-row--actions">
                                <i v-if="thread.closed" class="fas fa-lock" data-toggle="tooltip" data-placement="top" title="Closed"/>
                                <i v-if="thread.pinned" class="fas fa-thumbtack" data-toggle="tooltip" data-placement="top" title="Pinned"/>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-md-8 text-center text-md-right">
                            <p>Moderator Controls</p>
                            <div class="forum-posts--staff-controls">
                                <button class="btn small"><i class="fas fa-lock mr-2"></i>Lock Thread</button>
                                <button class="btn small"><i class="fas fa-eye-slash mr-2"></i>Hide Thread</button>
                                <button class="btn small red"><i class="far fa-trash-alt mr-2"></i>Delete Thread</button>
                            </div>
                        </div> -->
                    </div>
                </section>
                <section class="forum-posts">
                    <Loading v-if="loading"/>
                    <div v-if="thread && thread.page" class="content-box">
                        <div v-if="!thread.page.posts.length" class="error-message text-center">No posts found.</div>
                        <template v-for="post in thread.page.posts" :key="post.id">
                            <ForumPost :user="post.user" :content="post.content" :date="post.date"/>
                        </template>
                    </div>
                </section>
                <nav v-if="thread" class="pagination-row-center w-100 mt-4" aria-label="Page navigation example">
                    <ul class="pagination m-auto">
                        <li class="page-item" :class="{ 'disabled': !thread.page.previous_page }">
                            <!-- v-if="thread.page.previous_page" -->
                            <a class="page-link" href="#" @click="previousPage" aria-label="Previous">
                                <span aria-hidden="true">&laquo; Previous Page</span>
                            </a>
                        </li>
                        <li class="page-item active"><a class="page-link" href="#">{{ page }}</a></li>
                        <li class="page-item" :class="{ 'disabled': !thread.page.next_page }">
                            <!-- v-if="thread.page.next_page" -->
                            <a class="page-link" href="#" @click="nextPage" aria-label="Next">
                                <span aria-hidden="true">Next Page &raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <hr>
                <section v-if="thread">
                    <div v-if="thread.closed" class="forum-post-announcement closed">
                        <i class="fas fa-exclamation-circle mr-2"></i>This thread has been closed.
                    </div>
                    <div v-if="!thread.closed && !($store.getters.isLoggedIn)" class="forum-post-announcement login">
                        <i class="fas fa-sign-in-alt mr-2"></i>Please <router-link to="/login">Login</router-link> to add a reply.
                    </div>
                </section>
                <section v-if="thread && !thread.closed">
                    <form @submit.prevent="submitPost" class="forum-reply">
                        <ForumPostEditor v-model:editorContent="editorContent" :content="editorContent"/>
                        <!-- <button type="submit" class="btn full-width">Submit Reply</button> -->
                        <div v-if="successMessage" class="mb-3 success-message text-center">
                            <p>{{ successMessage }}</p>
                        </div>
                        <div v-if="submissionError" class="mb-3 error-message text-center">
                            <p>{{ submissionError }}</p>
                        </div>
                        <LoadingButton :loading="loadingSubmission" :classes="['full-width']">Submit Post</LoadingButton>
                    </form>
                </section>

            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import ForumPost from "@/views/forums/ForumPost"
import ForumPostEditor from "@/views/forums/ForumPostEditor"
import LoadingButton from "@/components/LoadingButton"

export default {
    components: {
        Loading,
        ForumPost,
        ForumPostEditor,
        LoadingButton
    },
    created() {
        document.title = 'Forums - Near-Reality'

        this.client = new APIClient();
        this.getForumThread(this.page);

        this.$watch('page', (page) => { this.getForumThread(page) }, { immediate: true })
    },
    mounted() {
        this.errorToast = new window.bootstrap.Toast(this.$refs.toast)
    },
    computed: {
        page() {
            return Number.parseInt(this.$route.query.page) || 1
        }
    },
    data() {
        return {
            client: null,
            loading: false,
            loadingSubmission: false,
            submissionError: '',
            thread: null,
            editorContent: '<p>Click here and type your message.</p>',
            errorToast: '',
            successMessage: null
        }
    },
    methods: {
        getForumThread(page) {
            this.loading = true

            this.client.getForumThread(this.$route.params.id, page).then((response) => {
                this.loading = false;
                this.thread = response.data
            }).catch((err) => {
                this.loading = false
                
                if (err.response.status === 404 || err.response.status === 500) {
                    this.$router.push('/404')
                }
            })
        },
        submitPost() {
            this.successMessage = null
            this.loadingSubmission = true;

            const payload = {
                content: this.editorContent,
                thread_id: this.$route.params.id
            }

            this.client.postForumPost(payload).then((response) => {
                this.loadingSubmission = false
                this.editorContent = '<p></p>'
                if (response.status === 201) {
                    this.successMessage = 'Successfully posted comment!'
                }
            }).catch((err) => {
                this.loadingSubmission = false
                if (err.response && err.response.data) {
                    this.submissionError = err.response.data.error.content
                }
            })
        },
        previousPage() {
            this.$router.push({ query: { page: Math.max(1, this.page - 1) } })
        },
        nextPage() {
            this.$router.push({ query: { page: this.page + 1 } })
        },
    }
}
</script>