<template>
    <main>
        <div class="container">
            <div class="main-content-box" id="highscores">

                <div class="row flex-row d-flex justify-content-space-between top-page-divider">
                        
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start">
                        <h3 class="page-title mb-3 mb-lg-0">Hiscores</h3>
                        <router-link to="/hiscores" class="btn small mb-3 mb-lg-0 ml-lg-4"><i class="fas fa-long-arrow-alt-left mr-2"></i>Back to Hiscores</router-link>
                        </div>            
                    
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div class="page-title-box-right">
                                <input type="text" v-model="playerSearch" @keyup.enter="search" name="user_search" id="us" placeholder="Search players...">
                                <button type="submit" @click="search" id="us-btn"><i class="fa fa-search"></i></button>
                            </div>
                        </div>
                </div>

                <div class="card">       
                    <div class="highscores-current-view">
                        <div class="skill-name">
                            <h5 class="rank-styles admin">{{ toTitleCase($route.params.username) }}</h5>
                        </div>

                        <div>
                            <img src="resources/img/highscores/gamemodes/ironman.png">
                            <p>Ironman</p>
                        </div>
                        <div>
                            <img src="resources/img/highscores/gamemodes/xp-boost.png">
                            <p>10x XP Boost</p>
                        </div>
                        <!-- <div>
                            <img src="resources/img/highscores/gamemodes/world.png">
                            <p>Eco World</p>
                        </div> -->
                    </div>

                </div>
                <div class="row">

                    <div class="col-12 col-md-12 col-lg-5 col-xl-4 left-col">
                        <div class="card">
                            <ul class="highscores-categories profile-highscores-stats">
                                <li class="highscores-categories--title">
                                    <h6>Boss Kill Counts</h6>
                                </li>
                                <li>
                                    <div class="abyssal-sire">
                                        <span class="stat-name">Abyssal Sire</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="alchemical-hydra">
                                    <span class="stat-name">Alchemical Hydra</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="barrows-chests">
                                    <span class="stat-name">Barrows Chests</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="bryophyta">
                                    <span class="stat-name">Bryophyta</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="callisto">
                                    <span class="stat-name">Callisto</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="cerberus">
                                    <span class="stat-name">Cerberus</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="chambers-of-xeric">
                                    <span class="stat-name">Chambers of Xeric</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="chambers-of-xeric-challenge-mode">
                                    <span class="stat-name">Chambers of Xeric: Challenge Mode</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="chaos-elemental">
                                    <span class="stat-name">Chaos Elemental</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="chaos-fanatic">
                                    <span class="stat-name">Chaos Fanatic</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="commander-zilyana">
                                    <span class="stat-name">Commander Zilyana</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="corporeal-beast">
                                    <span class="stat-name">Corporeal Beast</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="crazy-archaeologist">
                                    <span class="stat-name">Crazy Archaeologist</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="deranged-archaeologist">
                                    <span class="stat-name">Deranged Archaeologist</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="dagannoth-prime">
                                    <span class="stat-name">Dagannoth Prime</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="dagannoth-rex">
                                    <span class="stat-name">Dagannoth Rex</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="dagannoth-supreme">
                                    <span class="stat-name">Dagannoth Supreme</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="ganodermic-beastie">
                                    <span class="stat-name">Ganodermic Beastie</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="general-graardor">
                                    <span class="stat-name">General Graardor</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="giant-mole">
                                    <span class="stat-name">Giant Mole</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="grotesque-guardians">
                                    <span class="stat-name">Grotesque Guardians</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="hespori">
                                    <span class="stat-name">Hespori</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="kalphite-queen">
                                    <span class="stat-name">Kalphite Queen</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="king-black-dragon">
                                    <span class="stat-name">King Black Dragon</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="kraken">
                                    <span class="stat-name">Kraken</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="kreearra">
                                    <span class="stat-name">Kreearra</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="kril-tsutsaroth">
                                    <span class="stat-name">Kril Tsutsaroth</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="mimic">
                                    <span class="stat-name">Mimic</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="nightmare">
                                    <span class="stat-name">Nightmare</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="nightmare-phosani">
                                    <span class="stat-name">Phosani's Nightmare</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="obor">
                                    <span class="stat-name">Obor</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="sarachnis">
                                    <span class="stat-name">Sarachnis</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="scorpia">
                                    <span class="stat-name">Scorpia</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="skotizo">
                                    <span class="stat-name">Skotizo</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="the-corrupted-gauntlet">
                                    <span class="stat-name">The Corrupted Gauntlet</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="the-gauntlet">
                                    <span class="stat-name">The Gauntlet</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="theatre-of-blood">
                                    <span class="stat-name">Theatre of Blood</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="theatre-of-blood">
                                    <span class="stat-name">Theatre of Blood: Hard Mode</span> <span class="stat-count">50</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="thermonuclear-smoke-devil">
                                    <span class="stat-name">Thermonuclear Smoke Devil</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="tzkal-zuk">
                                    <span class="stat-name">Tzkal-Zuk</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="tztok-jad">
                                    <span class="stat-name">Tztok-jad</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="venenatis">
                                    <span class="stat-name">Venenatis</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="vetion">
                                    <span class="stat-name">Vetion</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="vorkath">
                                    <span class="stat-name">Vorkath</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="wintertodt">
                                    <span class="stat-name">Wintertodt</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="xamphur">
                                    <span class="stat-name">Xamphur</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="zalcano">
                                    <span class="stat-name">Zalcano</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="zulrah">
                                    <span class="stat-name">Zulrah</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>

                            </ul>
                            
                        </div>

                    </div>

                    <div class="col-12 col-md-12 col-lg-7 col-xl-8 right-col">

                        <div class="card rank-table">
                            <div class="table-overflow">        
                                <table id="profile-hiscores">
                                    <thead>
                                        <tr>
                                            <th class="skill">
                                                Skill
                                            </th>
                                            <th class="skill-name">
                                                Skill Name
                                            </th>
                                            <th class="rank">
                                                Rank
                                            </th>
                                            <th class="level">
                                                Level
                                            </th>
                                            <th class="right-col">
                                                Experience
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="hiscore in hiscores" :key="hiscore">
                                            <td class="skill" :class="hiscore.skill_name"></td>
                                            <td class="skill-name">{{ toTitleCase(hiscore.skill_name) }}</td>
                                            <td class="rank">{{ hiscore.rank }}</td>
                                            <td class="level">{{ hiscore.level }}</td>
                                            <td class="right-col">{{ hiscore.experience }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>

                        <div class="card">
                            <ul class="highscores-categories profile-highscores-stats">
                                <li class="highscores-categories--title">
                                    <h6>PvP Stats</h6>
                                </li>
                                <li>
                                    <div class="pk-kills">
                                    <span class="stat-name">PvP Kills</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="pk-kills">
                                    <span class="stat-name">PvP Deaths</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="pk-kills">
                                    <span class="stat-name">PvP KDR</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="pk-streaks">
                                    <span class="stat-name">Top Kill Streak</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="pk-streaks">
                                    <span class="stat-name">Current Kill Streak</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>

                            </ul>
                            
                        </div>
                        <div class="card">
                            <ul class="highscores-categories profile-highscores-stats">
                                <li class="highscores-categories--title">
                                    <h6>Clue Scrolls</h6>
                                </li>
                                <li>
                                    <div class="clue-scrolls-all">
                                    <span class="stat-name">All Clue Scrolls</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="clue-scrolls-beginner">
                                    <span class="stat-name">Beginner Clues</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="clue-scrolls-easy">
                                    <span class="stat-name">Easy Clues</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="clue-scrolls-medium">
                                    <span class="stat-name">Medium Clues</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="clue-scrolls-hard">
                                    <span class="stat-name">Hard Clues</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="clue-scrolls-elite">
                                    <span class="stat-name">Elite Clues</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="clue-scrolls-master">
                                    <span class="stat-name">Master Clues</span> <span class="stat-count">Coming Soon</span>
                                    </div>
                                </li>

                            </ul>
                            
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"

export default {
    created() {
        document.title = 'Hiscores - Near-Reality'

        this.client = new APIClient();

        this.$watch(() => this.$route.params, () => this.getPlayerHiscores(this.$route.params.username), { immediate: true })
    },
    watch: {
        // '$this.route.params.username': {
        //     handler: function() {
        //         this.getPlayerHiscores(this.$route.params.username)
        //     },
        //     immediate: true
        // }
    },
    data() {
        return {
            client: null,
            playerSearch: '',
            hiscores: []
        }
    },
    methods: {
        search() {
            if (this.playerSearch === '') {
                return;
            }

            this.$router.push({ path: '/hiscores/player/' + this.playerSearch, replace: true })
        },
        getPlayerHiscores(username) {
            this.client.getPlayerHiscores(username).then(response => {
                this.hiscores = response.data
            }); 
        }
    }
}
</script>