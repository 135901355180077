<template>
    <div class="modal-dialog modal-dialog-centered">
        <div v-if="product" class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Credit Payment - PayPal</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div class="credit-modal--body mb-2">
                    <ul>
                            <li><p>Current Balance:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ balance }} Credits</p></li>
                            <li><p>Order:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ product.amount }} {{ product.bonus_amount > 0 ? `(+${product.bonus_amount} Bonus)` : '' }} Credits</p></li>
                            <li class="new-balance"><p>New Balance:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{balance + product.amount + product.bonus_amount}} Credits</p></li>
                            <li><hr></li>
                            <li class="total"><p>Total:</p><p>${{ product.price }} USD</p></li>
                        <li class="payment-method"><p>Payment Method:</p><p class="stripe-color">PayPal</p></li>
                    </ul>
                </div>
                <hr>
                <div id="button-element" class="form-control mb-0"></div>
            </div>
        </div>
        <div v-else class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Credit Payment - PayPal</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <p>Sorry, but it looks like we ran into trouble while creating your PayPal checkout session. Please report this to a staff member for help.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.stripe-color {
    color: #4379FF;
}
</style>

<script>
import APIClient from "@/services/APIClient.js"
import { loadScript } from '@paypal/paypal-js';
import { nextTick } from 'vue'

export default {
    props: {
        product: Object,
        balance: Number
    },
    watch: {
        async product(newVal, oldVal) {
            if (newVal) {
                await nextTick()
                await this.paypal.Buttons({
                  createOrder: (data, actions) => {
                    const payload = {
                      id: this.product.id,
                      method: 'paypal'
                    }

                    const order = this.client.postCreditPurchase(payload).then((response) => {
                      this.loading = false;
                      if (response.status === 201) {
                        return response.data.order_id
                      }
                      return Promise.reject(response)
                    })
                    return order
                  },
                  onApprove: (data, actions) => {
                    return actions.order.capture().then((orderData) => {
                      this.$router.push('/store/success')
                    })
                  }
                }).render("#button-element")
            }
        }
    },
    created() {
        this.client = new APIClient();
        loadScript({
          "client-id": "AUG-mwKyjEzjLLtKDNer-VpsLv5RGtUjHFJOQVP0JMwnOEk4O5JXhYR_76FbzDGIw2Pxfpj_UXCS4tWM"
        }).then((paypal) => {
            this.paypal = paypal
        })
    },
    data() {
        return {
            client: null,
            paypal: null
        }
    },
    methods: {
        purchase() {

        }
    }
}
</script>
