<template>
    <main>
        <div class="container very-narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Create an Account</h3>
                </div>
                <p class="text-center w-75 mx-auto">To get started on Luxios, please register an account. <strong>After creating your account, you will use those same credentials to log into the game.</strong></p>

                <p v-if="awaitingConfirmation" class="success-message m-auto mb-4"><i class="fas fa-check-circle mr-1"></i>We sent you a confirmation email, please click on the link in the email to finalise your account creation!</p>
              <div v-if="errors.details" class="error-message mb-4">
                <p>{{ errors.details }}</p>
              </div>
                <form @submit.prevent="executeCaptcha">
                    <div class="mb-3">
                        <label for="registerUsername" class="form-label">Username</label>
                        <input type="text" v-model="username" class="form-control mb-0" id="registerUsername" required>
                        <template v-if="errors.username">
                            <small v-for="error in errors.username" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                    </div>
                    <div class="mb-3">
                        <label for="registerEmail" class="form-label">Email Address</label>
                        <input type="email" v-model="email" class="form-control mb-0" id="registerEmail" required>
                        <template v-if="errors.email">
                            <small v-for="error in errors.email" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                    </div>
                    <div class="mb-3">
                        <label for="registerPassword" class="form-label">Password</label>
                        <input type="password" v-model="password" class="form-control mb-0" id="registerPassword" aria-describedby="passwordHelpBlock" required>
                        <template v-if="errors.password">
                            <small v-for="error in errors.password" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                        <div v-else id="passwordHelpBlock" class="form-text">
                            Your password must be at-least 6 characters long, and contain letters, numbers, and certain special characters.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="registerConfirmPassword" class="form-label">Confirm Password</label>
                        <input type="password" v-model="passwordConfirmation" class="form-control mb-0" id="registerConfirmPassword" required>
                        <template v-if="errors.password_confirmation">
                            <small v-for="error in errors.password_confirmation" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                    </div>
                    <div class="mb-3 form-check">
                        <input class="form-check-input" type="checkbox" value="" id="registerTOS" required>
                        <label class="form-check-label" for="registerTOS">
                            I accept the <router-link to="/terms">Terms & Conditions</router-link>, <router-link to="/privacy">Privacy Policy</router-link>, and <router-link to="/rules">Rules</router-link>
                        </label>
                    </div>
                    <VueHcaptcha ref="hcaptcha" sitekey="332bd50d-ceed-4901-b253-345b9792af15" size="invisible" @verify="register" class="text-center"/>
                    <button type="submit" class="btn full-width large green"><div v-if="loading" class="spinner-border spinner-border-sm mr-2"/>Register</button>
                    <p class="text-center mt-3 mb-0">Already signed up? <router-link to="/play">Download Luxios Client</router-link></p>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'

export default {
    components: {
        VueHcaptcha
    },
    created() {
        document.title = 'Register - Near-Reality'

        this.client = new APIClient();
    },
    data() {
        return {
            client: null,
            loading: false,
            awaitingConfirmation: false,
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            errors: []
        }
    },
    methods: {
        executeCaptcha() {
            this.loading = true
            this.awaitingConfirmation = false
            this.$refs.hcaptcha.execute();
        },
        register(tokenStr) {
            console.log('catpcha token:', tokenStr)
            this.loading = true
            this.awaitingConfirmation = false
            this.errors = []

            const payload = {
                username: this.username,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirmation,
                captchaToken: tokenStr
            }

            this.client.postUser(payload).then((response) => {
                console.log(response)
                if (response.status === 200) {
                    this.awaitingConfirmation = true
                } else if (response.status === 201) {
                  this.$router.push('/welcome')
                }
                this.loading = false
            }).catch((err) => {
                console.log(err)
                if(err.response.status === 429)
                    this.errors = {details: "You have been rate limited, please try again later."}
                else
                    this.errors = err.response.data
                this.loading = false
                this.awaitingConfirmation = false
            })
        },
    }
}
</script>
