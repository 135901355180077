<template>
    <div class="modal-dialog wide-width modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Donator Benefits</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div class="donator-benefits">
                    <div class="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th class="feature">
                                        Features
                                    </th>
                                    <th class="rank premium">
                                        <img src="@/assets/img/donator-benefits/premium.png" class="rank-image mr-1">Premium
                                    </th>
                                    <th class="rank expansion">
                                        <img src="@/assets/img/donator-benefits/expansion.png" class="rank-image mr-1">Expansion
                                    </th>
                                    <th class="rank extreme">
                                        <img src="@/assets/img/donator-benefits/extreme.png" class="rank-image mr-1">Extreme
                                    </th>
                                    <th class="rank respected">
                                        <img src="@/assets/img/donator-benefits/respected.png" class="rank-image mr-1">Respected
                                    </th>
                                    <th class="rank legendary">
                                        <img src="@/assets/img/donator-benefits/legendary.png" class="rank-image mr-1">Legendary
                                    </th>
                                    <th class="rank mythical">
                                        <img src="@/assets/img/donator-benefits/mythical.png" class="rank-image mr-1">Mythical
                                    </th>
                                    <th class="rank uber">
                                        <img src="@/assets/img/donator-benefits/uber.png" class="rank-image mr-1">Uber
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="feature">
                                        Amount Donated Required
                                    </td>
                                    <td class="rank premium">
                                        $25
                                    </td>
                                    <td class="rank expansion">
                                        $50
                                    </td>
                                    <td class="rank extreme">
                                        $200
                                    </td>
                                    <td class="rank respected">
                                        $400
                                    </td>
                                    <td class="rank legendary">
                                        $1,000
                                    </td>
                                    <td class="rank mythical">
                                    $2,500
                                    </td>
                                    <td class="rank uber">
                                    $5,000
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                        Run Boost
                                    </td>
                                    <td class="rank premium">
                                        5%
                                    </td>
                                    <td class="rank expansion">
                                        5%
                                    </td>
                                    <td class="rank extreme">
                                        10%
                                    </td>
                                    <td class="rank respected">
                                        10%
                                    </td>
                                    <td class="rank legendary">
                                        15%
                                    </td>
                                    <td class="rank mythical">
                                    20%
                                    </td>
                                    <td class="rank uber">
                                    20%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                        Pest Control Bonus Points
                                    </td>
                                    <td class="rank premium">
                                        (+1)
                                    </td>
                                    <td class="rank expansion">
                                        (+1)
                                    </td>
                                    <td class="rank extreme">
                                        (+3)
                                    </td>
                                    <td class="rank respected">
                                        (+3)    
                                    </td>
                                    <td class="rank legendary">
                                        (+4)
                                    </td>
                                    <td class="rank mythical">
                                        (+5)
                                    </td>
                                    <td class="rank uber">
                                        (+5)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                        God Wars Chambers Killcount Requirement
                                    </td>
                                    <td class="rank premium">
                                        30
                                    </td>
                                    <td class="rank expansion">
                                        25
                                    </td>
                                    <td class="rank extreme">
                                        20
                                    </td>
                                    <td class="rank respected">
                                        15   
                                    </td>
                                    <td class="rank legendary">
                                        15
                                    </td>
                                    <td class="rank mythical">
                                        15
                                    </td>
                                    <td class="rank uber">
                                        10
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                        Chance to Save Tokens in Warriors Guild
                                    </td>
                                    <td class="rank premium">
                                        10%
                                    </td>
                                    <td class="rank expansion">
                                        10%
                                    </td>
                                    <td class="rank extreme">
                                        15%
                                    </td>
                                    <td class="rank respected">
                                        15%  
                                    </td>
                                    <td class="rank legendary">
                                        20%
                                    </td>
                                    <td class="rank mythical">
                                        25%
                                    </td>
                                    <td class="rank uber">
                                        25%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                        Personal Death Time Extended
                                    </td>
                                    <td class="rank premium">
                                    (+2 Minutes)
                                    </td>
                                    <td class="rank expansion">
                                    (+3 Minutes)
                                    </td>
                                    <td class="rank extreme">
                                    (+4 Minutes)
                                    </td>
                                    <td class="rank respected">
                                    (+5 Minutes) 
                                    </td>
                                    <td class="rank legendary">
                                    (+6 Minutes)
                                    </td>
                                    <td class="rank mythical">
                                    (+7 Minutes)
                                    </td>
                                    <td class="rank uber">
                                    (+8 Minutes)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Farming Patches Auto-Composted
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    Normal
                                    </td>
                                    <td class="rank extreme">
                                    Normal
                                    </td>
                                    <td class="rank respected">
                                    Normal
                                    </td>
                                    <td class="rank legendary">
                                    Super
                                    </td>
                                    <td class="rank mythical">
                                    Super
                                    </td>
                                    <td class="rank uber">
                                    Super
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Double Rewards from Crystal Chest
                                    </td>
                                    <td class="rank premium">
                                    10%
                                    </td>
                                    <td class="rank expansion">
                                    10%
                                    </td>
                                    <td class="rank extreme">
                                    15%
                                    </td>
                                    <td class="rank respected">
                                    15%
                                    </td>
                                    <td class="rank legendary">
                                    20%
                                    </td>
                                    <td class="rank mythical">
                                    25%
                                    </td>
                                    <td class="rank uber">
                                    25%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Restore Box Cooldown
                                    </td>
                                    <td class="rank premium">
                                    7 Minutes
                                    </td>
                                    <td class="rank expansion">
                                    6 Minutes
                                    </td>
                                    <td class="rank extreme">
                                    5 Minutes
                                    </td>
                                    <td class="rank respected">
                                    4 Minutes
                                    </td>
                                    <td class="rank legendary">
                                    2 Minutes
                                    </td>
                                    <td class="rank mythical">
                                    1 Minutes
                                    </td>
                                    <td class="rank uber">
                                    None
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Increased Farming Yield
                                    </td>
                                    <td class="rank premium">
                                
                                    </td>
                                    <td class="rank expansion">
                                    
                                    </td>
                                    <td class="rank extreme">
                                    (+1)
                                    </td>
                                    <td class="rank respected">
                                    (+1)
                                    </td>
                                    <td class="rank legendary">
                                    (+1)
                                    </td>
                                    <td class="rank mythical">
                                    (+1)
                                    </td>
                                    <td class="rank uber">
                                    (+1)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Increased Marks of Grace Chance (%)
                                    </td>
                                    <td class="rank premium">
                                
                                    </td>
                                    <td class="rank expansion">
                                    10%
                                    </td>
                                    <td class="rank extreme">
                                    10%
                                    </td>
                                    <td class="rank respected">
                                    10%
                                    </td>
                                    <td class="rank legendary">
                                    10%
                                    </td>
                                    <td class="rank mythical">
                                    10%
                                    </td>
                                    <td class="rank uber">
                                    10%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Bonus Vote Points
                                    </td>
                                    <td class="rank premium">
                                
                                    </td>
                                    <td class="rank expansion">
                                    
                                    </td>
                                    <td class="rank extreme">
                                    1.5x
                                    </td>
                                    <td class="rank respected">
                                    1.5x
                                    </td>
                                    <td class="rank legendary">
                                    1.5x
                                    </td>
                                    <td class="rank mythical">
                                    1.5x
                                    </td>
                                    <td class="rank uber">
                                    2x
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Price for Zahur's Services
                                    </td>
                                    <td class="rank premium">
                                    150gp
                                    </td>
                                    <td class="rank expansion">
                                    125gp
                                    </td>
                                    <td class="rank extreme">
                                    100gp
                                    </td>
                                    <td class="rank respected">
                                    75gp
                                    </td>
                                    <td class="rank legendary">
                                    50gp
                                    </td>
                                    <td class="rank mythical">
                                    Free
                                    </td>
                                    <td class="rank uber">
                                    Free
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Yell Cooldown
                                    </td>
                                    <td class="rank premium">
                                    2 Minutes
                                    </td>
                                    <td class="rank expansion">
                                    1 Minute
                                    </td>
                                    <td class="rank extreme">
                                    45 Seconds
                                    </td>
                                    <td class="rank respected">
                                    30 Seconds
                                    </td>
                                    <td class="rank legendary">
                                    15 Seconds
                                    </td>
                                    <td class="rank mythical">
                                    5 Seconds
                                    </td>
                                    <td class="rank uber">
                                    None
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Boosted Superior Slayer Spawn Rate (%)
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    
                                    </td>
                                    <td class="rank extreme">
                                    
                                    </td>
                                    <td class="rank respected">
                                    10%
                                    </td>
                                    <td class="rank legendary">
                                    10%
                                    </td>
                                    <td class="rank mythical">
                                    10%
                                    </td>
                                    <td class="rank uber">
                                    10%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Extra Favorites Teleport
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    (+1)
                                    </td>
                                    <td class="rank extreme">
                                    (+1)
                                    </td>
                                    <td class="rank respected">
                                    (+1)
                                    </td>
                                    <td class="rank legendary">
                                    (+2)
                                    </td>
                                    <td class="rank mythical">
                                    (+2)
                                    </td>
                                    <td class="rank uber">
                                    (+2)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Extra Roll at Wintertodt
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    (+1 Roll)
                                    </td>
                                    <td class="rank extreme">
                                    (+1 Roll)
                                    </td>
                                    <td class="rank respected">
                                    (+1 Roll)
                                    </td>
                                    <td class="rank legendary">
                                    (+2 Rolls)
                                    </td>
                                    <td class="rank mythical">
                                    (+2 Rolls)
                                    </td>
                                    <td class="rank uber">
                                    (+2 Rolls)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Extra Daily Challenge Slot
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    (+1)
                                    </td>
                                    <td class="rank extreme">
                                    (+1)
                                    </td>
                                    <td class="rank respected">
                                    (+1)
                                    </td>
                                    <td class="rank legendary">
                                    (+2)
                                    </td>
                                    <td class="rank mythical">
                                    (+2)
                                    </td>
                                    <td class="rank uber">
                                    (+2)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Birdhouse Timers
                                    </td>
                                    <td class="rank premium">
                                    45 Minutes
                                    </td>
                                    <td class="rank expansion">
                                    40 Minutes
                                    </td>
                                    <td class="rank extreme">
                                    35 Minutes
                                    </td>
                                    <td class="rank respected">
                                    30 Minutes
                                    </td>
                                    <td class="rank legendary">
                                    25 Minutes
                                    </td>
                                    <td class="rank mythical">
                                    20 Minutes
                                    </td>
                                    <td class="rank uber">
                                    20 Minutes
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Xeric's Wisdom (Default +25%)
                                    </td>
                                    <td class="rank premium">
                                    (+27%)
                                    </td>
                                    <td class="rank expansion">
                                    (+30%)
                                    </td>
                                    <td class="rank extreme">
                                    (+33%)
                                    </td>
                                    <td class="rank respected">
                                    (+36%)
                                    </td>
                                    <td class="rank legendary">
                                    (+40%)
                                    </td>
                                    <td class="rank mythical">
                                    (+45%)
                                    </td>
                                    <td class="rank uber">
                                    (+60%)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Dwarf Multicannon Space (Default 30)
                                    </td>
                                    <td class="rank premium">
                                    40
                                    </td>
                                    <td class="rank expansion">
                                    50
                                    </td>
                                    <td class="rank extreme">
                                    60
                                    </td>
                                    <td class="rank respected">
                                    70
                                    </td>
                                    <td class="rank legendary">
                                    90
                                    </td>
                                    <td class="rank mythical">
                                    90
                                    </td>
                                    <td class="rank uber">
                                    100
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Total Preset Slots (Default 2)
                                    </td>
                                    <td class="rank premium">
                                    4
                                    </td>
                                    <td class="rank expansion">
                                    6
                                    </td>
                                    <td class="rank extreme">
                                    8
                                    </td>
                                    <td class="rank respected">
                                    10
                                    </td>
                                    <td class="rank legendary">
                                    12
                                    </td>
                                    <td class="rank mythical">
                                    14
                                    </td>
                                    <td class="rank uber">
                                    16
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Clue Caskets Bonus
                                    </td>
                                    <td class="rank premium">
                                    (+3%)
                                    </td>
                                    <td class="rank expansion">
                                    (+6%)
                                    </td>
                                    <td class="rank extreme">
                                    (+9%)
                                    </td>
                                    <td class="rank respected">
                                    (+12%)
                                    </td>
                                    <td class="rank legendary">
                                    (+15%)
                                    </td>
                                    <td class="rank mythical">
                                    (+18%)
                                    </td>
                                    <td class="rank uber">
                                    (+21%)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Barrows Rewards Bonus (Double Loot)
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    
                                    </td>
                                    <td class="rank extreme">
                                    (+20%)
                                    </td>
                                    <td class="rank respected">
                                    (+20%)
                                    </td>
                                    <td class="rank legendary">
                                    (+20%)
                                    </td>
                                    <td class="rank mythical">
                                    (+20%)
                                    </td>
                                    <td class="rank uber">
                                    (+20%)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Discount on Points Lost in Raid When Dying
                                    </td>
                                    <td class="rank premium">
                                    5%
                                    </td>
                                    <td class="rank expansion">
                                    10%
                                    </td>
                                    <td class="rank extreme">
                                    10%
                                    </td>
                                    <td class="rank respected">
                                    15%
                                    </td>
                                    <td class="rank legendary">
                                    30%
                                    </td>
                                    <td class="rank mythical">
                                    40%
                                    </td>
                                    <td class="rank uber">
                                    50%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Larran's Key Drop Boost (on task in Wilderness)
                                    </td>
                                    <td class="rank premium">
                                    5%
                                    </td>
                                    <td class="rank expansion">
                                    10%
                                    </td>
                                    <td class="rank extreme">
                                    15%
                                    </td>
                                    <td class="rank respected">
                                    20%
                                    </td>
                                    <td class="rank legendary">
                                    25%
                                    </td>
                                    <td class="rank mythical">
                                    30%
                                    </td>
                                    <td class="rank uber">
                                    40%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Barrows Rewards Bonus (Triple Loot)
                                    </td>
                                    <td class="rank premium">
                                    
                                    </td>
                                    <td class="rank expansion">
                                    
                                    </td>
                                    <td class="rank extreme">
                                    
                                    </td>
                                    <td class="rank respected">
                                    
                                    </td>
                                    <td class="rank legendary">
                                    
                                    </td>
                                    <td class="rank mythical">
                                    (+30%)
                                    </td>
                                    <td class="rank uber">
                                    (+30%)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Coal Bag Capacity
                                    </td>
                                    <td class="rank premium">
                                    56
                                    </td>
                                    <td class="rank expansion">
                                    100
                                    </td>
                                    <td class="rank extreme">
                                    175
                                    </td>
                                    <td class="rank respected">
                                    250
                                    </td>
                                    <td class="rank legendary">
                                    500
                                    </td>
                                    <td class="rank mythical">
                                    600
                                    </td>
                                    <td class="rank uber">
                                    750
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Bank Capacity (Total)
                                    </td>
                                    <td class="rank premium">
                                    600
                                    </td>
                                    <td class="rank expansion">
                                    800
                                    </td>
                                    <td class="rank extreme">
                                    900
                                    </td>
                                    <td class="rank respected">
                                    1,000
                                    </td>
                                    <td class="rank legendary">
                                    1,300
                                    </td>
                                    <td class="rank mythical">
                                    1,500
                                    </td>
                                    <td class="rank uber">
                                    1,800
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Extra Bonus Blood Money
                                    </td>
                                    <td class="rank premium">
                                    5%
                                    </td>
                                    <td class="rank expansion">
                                    10%
                                    </td>
                                    <td class="rank extreme">
                                    15%
                                    </td>
                                    <td class="rank respected">
                                    20%
                                    </td>
                                    <td class="rank legendary">
                                    30%
                                    </td>
                                    <td class="rank mythical">
                                    40%
                                    </td>
                                    <td class="rank uber">
                                    50%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Extra Time in Tears of Guthix Minigame
                                    </td>
                                    <td class="rank premium">
                                    10 Seconds
                                    </td>
                                    <td class="rank expansion">
                                    20 Seconds
                                    </td>
                                    <td class="rank extreme">
                                    25 Seconds
                                    </td>
                                    <td class="rank respected">
                                    30 Seconds
                                    </td>
                                    <td class="rank legendary">
                                    45 Seconds
                                    </td>
                                    <td class="rank mythical">
                                    55 Seconds
                                    </td>
                                    <td class="rank uber">
                                    70 Seconds
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Extra Chance to Find a Pet
                                    </td>
                                    <td class="rank premium">
                                    1%
                                    </td>
                                    <td class="rank expansion">
                                    3%
                                    </td>
                                    <td class="rank extreme">
                                    6%
                                    </td>
                                    <td class="rank respected">
                                    10%
                                    </td>
                                    <td class="rank legendary">
                                    10%
                                    </td>
                                    <td class="rank mythical">
                                    12%
                                    </td>
                                    <td class="rank uber">
                                    15%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Start Fight Caves at Wave 31
                                    </td>
                                    <td class="rank premium">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Remove Skull via Restoration Box
                                    </td>
                                    <td class="rank premium">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Auto Pickup Marks of Grace (Rooftop Agility)
                                    </td>
                                    <td class="rank premium">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Ability to use noted coal when smelting
                                    </td>
                                    <td class="rank premium">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Receive noted bar drops from metal dragons
                                    </td>
                                    <td class="rank premium">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Free Pet Insurance (After 2 pets insured)
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Private Red Chinchompa Hunting Ground
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    No heat damage taken in Karuulm Slayer dungeon
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Free Wilderness Resource Area Access
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        
                                    </td>
                                    <td class="rank respected">
                                    
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    Receive noted bars at blast furnace
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        
                                    </td>
                                    <td class="rank respected">
                                    
                                    </td>
                                    <td class="rank legendary">
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    DI Zone Access
                                    </td>
                                    <td class="rank premium">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank expansion">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank extreme">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    RDI Zone Access
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        
                                    </td>
                                    <td class="rank respected">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    LDI Zone Access
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        
                                    </td>
                                    <td class="rank respected">
                                        
                                    </td>
                                    <td class="rank legendary">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank mythical">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="feature">
                                    UDI Zone Access
                                    </td>
                                    <td class="rank premium">
                                        
                                    </td>
                                    <td class="rank expansion">
                                        
                                    </td>
                                    <td class="rank extreme">
                                        
                                    </td>
                                    <td class="rank respected">
                                    </td>
                                    <td class="rank legendary">
                                    </td>
                                    <td class="rank mythical">
                                    </td>
                                    <td class="rank uber">
                                        <i class="fas fa-check"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="top-page-divider mt-4">
                        <h3 class="page-title center">Donator Media</h3>
                    </div>
                    <div class="donator-benefits--media">
                        
                        <div class="donator-benefits--media-grid">
                            <div class="donator-benefits--media-column">
                                <img src="@/assets/img/donator-benefits/di.png" lazy>
                                <h5>DI Zone</h5>
                            </div>
                            <div class="donator-benefits--media-column">
                                <img src="@/assets/img/donator-benefits/die.png" lazy>
                                <h5>DIE Zone</h5>
                            </div>
                            <div class="donator-benefits--media-column">
                                <img src="@/assets/img/donator-benefits/rdi.png" lazy>
                                <h5>RDI Zone</h5>
                            </div>
                            <div class="donator-benefits--media-column">
                                <img src="@/assets/img/donator-benefits/ldi.png" lazy>
                                <h5>LDI Zone</h5>
                            </div>
                            <div class="donator-benefits--media-column">
                                <img src="@/assets/img/donator-benefits/udi.png" lazy>
                                <h5>UDI Zone</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>