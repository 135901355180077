<template>
    <main>
        <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div class="content-box success-confirmation-box">
                                <div class="success-modal--content  mt-4 mb-4">
                                    <div class="success-modal--icon">
                                        <i class="fas fa-check"></i>
                                    </div>
                                </div>
                                <h3 class="text-center text-shadow page-title mb-1">Order Complete</h3>
                                <h5 class="text-center text-shadow paragraph--heading mb-4">Thank you!</h5>
                                <a href="#" class="btn green m-auto">Return to Store</a>

                            </div>

                        </div>

                        <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div class="main-content-box">
                                <div class="top-page-divider">
                                    <h3 class="page-title text-center">Order Confirmation</h3>
                                </div>
                                    <p>Thank your for supporting Near-Reality! Your contribution directly helps us with expensive server hosting costs, developer payment, and marketing.</p>
                                    <p><strong>You should now be able to claim your item(s) in-game by talking to the Wise Old Man located in the home area.</strong> If you have any issues while doing so, please feel free to <router-link to="/tickets/create">create a ticket</router-link></p>
                            </div>
                        </div>
                    </div>
        </div>
    </main>    
</template>
