<template>
    <footer>
        <div class="section-divider"></div>
        <div class="footer-main-block pt-3 pb-4">
            <div class="container mw-80">
                <div class="row gy-4 d-flex align-items-center mt-3 mb-5">
                    <div class="col-12 col-md-12 col-lg-3 f-logo text-center text-lg-left">
                        <a class="navbar-brand" href="#">
                            <img src="@/assets/img/logo-mobile.png" alt="Near-Reality Logo" lazy>
                        </a>
                    </div>
                    <div class="col-12 col-md-12 col-lg-5 text-center text-lg-left">
                        <p class="mb-2">Near-Reality.com is owned and operated by Unity Games Studio, LLC</p>
                        <p class="mb-2">Copyright © 2008-{{ getCurrentYear() }} Near Reality. | All rights reserved.</p>
                        <p class="mb-0">Use of this website is subject to our <router-link to="/terms">Terms of Service</router-link> & <router-link to="/privacy">Privacy Policy</router-link></p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 text-center text-lg-right">
                        <ul class="footer-socials">
                            <li><a href="#" target="_blank" class="social-link" alt="facebook" title="Follow us on Facebook"><i class="fab fa-facebook"></i></a></li>
                            <li><a href="#" target="_blank" class="social-link" alt="youtube" title="Subscribe on YouTube"><i class="fab fa-youtube"></i></a></li>
                            <li><a href="#" target="_blank" class="social-link" alt="discord" title="Join us on Discord"><i class="fab fa-discord"></i></a></li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="footer-grid mt-5 mb-4">
                    <div id="links-a" class="col footer-links text-center">
                        <ul>
                            <li><h5>Near-Reality</h5></li>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/news">News</router-link></li>
                            <li><router-link to="/play">Play</router-link></li>
                        </ul>
                    </div>
                    <div id="links-b" class="col footer-links text-center">
                        <ul>
                            <li><h5>Community</h5></li>
                            <li><router-link to="/login">Login</router-link></li>
                            <li><router-link to="/register">Register</router-link></li>
                            <li><router-link to="/account">Account</router-link></li>
                            <li><a href="https://discord.com/invite/nrpk" target="_blank">Discord</a></li>
                        </ul>
                    </div>
                    <div id="links-c" class="col footer-links text-center">
                        <ul>
                            <li><h5>Support</h5></li>
                            <li><router-link to="/terms">Terms</router-link></li>
                            <li><router-link to="/privacy">Privacy Policy</router-link></li>
                            <li><router-link to="/rules">Rules</router-link></li>
                            <li><router-link to="/faq">FAQ</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
const getCurrentYear = () => {
    return new Date().getFullYear()
}
</script>
