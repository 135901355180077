<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title">Terms of Service</h3>
                </div>
                    <p>Version 0.1</p>
                    <p>Date: February 2022</p>
                    <hr>
                    <h5 class="mt-4 mb-4">INTRODUCTION</h5>
                    <p>
                        These terms and conditions of use, together with our <a
                            href="https://near-reality.com/support/privacy-policy/">privacy policy</a>, <a
                            href="https://near-reality.com/support/game-rules/">Game Rules</a>, and all supplemental terms
                        (as amended from time to time), other documents and policies that are expressly referenced in these
                        terms and conditions (the "Terms"), govern your use of any Unity Games Studio Products (as defined below) made
                        available by Unity Games Studio to you ("you"/"yours").
                        </p>
                    <p>
                        The Unity Games Studio Product (as defined) is made available to you in accordance with these Terms
                        by Unity Games Studio, LLC ("Unity Games Studio", “we”, “our”, “us”), a company registered in the
                        United States (registered number 801749378), and whose registered office is at Harbor Springs,
                        Michigan, United States.
                    
                    </p>
                    <p>
                        Please read these Terms carefully. These Terms form a legally binding contract between you and us.
                        By downloading, browsing, installing, accessing or using a Unity Games Studio Product in any way,
                        you agree to be bound by these Terms. If you do not agree to be bound by these Terms you should not
                        create an account (“Account”) in relation to the Unity Games Studio Product or download, install,
                        browse, use or access the Unity Games Studio Product. If you have created an Account or used or
                        downloaded a Unity Games Studio Product, but do not agree to these Terms, you should immediately
                        discontinue your use of the Unity Games Studio Product, and uninstall it from your device.
                        </p>
                    <p>
                        To use the Unity Games Studio Product you may be required to comply with third-party platform
                        operator terms (e.g. Google's Play Store terms). If you are accessing any Unity Games Studio Product
                        via any third-party platform then these Terms shall apply in respect of your use of the Unity Games
                        Studio Product, in addition to any terms and conditions imposed by such third party platform.
                        </p>
                    <p>
                        PLEASE KEEP A COPY OF THESE TERMS FOR FUTURE REFERENCE. YOU SHOULD CHECK THESE TERMS EVERY TIME YOU
                        USE A UNITY GAMES STUDIO PRODUCT AS WE MAY CHANGE THESE TERMS AT ANY TIME IN ACCORDANCE WITH THE
                        PROCESS SET OUT AT SECTION 2 BELOW.
                    </p>

                    <hr>
                    <h5 class="mt-4 mb-4">CHANGES TO THESE TERMS</h5>
                    <p>
                        We may change these Terms to reflect: (a) changes in applicable laws; (b) regulatory or security
                        requirements; (c) relevant guidance or codes of practice; (d) technical alterations to Unity Games
                        Studio Products; (e) to improve clarity and consistency; or (f) for any other reason within the sole
                        discretion of Unity Games Studio.
                    </p>
                    <p>
                        Please check the Terms whenever you use a Unity Gamnes Studio Product. We will treat your continued
                        use of a Unity Games Studio Product as acceptance of any changes made to the Terms from the previous
                        version.
                    </p>
                    <hr>
                    <h5 class="mt-4 mb-4">ACCOUNT TERMINATION</h5>
                    <p>
                        <b>3.1. <u>How can my account be suspended or terminated?</u></b>
                    </p>

                    <p>
                    3.1.1. <b><u>You.</u></b> You may terminate or suspend your account at any time by contacting us at
                        support@near-reality.com.
                    </p>
                    <p>
                    3.1.2. Us. We may terminate or suspend your account without notice to you if we reasonably
                        determine, that:
                    </p>


                    <ul class="bulletpoints">
                        <li class="mb-4">you have breached any part of these Terms (including the User Rules);</li>
                        <li class="mb-4">doing so would be in the best interests of our community or the Unity Games Studio Services or
                            is required for upholding a third party’s rights;
                        </li>
                        <li class="mb-4">we have stopped offering the Unity Games Studio Services (though we’ll normally post advance
                            notice on our website, app or game if we plan to stop offering a feature to the game);
                        </li>
                        <li class="mb-4">you have failed to pay any fees you owe us (except in cases of our gross negligence or willful
                            misconduct) or we have to refund someone due to unauthorized use of a payment made from your
                            account; or
                        </li>
                        <li class="mb-4">you have (or we have reasonable grounds for suspecting that you have) made unauthorized use of
                            someone else’s payment info.
                        </li>
                    </ul>

                    <hr>
                    <h5 class="mt-4 mb-4">LIMITED LICENSE</h5>
                    <p>
                    <b>3.1 <u>What can I do with the Unity Games Studio Services?</u></b>
                    </p>
                    <p>
                        
                            We grant you a limited, non-exclusive, non-transferable, revocable license to use and enjoy the
                            Unity Games Studio (and any Virtual Content) for your individual, non-commercial, entertainment
                            purposes only and expressly conditioned upon your compliance with these Terms. If we terminate
                            your account, any license granted by us to you in the Unity Games Studio Services and any
                            Virtual Content ends immediately. Unless otherwise expressly authorized by us in a signed
                            written contract, you may not sell, copy, exchange, loan, reverse engineer, decompile, derive
                            source code from, translate, lease, grant a security interest in, transfer, publish, assign or
                            otherwise distribute any of the Unity Games Studio Services or any of Unity Games Studio’
                            intellectual property, including any of our computer code or Virtual Content.
                    </p>
                    <hr>
                    <h5 class="mt-4 mb-4">VIRTUAL GOODS, GAME CURRENCY AND PURCHASES</h5>

                        <p>
                        <b><u>What is Virtual Content? (Virtual content includes stuff like game currency and virtual
                            goods, such as weapons, consumables, emotes, accessories, etc.)</u></b>
                        </p>
                        <p>
                        When using the Unity Games Studio Services, we may provide you with opportunities to acquire a
                        limited license
                        to access virtual goods, such as champions, skins, emotes, etc., (“Virtual Goods”) and in-game
                        currency (“Game Currency”), associated with your account (collectively, “Virtual Content”).
                        </p>
                        <p>
                        Game Currency may only be used for Near-Reality.com (Old School Near Reality).
                        </p>
                        <p>
                        <b><u>How do I get a “license” to access Virtual Content? (Buy it, earn it, or receive it.)</u></b>
                        </p>
                        
                        <p>
                            We may provide you with various opportunities to acquire a limited license to access Game
                        Currency or Virtual Content, including:
                        </p>

                        <p>purchasing it (e.g., with a credit card);</p>
                        <p>earning it (e.g., by killing bosses); or</p>
                        <p>receiving it (e.g., from another player as a gift)</p>

                        <p>
                        <u>Do I “own” the Virtual Content I unlock? (No. What you “unlock” is not the virtual good
                            itself, but rather, a non-transferable limited license to access it.)</u>
                        </p>
                        <p>
                        You have no ownership or other property interest in any of the Virtual Content you unlock,
                        regardless of how you acquired access to it. Virtual Content has no monetary value. You can’t
                        transfer (unless we allow it in the functionality of the Unity Games Studio Services) or redeem Virtual
                        Content for any type of “real world” money. You can’t obtain any refunds for purchasing a
                        license to access Virtual Content, except as expressly permitted by us. You can find our current
                        content refund policy here.
                        </p>
                        <p>
                        <b><u>Once again: I don’t own my Virtual Content?</u></b>
                        </p>
                        <p>
                        When you obtain Virtual Content from us, what we are actually giving you is a personal,
                        non-exclusive, non-transferable, non-sublicenseable, revocable, limited right and license to use
                        that Virtual Content only in connection with your use of the applicable Unity Games Studio Services.
                        </p>
                        <p>
                        Notwithstanding anything to the contrary in these Terms, you acknowledge and agree that you
                        shall have no ownership or other property interest in your account, and that all rights in and
                        to your account are and shall forever be owned by and inure to the benefit of Unity Games Studio. You
                        further acknowledge and agree that you have no title, ownership, or other proprietary interest
                        in any Virtual Content, regardless of any consideration offered or paid in exchange.
                        Furthermore, except in cases of willful misconduct or gross negligence, or to the extent these
                        Terms say otherwise, Unity Games Studio shall not be liable in any manner for the destruction, deletion,
                        modification, impairment, hacking, or any other damage or loss of any kind caused to Virtual
                        Content, including the deletion of Virtual Content upon the termination or expiration of your
                        account or our reasonable changes to the Unity Games Studio.
                        </p>
                    <p>
                    <b><u>Will my Virtual Content always be available? (Not necessarily, no.)</u></b>
                    </p>
                        
                        
                    <p>
                        In an effort to constantly improve the Unity Games Studio Services, evolve our games and keep the
                        Unity Games Studio
                        Services, safe, fun, and secure, we have the right to delete, alter, move, remove, re-package,
                        re-price, or transfer any and all Game Content, including Virtual Content, in whole or in part,
                        at any time, with or without notice to you, and with no liability of any kind to you. For
                        example, your Virtual Goods may (and likely will) evolve over time to improve the Unity Games Studio
                        Services
                        or for regulatory or legal reasons. If we decide to entirely retire certain Virtual Goods that
                        you recently purchased, we will provide you with a replacement. We won’t delete your Game
                        Currency without notice (such as through posts on our website, app or game), unless your account
                        is terminated by us for a legitimate reason or by you under Section 3.1.1. We may sometimes
                        change the purchasing power of Game Currency (for example, we might increase the number of Game
                        Currency needed to purchase Virtual Goods, such as weapons).
                    </p>
                    <hr>
                    <h5 class="mt-4 mb-4">GAME RULES</h5>
                    <p>
                        You must comply with the current version of any rules, guidelines, codes of conduct or instructions
                        specified in any Unity Games Studio Product including our game rules, the current version of which
                        for each Unity Games Studio Product are linked below (“Game Rules”). The Game Rules are expressly
                        incorporated into these Terms. Any use of a Unity Games Studio Product not in accordance with the
                        Game Rules exceeds the scope of the license granted by these Terms and is prohibited.
                    </p>
                    <p>
                        Old School Near Reality Rules are found <a
                            href="https://near-reality.com/support/game-rules/">here</a>

                    </p>
                    <hr>
                    <h5 class="mt-4 mb-4">REFUND POLICY</h5>
                    <p>
                        We are happy to review requests for refunds on a case by case basis.
                    </p>
                    <p>
                        Unless required by law, we will not refund any amounts paid in relation to, Micro-Game
                        Credits, Unity Games Studio Product Pins or Virtual Currency when:
                    </p>

                    <ul class="bulletpoints">
                        <li class="mb-4">You have been the victim of in-game scamming, as there are warnings about avoiding this on the
                            Unity Games Studios
                            Products (however, we will try to assist you to recover your Account).
                        </li>
                        <li class="mb-4">You have been Stopped from a Unity Games Studio Product for violating these Terms including any
                            rules of a Unity Games Studio
                            Product (including the Game Rules) or the User Content policy.
                        </li>
                        <li class="mb-4">You have been using unauthorised software to access the Unity Games Studio Product.
                            Unity Games Studio stops offering the Unity Games Studio Product.
                        </li>
                        <li class="mb-4">You decide to cancel your Account.</li>
                    </ul>
                    <p>
                        If, after reading the above, you wish to request that we consider a refund, then please visit the
                        Support section of the applicable Unity Games Studio Product website for assistance, providing as
                        much
                        information as possible. Payment support can be accessed <a
                            href="https://near-reality.com/account/tickets/">here</a>. Please note, refunds are given at the
                        sole discretion of Unity Games Studios, LLC (however your statutory rights are unaffected).
                    </p>

                    <hr>
                    <h5 class="mt-4 mb-4">THIRD PARTY SITES AND TECHNOLOGIES</h5>
                    <p>
                        UNITY GAMES STUDIO PRODUCTS MAY INCLUDE LINKS TO INTERNET SITES OR OTHER TECHNOLOGIES SUPPLIED
                        BY THIRD PARTIES INCLUDING SITES/TECHNOLOGIES WHICH ENABLE YOU TO PAY BY MEANS OF PAYMENT METHODS PROVIDED BY THIRD PARTIES. WE MAKE NO PROMISES ABOUT THOSE THIRD-PARTY SITES TECHNOLOGIES OR THEIR CONTENT, PRODUCTS OR SERVICES AS THESE ARE OUTSIDE OUR REASONABLE CONTROL.
                    </p>
                    <p>
                        ANY THIRD-PARTY SITES OR TECHNOLOGIES SUGGESTED ARE NOT ENDORSED, CONTROLLED OR VERIFIED BY
                        UNITY GAMES STUDIO. UNITY GAMES STUDIO DOES NOT GUARANTEE THAT ANY SUGGESTED TECHNOLOGIES WILL
                        WORK ON YOUR COMPUTER OR BE
                        VIRUS FREE. ANY SUGGESTIONS MADE ARE NOT A WARRANTY OF ANY KIND AND UNITY GAMES STUDIO IS NOT
                        RESPONSIBLE FOR
                        ANY DISRUPTION, PROBLEM, DAMAGE, DATA LOSS, COST OR INCONVENIENCE CAUSED BY THE SUGGESTED SITES
                        OR TECHNOLOGIES. UNITY GAMES STUDIO WILL NOT PROVIDE SUPPORT FOR ANY SITES OR TECHNOLOGIES WHICH
                        ARE NOT ITS
                        OWN.
                    </p>
                    <p>
                        UNITY GAMES STUDIO EXERCISES NO CONTROL OVER THE COPYRIGHT, PATENT OR TRADEMARK COMPLIANCE
                        OR LEGALITY
                        OF THE SUGGESTED SITES OR TECHNOLOGIES.
                    </p>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    created() {
        document.title = 'Terms - Near-Reality'
    }
}
</script>