<template>
    <main>
        <div class="container narrow">
            <div class="main-content-box">
                <Loading v-if="loading"/>
                <div v-else-if="post !== null">
                    <div class="top-page-divider">
                        <div class="news-post-header">
                            <div class="news-post-header--titles">
                                <h3 class="page-title">{{ post.title }}</h3>
                                <p>Posted on {{ currentDate() }} by <b>Near-Reality Team</b></p>
                            </div>
                        </div>

                    </div>

                    <div class="news-post-content">
                        <div v-html="post.content"></div>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <h5>Oops! News archive post not found!</h5>
                        <p>Try searching for some others or report this to a staff member.</p>
                    </div>
                </div>
                <div class="news-post-footer">
                    <div class="top-page-divider reverse"></div>
                <div class="news-post-footer--credits">
                    <a href="#" class="join-discussion"><i class="far fa-comments"></i> Browse all news archives</a>
                    <p class="mt-4">Jacmob, Kryeus, Patrity, Pazaz, Standev</p>
                    <p class="signature">The Old School Near-Reality Team</p>
                </div>
            </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router'
import Loading from "@/components/Loading.vue"
import APIClient from "@/services/APIClient.js"
// interface Post {
//     title: string,
//     content: string,
//     date: Date
//     slug: string
//     category: "Game Updates" | "Community" | "Staff Updates"
// }
const route = useRoute();

const client = new APIClient();
const loading = ref(true);

const post = ref(null);

onMounted(() => {
    client.getNews(route.params.slug).then((response) => {
        console.log(response)
        post.value = response.data;
    }).catch((err) => {
        console.log(err)
    }).finally(() => {
        loading.value = false;
    })
})
const currentDate = () => {
  return new Date()
}
</script>
