import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import '@/assets/css/main.css'

const titleCaseMixin = {
    methods: {
        toTitleCase(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }
}

const gtagConfig = {
    config: { id: "G-B6KS8VHJTT" }
};

createApp(App).use(store).use(router).use(VueGtag, gtagConfig).mixin(titleCaseMixin).mount("#app");


