<template>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Two Factor Authentication - Activate</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div v-if="form.errorMessage" class="mb-3 error-message text-center">
                    <p>{{ form.errorMessage }}</p>
                </div>
                <div>
                    <h6>Activate Two-Factor Authentication</h6>
                    <p>Thank you for activating two-factor authentication! Please scan the QR code below with your preferred authentication code provider, then enter the current 6-digit code that it generates for you.</p>
                    <canvas id="canvas" class="d-block mx-auto"></canvas>
                    <form>
                        <label class="form-label">Current Authentication Code</label>
                        <input v-model="form.data.code" required placeholder="Enter the 6-digit code shown in your authentication app" class="form-control mb-0">
                        <small v-if="form.data.code.length !== 6" class="text-danger">Please enter your current 6-digit code here before activating!</small>
                    </form>
                </div>
                <hr>
                <div>
                    <button @click="activate" :disabled="form.data.code.length !== 6" class="btn">
                        <div v-if="form.processing" class="spinner-border spinner-border-sm mr-2"/>
                        Activate
                    </button>
                    <a class="btn red" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode'
import APIClient from "@/services/APIClient.js"

export default {
    props: {
        username: String,
        secret: String
    },
    created() {
        this.apiClient = new APIClient()
    },
    mounted() {
        QRCode.toCanvas(document.getElementById('canvas'), 'otpauth://totp/Near-Reality:' + this.username + '?secret=' + this.secret + '&issuer=Near-Reality', function (error) {
            if (error) console.error(error)
        })
    },
    data() {
        return {
            apiClient: null,
            form: {
                errorMessage: '',
                processing: false,
                data: {
                    code: ''
                }
            }
        }
    },
    methods: {
        activate() {
            this.form.errorMessage = ''
            this.form.processing = true
            
            this.apiClient.postTwoFactorActivation(this.form.data).then((response) => {
                console.log('hi?')
                this.form.processing = false

                console.log(response)
                if (response.data && response.data.success) {
                    this.$emit('twoFactorActivated')
                }
            }).catch((err) => {
                this.form.processing = false
                console.log(err.status)
                console.log(err.response)
                if (err.response.data && err.response.data.error) {
                    this.form.errorMessage = err.response.data.error
                }
            })
        }
    }
}
</script>