<template>
    <main>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-heading">
                        <h2>Vote for <span>Near reality</span></h2>
                        <p>Every single vote helps Near-Reality grow so please remember to do so every 12 hours! To show our appreciation for your time, each vote can
                        be redeemed for in-game rewards, accessible by talking to the Wise Old Man who is located at Home.</p>
                    </div>
                </div>
            </div>
            <div class="main-content-box">
                <div class="vote-buttons--grid">
                    <a :href="`http://runelocus.com/top-rsps-list/vote-43455/?id2=${this.$store.state.user.name}`" target="_blank" class="vote_btn" :class="runelocusDiff === 'Available!' ? 'available' : 'voted'">
                        <div v-if="!countdownIssue" class="next-vote">Next Vote: <span>{{ runelocusDiff }}</span></div>
                        <img src="@/assets/img/vote-sites/runelocus.png" lazy>
                        <div class="site-name">RuneLocus</div>
                    </a>
                    <a :href="`http://rsps-list.com/index.php?a=in&u=nrpk&id=${this.$store.state.user.name}`" target="_blank" class="vote_btn" :class="rspslistDiff === 'Available!' ? 'available' : 'voted'">
                        <div v-if="!countdownIssue" class="next-vote">Next Vote: <span>{{ rspslistDiff }}</span></div>
                        <img src="@/assets/img/vote-sites/rsps-list.png" lazy>
                        <div class="site-name">RSPS-List</div>
                    </a>
                    <a :href="`https://runelist.io/toplist/server/78/vote/${this.$store.state.user.name}`" target="_blank" class="vote_btn" :class="runelistDiff === 'Available!' ? 'available' : 'voted'">
                        <div v-if="!countdownIssue" class="next-vote">Next Vote: <span> {{ runelistDiff }}</span></div>
                        <img src="@/assets/img/vote-sites/runelist.svg" lazy>
                        <div class="site-name">Runelist</div>
                    </a>
                </div>

            </div>
            <div class="main-content-box vote-statistics">
                <div class="top-page-divider">
                    <h3 class="page-title center">Vote Statistics</h3>
                    <p>Vote statistics are updated every five minutes.</p>
                </div>
                <Loading v-if="loading"/>
                <div v-if="!loading" class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <div class="col">
                        <div class="card">
                            <table id="voter-history">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                        <h5 class="content-box--heading">{{ month }}<br>Leaderboard</h5>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td v-if="!monthlyLeaderboard.length" colspan="2" class="text-center">
                                        No monthly leaderboard information found.
                                    </td>
                                    <tr v-for="entry in monthlyLeaderboard" :key="entry" class="voter-leaderboard--row">
                                        <td class="voter-name">
                                            {{ entry.username }}
                                        </td>
                                        <td class="voter-amount">
                                            {{ entry.count }} votes
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="col">
                        <div class="card">
                            <div class="vote-statistics-stat" id="total-votes">
                            <h5 class="content-box--heading">Total<br>Votes</h5>
                                <h3>
                                    {{ totalVotes }}
                                </h3>
                                <p>Total Votes</p>
                            </div>
                        </div>

                    </div>
                    <div class="col">
                        <div class="card">
                            <div class="vote-statistics-stat" id="mtotal-votes">
                                <h5 class="content-box--heading">Monthly<br>Votes</h5>
                                <h3>
                                    {{ monthlyVotes }}
                                </h3>
                                <p>Votes for {{ month }} {{ year }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import moment from 'moment';
import Loading from '@/components/Loading'

export default {
    components: {
        Loading
    },
    created() {
        document.title = 'Vote - Near-Reality'

        this.apiClient = new APIClient()
        this.getVoteMetrics()
        this.getVoteCountdowns()
    },
    data() {
        return {
            apiClient: null,
            loading: true,
            month: moment().format('MMMM'),
            year: moment().year(),
            totalVotes: 0,
            monthlyVotes: 0,
            monthlyLeaderboard: [],
            countdownIssue: true,
            runelistDiff: null,
            runelocusDiff: null,
            rspslistDiff: null,
        }
    },
    methods: {
        getVoteCountdowns() {
            this.apiClient.getVoteCountdowns().then(response => {
                this.updateTimes(response)
                setInterval(() => this.updateTimes(response), 1000)
                this.countdownIssue = false;
            }).catch((err) => {
                this.countdownIssue = true;
                console.log(err)
            })
        },
        updateTimes(response) {
            const second = 1000
            const minute = second * 60
            const hour = minute * 60
            const day = hour * 24

            let now = new Date().getTime()

            if (response.data.runelist === null) {
                this.runelistDiff = `Available!`
            } else {
                let difference = new Date(response.data.runelist) - now
                if (Math.sign(difference) == -1) {
                    this.runelistDiff = `Available!`
                } else {
                    const runelistHours = Math.floor((difference % (day)) / (hour))
                    const runelistMinutes = Math.floor((difference % (hour)) / (minute))
                    const runelistSeconds = Math.floor((difference % (minute)) / second)
                    this.runelistDiff = `${runelistHours > 0 ? `${runelistHours} hours, ` : ''}${runelistMinutes > 0 ? `${runelistMinutes} minutes, ` : ''}${runelistSeconds} seconds`
                }
            }

            if (response.data.runelocus === null) {
                this.runelocusDiff = `Available!`
            } else {
                let difference = new Date(response.data.runelocus) - now
                if (Math.sign(difference) == -1) {
                    this.runelocusDiff = `Available!`
                } else {
                    const runelocusHours = Math.floor((difference % (day)) / (hour))
                    const runelocusMinutes = Math.floor((difference % (hour)) / (minute))
                    const runelocusSeconds = Math.floor((difference % (minute)) / second)
                    this.runelocusDiff = `${runelocusHours > 0 ? `${runelocusHours} hours, `: ''}${runelocusMinutes > 0 ? `${runelocusMinutes} minutes, ` : ''}${runelocusSeconds} seconds`
                }
            }

            if (response.data.rsps_list === null) {
                this.rspslistDiff = `Available!`
            } else {
                let difference = new Date(response.data.rsps_list) - now
                if (Math.sign(difference) == -1) {
                    this.rspslistDiff = `Available!`
                } else {
                    const rspslistHours = Math.floor((difference % (day)) / (hour))
                    const rspslistMinutes = Math.floor((difference % (hour)) / (minute))
                    const rspslistSeconds = Math.floor((difference % (minute)) / second)
                    this.rspslistDiff = `${rspslistHours > 0 ? `${rspslistHours} hours, ` : ''}${rspslistMinutes > 0 ? `${rspslistMinutes} minutes, `: ''}${rspslistSeconds} seconds`
                }
            }
        },
        getVoteMetrics() {
            this.apiClient.getVoteMetrics().then(response => {
                if (response.data) {
                    this.loading = false;

                    if (response.data.totalVotes) {
                        this.totalVotes = response.data.totalVotes;
                    }

                    if (response.data.monthlyVotes) {
                        this.monthlyVotes = response.data.monthlyVotes;
                    }

                    if (response.data.totalVotes) {
                        this.monthlyLeaderboard = response.data.monthlyLeaderboard;
                    }
                }
            })
        }
    }
}
</script>
