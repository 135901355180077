<template>
    <main>
        <div class="container super-narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Login</h3>
                </div>
                <p class="text-center mb-0">Not Registered? <router-link to="/register">Create an Account</router-link></p>
                <div v-if="errors.details" :key="error" class="my-3 error-message text-center">
                    <p>{{ errors.details }}</p>
                </div>
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="loginUsername" class="form-label">Username</label>
                        <input v-model="username" type="text" class="form-control mb-0" id="loginUsername" required>
                        <template v-if="errors.username">
                            <small v-for="error in errors.username" :key="error" class="text-danger">{{ error }}</small>
                        </template>
                    </div>
                    <div class="mb-3">
                        <label for="loginPassword" class="form-label">Password</label>
                        <input v-model="password" type="password" class="form-control mb-0" id="loginPassword" required>
                        <template v-if="errors.password">
                            <small v-for="error in errors.password" :key="error" class="text-danger">{{ error }}</small>
                        </template>                    
                    </div>
                    <!-- <VueHcaptcha ref="hcaptcha" sitekey="a43d36f1-86cb-48c0-8d0b-e0337a83a9b2" size="invisible" @verify="onVerify" class="text-center"/> -->
                    <button type="submit" class="btn full-width large green">Login</button>
                    <p class="text-center mt-3 mb-0">Forgot your password? <router-link to="/account/request-password-reset">Reset your password</router-link></p>
                    <p class="text-center">Having trouble logging in? <a href="https://discord.com/363WxMFMWx">Join Discord</a></p>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from '@/services/APIClient.js'
// import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'

export default {
    components: {
        // VueHcaptcha
    },
    created() {
        document.title = 'Login - Near-Reality'

        this.apiClient = new APIClient()
    },
    data() {
        return {
            apiClient: null,
            username: '',
            password: ''
        }
    },
    computed: {
        errors() {
            return this.$store.state.loginErrors;
        }
    },
    methods: {
        onVerify(tokenStr, ekey) {
            this.$store.dispatch('authenticate', {
                username: this.username,
                password: this.password
            })
        },
        login() {
            // this.$refs.hcaptcha.execute()
            this.$store.dispatch('authenticate', {
                username: this.username,
                password: this.password
            })
        }
    }
}
</script>
