<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="row flex-row d-flex justify-content-space-between top-page-divider">
                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <h3 class="page-title">User Profile</h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                        <!-- <div class="page-title-box-right">
                            <a href="#" class="btn">View Adventures Log</a>
                        </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                        <div class="card">
                            <div class="d-flex justify-content-between mb-3 align-items-center">
                                <h5 class="rank-styles plus-icon mb-0" :class="getRankSelector($store.state.user.privilege, $store.state.user.memberRank)">{{ $store.state.user.name }}</h5>
                                <button @click="signOut" class="btn small red">Sign out</button>
                            </div>
                            <hr>
                            <ul class="profile-info">
                                <li>
                                    <p class="profile-info--title"><i class="fas fa-user"></i>Username:</p>
                                    <p class="rank-styles plus-icon mb-0" :class="getRankSelector($store.state.user.privilege, $store.state.user.memberRank)">{{ $store.state.user.name }}</p>
                                </li>
                                <li>
                                    <p class="profile-info--title"><i class="fas fa-calendar-alt"></i>Joined:</p>
                                    <p class="profile-info--result">{{ formatDate($store.state.user.joinDate) }}</p>

                                </li>
                                <li>
                                    <p class="profile-info--title"><i class="fas fa-poll-h"></i>Total Votes:</p>
                                    <p class="profile-info--result">{{ $store.state.user.totalVotes }}</p>
                                </li>
                                <li>
                                    <p class="profile-info--title"><i class="far fa-credit-card"></i>Total Spent:</p>
                                    <p class="profile-info--result">${{ $store.state.user.totalSpent }}</p>
                                </li>
                                <li>
                                    <p class="profile-info--title"><i class="fas fa-coins"></i>Store Credits:</p>
                                    <p class="profile-info--result">{{ $store.state.user.storeCredits }}</p>
                                </li>
                            </ul>

                        </div>
                        <div class="card">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="text-center m-0">Authenticator</h5>
                                <div v-if="twoFactorStatus.processing">Loading...</div>
                                <div v-else class="d-inline-block my-2 text-center" :class="twoFactorStatus.activated ? 'text-success' : 'text-danger'"><i class="fas" :class="{ 'fa-check-circle': twoFactorStatus.activated, 'fa-exclamation-circle': !twoFactorStatus.activated }"></i> {{ !twoFactorStatus.activated ? 'Not ': '' }} Activated</div>
                            </div>
                            <hr>
                            <Loading v-if="twoFactorStatus.processing"/>
                            <template v-else>
                                <p>You may activate two-factor authentication by scanning the QR code below on your preferred token provider. We recommend using <strong>Google Authenticator</strong>, which conveniently works on your phone and your browser, if you choose to download the extension. We can also reccomend using <strong>Authy</strong>.</p>
                                <p>Please consider activiating two-factor authentication using the button below to prevent your account from being hacked and help make the community a safer place. <strong>We also reward you for taking this step towards security, for example by providing extra vote rewards.</strong></p>
                                <button v-if="!twoFactorStatus.activated" @click="openTwoFactorActivationModal" class="btn small">Activate</button>
                                <button v-else @click="openTwoFactorDeactivationModal" class="btn small red">Deactivate</button>
                            </template>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-7">
<!--                        <div class="card">-->
<!--                            <div class="profile-categories&#45;&#45;row">-->
<!--                                <h5>Support Tickets</h5>-->
<!--                                <router-link to="/account/tickets" class="btn small">View Tickets</router-link>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="card">
                            <div class="profile-categories--row">
                                <h5>Purchase History</h5>
                                <router-link :to="{ path: '/account/purchases', query: { type: 'credits' } }" class="btn small"><i class="far fa-credit-card mr-2"></i>View Purchase History</router-link>
                            </div>
                        </div>
                        <div class="card">
                            <div>
                                <h5>Change Password</h5>
                                <div v-if="passwordResetForm.errorMessage" class="error-message">
                                    {{ passwordResetForm.errorMessage }}
                                </div>
                                <div v-if="passwordResetForm.successMessage" class="success-message">
                                    {{ passwordResetForm.successMessage }}
                                </div>
                                <form @submit.prevent="submitPasswordChange">
                                    <div class="mb-3">
                                        <label class="form-label">Current Password</label>
                                        <input v-model="passwordResetForm.data.password" type="password" required placeholder="Enter your current password" class="form-control mb-0">
                                        <small v-if="passwordResetForm.errors && passwordResetForm.errors.password" class="text-red">{{ passwordResetForm.errors.password }}</small>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">New Password</label>
                                        <input v-model="passwordResetForm.data.newPassword" type="password" required placeholder="Enter your newly-desired password" class="form-control mb-0">
                                        <small v-if="passwordResetForm.errors && passwordResetForm.errors.newPassword" class="text-red">{{ passwordResetForm.errors.newPassword }}</small>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Confirm New Password</label>
                                        <input v-model="passwordResetForm.data.newPasswordConfirmation" type="password" required placeholder="Confirm your newly-desired password" class="form-control">
                                        <small v-if="passwordResetForm.errors && passwordResetForm.errors.newPasswordConfirmation" class="text-red">{{ passwordResetForm.errors.newPasswordConfirmation }}</small>
                                    </div>

                                    <button type="submit" class="btn small">
                                        <div v-if="passwordResetForm.processing" class="spinner-border spinner-border-sm mr-2"/>
                                        Change Password
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <div v-if="$store.state.user.name && twoFactorStatus.secret" class="modal" tabindex="-1" ref="twoFactorActivationModal">
        <TwoFactorActivationModal @twoFactorActivated="activated" :username="$store.state.user.name" :secret="twoFactorStatus.secret"/>
    </div>
    <div class="modal" tabindex="-1" ref="twoFactorDeactivationModal">
        <TwoFactorDeactivationModal @deactivated="deactivated"/>
    </div>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import { dateMixin } from "@/mixins/dateMixin.js"
import { rankMixin } from "@/mixins/rankMixin.js"
import QRCode from 'qrcode'
import TwoFactorDeactivationModal from '@/components/modals/TwoFactorDeactivationModal'
import TwoFactorActivationModal from '@/components/modals/TwoFactorActivationModal'
import Loading from "@/components/Loading"
import Vue from "vue"

export default {
    components: {
        TwoFactorActivationModal,
        TwoFactorDeactivationModal,
        Loading
    },
    mixins: [ dateMixin, rankMixin ],
    created() {
        document.title = 'Account - Near-Reality'

        this.$store.dispatch('getAuthenticatedUser')
        this.apiClient = new APIClient()
    },
    mounted() {
        this.getTwoFactorStatus()
        this.twoFactorDeactivationModal = new window.bootstrap.Modal(this.$refs.twoFactorDeactivationModal)
        // QRCode.toCanvas(document.getElementById('canvas'), 'otpauth://totp/Near-Reality:Secret?secret=VCHRPVYTX3G4GZ7XLAIG4IKVNPAJL7G6&issuer=Near-Reality', function (error) {
        //     if (error) console.error(error)
        // })
    },
    data() {
        return {
            apiClient: null,
            twoFactorActivationModal: null,
            twoFactorDeactivationModal: null,
            twoFactorStatus: {
                processing: false,
                activated: null,
                secret: null
            },
            passwordResetForm: {
                processing: false,
                errorMessage: '',
                successMessage: '',
                data: {
                    password: '',
                    newPassword: '',
                    newPasswordConfirmation: ''
                }
            }
        }
    },
    methods: {
        activated() {
            this.twoFactorActivationModal.hide()
            this.getTwoFactorStatus()
        },
        deactivated() {
            this.twoFactorDeactivationModal.hide()
            this.getTwoFactorStatus()
        },
        openTwoFactorActivationModal() {
            this.twoFactorActivationModal.show()
        },
        openTwoFactorDeactivationModal() {
            this.twoFactorDeactivationModal.show()
        },
        getTwoFactorStatus() {
            this.twoFactorStatus.processing = true;
            this.apiClient.getTwoFactorStatus().then((response) => {
                this.twoFactorStatus.processing = false;
                if (response.status === 200 && response.data) {
                    this.twoFactorStatus.activated = response.data.activated
                    if (!response.data.activated) {
                        this.$nextTick(() => {
                            this.twoFactorActivationModal = new window.bootstrap.Modal(this.$refs.twoFactorActivationModal)
                        })
                        this.twoFactorStatus.secret = response.data.secret
                    }
                }
            }).catch((err) => {
                this.twoFactorStatus.processing = false;
            })
        },
        submitPasswordChange() {
            this.passwordResetForm.errorMessage = ''
            this.passwordResetForm.successMessage = ''
            this.passwordResetForm.processing = true;

            this.apiClient.postPasswordChange(this.passwordResetForm.data).then((response) => {
                this.passwordResetForm.processing = false;


                if (response && response.data && response.data) {
                    this.passwordResetForm.successMessage = response.data
                    this.passwordResetForm.data.password = ''
                    this.passwordResetForm.data.newPassword = ''
                    this.passwordResetForm.data.newPasswordConfirmation = ''
                    setTimeout(() => {
                        this.passwordResetForm.successMessage = ''
                    }, 5000)
                }
            }).catch((error) => {
                this.passwordResetForm.processing = false;
                if (error.response && error.response.data && error.response.data.details) {
                    this.passwordResetForm.errorMessage = error.response.data.details
                    setTimeout(() => {
                        this.passwordResetForm.errorMessage = ''
                    }, 5000)
                }
            })
        },
        signOut() {
            this.$store.dispatch('logout')
            this.$router.push('/')
        }
    }
}
</script>
