<template>
    <main>
        <div class="container">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">My Tickets</h3>
                    <p class="text-center">Below you will find any open and closed support tickets attached to your account.</p>
                </div>
                
                <div class="content-box">
                    <ul class="nav nav-tabs nav-fill" id="userActivity" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="openTickets-tab" data-bs-toggle="tab" data-bs-target="#openTickets" type="button" role="tab" aria-controls="home" aria-selected="true">Open Tickets</button>
                        </li>
                        
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="closedTickets-tab" data-bs-toggle="tab" data-bs-target="#closedTickets" type="button" role="tab" aria-controls="profile" aria-selected="false">Closed Tickets</button>
                        </li>
                    </ul>
                    <Loading v-if="loading"/>
                    <template v-else>
                        <div v-if="!loading" class="tab-content" id="userActivityContent">
                            <div class="tab-pane fade show active" id="openTickets" role="tabpanel" aria-labelledby="openTickets-tab">
                                <div class="table-overflow">
                                    <table class="ticketsHistory mb-0">
                                        <thead>
                                            <tr>
                                                <th class="subject">Title</th>
                                                <th>Type</th>
                                                <th class="created">Created</th>
                                                <th class="last-updated">Last Updated</th>
                                                <th class="status">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!openTickets.length">
                                                <td class="text-center" colspan="5">No open tickets found.</td>
                                            </tr>
                                            <tr v-for="ticket in openTickets" :key="ticket" @click="$router.push('/ticket/' + ticket.id)" class="table-row">
                                                <td class="subject">{{ ticket.title }}</td>
                                                <td class="subject">{{ ticket.type }}</td>
                                                <td class="created">{{ formatDate(ticket.created) }}</td>
                                                <td class="last-updated">{{ formatDate(ticket.updated) }}</td>
                                                <td :class="'status ' + (ticket.closed ? 'closed' : 'open')">{{ ticket.closed ? 'Closed' : 'Open' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div v-if="!loading" class="tab-pane fade" id="closedTickets" role="tabpanel" aria-labelledby="closedTickets-tab">
                                <div class="table-overflow">
                                    <table class="ticketsHistory mb-0">
                                        <thead>
                                            <tr>
                                                <th class="subject">Title</th>
                                                <th>Type</th>
                                                <th class="created">Created</th>
                                                <th class="last-updated">Last Updated</th>
                                                <th class="status">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!closedTickets.length">
                                                <td class="text-center" colspan="5">No closed tickets found.</td>
                                            </tr>
                                            <tr v-for="ticket in closedTickets" :key="ticket" @click="$router.push('/ticket/' + ticket.id)" class="table-row">
                                                <td class="subject">{{ ticket.title }}</td>
                                                <td class="subject">{{ ticket.type }}</td>
                                                <td class="created">{{ formatDate(ticket.created) }}</td>
                                                <td class="last-updated">{{ formatDate(ticket.updated) }}</td>
                                                <td :class="'status ' + (ticket.closed ? 'closed' : 'open')">{{ ticket.closed ? 'Closed' : 'Open' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>
                </div> 
            </div>
        </div>
    </main>
</template>

<script>
import APIClient from "@/services/APIClient.js"
import Loading from "@/components/Loading"
import { dateMixin } from "@/mixins/dateMixin.js"

export default {
    mixins: [ dateMixin ],
    components: {
        Loading
    },
    created() {
        document.title = 'Account Tickets - Near-Reality'

        this.client = new APIClient();

        this.client.getTickets().then(response => {
            this.loading = false;
            this.tickets = response.data;
        })
    },
    computed: {
        openTickets() {
            return this.tickets.filter(ticket => !ticket.closed)
        },
        closedTickets() {
            return this.tickets.filter(ticket => ticket.closed)
        }
    },
    data() {
        return {
            client: null,
            loading: true,
            tickets: null
        }
    }
}
</script>

<style scoped>
.table-row {
    cursor: pointer;
}
</style>
