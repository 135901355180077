<template>
    <div class="modal-dialog modal-dialog-centered">
        <div v-if="product" class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Credit Payment - Coinbase</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div class="credit-modal--body mb-2">
                    <ul>
                            <li><p>Current Balance:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ balance }} Credits</p></li>
                            <li><p>Order:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{ product.amount }} {{ product.bonus_amount > 0 ? `(+${product.bonus_amount} Bonus)` : '' }} Credits</p></li>
                            <li class="new-balance"><p>New Balance:</p><p><img src="@/assets/img/credits/nr-token.png" class="mr-2" lazy> {{balance + product.amount + product.bonus_amount}} Credits</p></li>
                            <li><hr></li>
                            <li class="total"><p>Total:</p><p>${{ product.price }} USD</p></li>
                        <li class="payment-method"><p>Payment Method:</p><p class="stripe-color">Coinbase</p></li>
                    </ul>
                </div>
                <hr>
                <h6>Important note about Coinbase payments</h6>
                <p>Because of the implications of paying with cryptocurrency, Coinbase does not confirm your payment with us until typically 5-10 minutes after submitting your payment. <strong>Once Coinbase officially confirms your payment, the credits should appear on your account</strong>. If you submitted a payment and have not received credits within this time period, it must mean that Coinbase failed to confirm your payment for whatever reason. For questions and support related to cryptocurrency payments or anything else, feel free to <router-link to="tickets/create">open a ticket.</router-link><strong></strong></p>
                <hr>
                <div class="text-center">
                    <form @submit.prevent="purchase">
                        <LoadingButton :loading="loading" :classes="['mr-2', 'stripe-color']">Pay ${{ product.price }} with Coinbase</LoadingButton>
                        <a class="btn red" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                    </form>
                </div>
            </div>
        </div>
        <div v-else class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Credit Payment - Coinbase</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <p>Sorry, but it looks like we ran into trouble while creating your Coinbase checkout session. Please report this to a staff member for help.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.stripe-color {
    color: #4379FF;
}
</style>

<script>
import APIClient from "@/services/APIClient.js"
import LoadingButton from "@/components/LoadingButton"

export default {
    components: {
        LoadingButton
    },
    props: {
        product: Object,
        balance: Number
    },
    created() {
        this.client = new APIClient();
    },
    data() {
        return {
            client: null,
        }
    },
    methods: {
        purchase() {
            const payload = {
                id: this.product.id,
                method: 'coinbase'
            }

            const order = this.client.postCreditPurchase(payload).then((response) => {
                this.loading = false;
                if (response.status === 201) {
                    window.open(response.data.hosted_url)
                }
            })
        }
    }
}
</script>