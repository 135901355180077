import { createStore } from "vuex";
import router from '@/router'
import APIClient from "@/services/APIClient.js";

export default createStore({
  state: {
    access: localStorage.getItem('access') || '',
    refresh: localStorage.getItem('refresh') || '',
    user: {},
    loginErrors: []
  },
  mutations: {
    setTokens(state, payload) {
      state.access = payload.access;
      state.refresh = payload.refresh;

      localStorage.setItem('access', payload.access);
      localStorage.setItem('refresh', payload.refresh);
    },
    removeTokens(state) {
      state.access = ''
      state.refresh = ''

      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setLoginErrors(state, payload) {
      state.loginErrors = payload;
    }
  },
  actions: {
    authenticate({ commit }, credentials) {
      const apiClient = new APIClient();
      apiClient.authenticate(credentials.username, credentials.password).then((response) => {
        if (response.status === 200) {
          if (response.data.access && response.data.refresh) {
            this.commit('setTokens', { access: response.data.access, refresh: response.data.refresh });
            router.push('/')
          }
        } else if(response.status === 401) {
          this.commit('setLoginErrors', "Invalid credentials")
        } else {
          this.commit('setLoginErrors', response.data)
        }
      }).catch((error) => {
        this.commit('setLoginErrors', error.response.data)
      });
    },
    getAuthenticatedUser() {
      if (JSON.stringify(this.state.user) !== '{}') {
        return;
      }

      const apiClient = new APIClient();
      apiClient.getAuthenticatedUser().then(response => {
        if (response.data) {
          this.commit('setUser', response.data);
        }
      })
    },
    logout() {
      this.commit('removeTokens')
      this.commit('setUser', {})
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.access && !!state.refresh;
    },
    getFormattedName() {
      // const group = "Developer";

      var img = 'hi';
      var color = 'yo';

      // if (group === "Owner") {
      //   img = require('@/assets/img/crowns/owner.png');
      //   color = "#ff1919";
      // } else if (group === "Developer") {
      //   img = require('@/assets/img/crowns/developer.png');
      //   color = "#558fce";
      // } else if (group === "Manager") {
      //   img = require('@/assets/img/crowns/manager.png');
      //   color = "#d57011"
      // } else if (group === "Administrator") {
      //   img = require('@/assets/img/crowns/admin.png');
      //   color = "#FFFF00";
      // } else if (group === "Senior Moderator") {
      //   img = require('@/assets/img/crowns/senior.png');
      //   color = "#9673D7";
      // } else if (group === "Moderator") {
      //   img = require('@/assets/img/crowns/moderator.png');
      //   color = "#C6CAD1";
      // } else if (group === "Server Support") {
      //   img = require('@/assets/img/crowns/server-support.png');
      //   color = "#007dc4";
      // } else if (group === "Moderator") {
      //   img = require('@/assets/img/crowns/moderator.png');
      //   color = "#C6CAD1";
      // }

      return "<img style=\"padding-bottom:4px;padding-right:2px\" src=\"" + img + "\">" + "<span style=\"color:" + color + ";font-weight:bold;text-shadow: 1px 1px 10px " + color + "; text-transform: capitalize;\">Andys1814</span>";;
    }
  },
  modules: {},
});
