<template>
  <button :disabled="loading" class="btn" :class="classes">
    <div v-if="loading" class="spinner-border spinner-border-sm mr-2"/>
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    classes: Array
  },
}
</script>