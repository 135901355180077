<template>
    <div>
        <div v-if="editor" class="forum-reply--editor pd-1">
            <button @click.prevent="editor.chain().focus().toggleBold().run()" class="editor-btn" :class="{ 'is-active': editor.isActive('bold') }" title="Bold" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-bold"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleItalic().run()" class="editor-btn" :class="{ 'is-active': editor.isActive('italic') }" title="Italic" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-italic"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleUnderline().run()" class="editor-btn" :class="{ 'is-active': editor.isActive('underline') }" title="Italic" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-underline"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleStrike().run()" class="editor-btn" :class="{ 'is-active': editor.isActive('strike') }" title="Strike" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-strikethrough"></i>
            </button>
            <div class="spacer"></div>
            <button @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="editor-btn" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" title="Heading 1" data-toggle="tooltip" data-placement="top">
                h1
            </button>
            <button @click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="editor-btn" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" title="Heading 2" data-toggle="tooltip" data-placement="top">
                h2
            </button>
            <button @click.prevent="editor.chain().focus().toggleBulletList().run()" class="editor-btn" :class="{ 'is-active': editor.isActive('bulletList') }" title="Bullet List" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-list"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleOrderedList().run()" class="editor-btn" :class="{ 'is-active': editor.isActive('orderedList') }" title="Ordered List" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-list-ol"></i>
            </button>
            <div class="spacer"></div>
            <button @click.prevent="editor.chain().focus().setTextAlign('left').run()" class="editor-btn" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }" title="Align Left" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-align-left"></i>
            </button>
            <button @click.prevent="editor.chain().focus().setTextAlign('center').run()" class="editor-btn" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }" title="Align Center" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-align-center"></i>
            </button>
            <button @click.prevent="editor.chain().focus().setTextAlign('right').run()" class="editor-btn" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }" title="Align Right" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-align-right"></i>
            </button>
            <div class="spacer"></div>
            <button @click.prevent="editor.commands.setHorizontalRule()" class="editor-btn" title="Spacer" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-ruler-horizontal"></i>
            </button>
            <button @click.prevent="editor.chain().focus().unsetAllMarks().run()" class="editor-btn" title="Clear Formatting" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-remove-format"></i>
            </button>
            <div class="spacer"></div>
            <button @click.prevent="addImage" class="editor-btn" title="Heading 2" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-image"></i>
            </button>
            <div class="spacer"></div>
            <button @click.prevent="editor.chain().focus().undo().run()" class="editor-btn" title="Undo" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-undo"></i>
            </button>
            <button @click.prevent="editor.chain().focus().redo().run()" class="editor-btn" title="Redo" data-toggle="tooltip" data-placement="top">
                <i class="fas fa-redo"></i>
            </button>
        </div>
        <EditorContent class="form-control" id="threadReply" :editor="editor"/>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'

export default {
    components: {
        EditorContent
    },
    props: {
        content: String
    },
    mounted() {
        this.editor = new Editor({
            content: this.content,
            extensions: [
                StarterKit.configure({
                    bulletList: {
                        HTMLAttributes: {
                            class: 'default-list'
                        }
                    },
                    orderedList: {
                        HTMLAttributes: {
                            class: 'default-list'
                        }
                    }
                }),
                Underline,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Image.configure({
                    inline: true
                })
            ],
            onUpdate: () => {
                this.$emit('update:editorContent', this.editor.getHTML())
            }
        })
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    data() {
        return {
            editor: null
        }
    },
    methods: {
        addImage() {
            const url = window.prompt('URL')

            if (url) {
                this.editor.chain().focus().setImage({ src: url }).run()
            }
        },
    }
}
</script>

<style>
/* .ProseMirror {
    margin-top: 1rem;
    line-height: 0.25;
} */
.ProseMirror {
    min-height: 200px;
}
</style>