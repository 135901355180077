<template>
    <NavBar v-if="$route.name !== '404'"/>
    <router-view/>
    <Footer v-if="$route.name !== '404'"/>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

export default {
  components: {
    NavBar,
    Footer
  }
}
</script>


