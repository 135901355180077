<template>
    <header id="navigation-top">
<!--        <router-link to="/store" class="cyber-sale-banner d-flex justify-content-center align-items-center py-2">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="32" height="32" viewBox="0 0 32 32"><title>discount-2</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" stroke="#30a322" stroke-miterlimit="10"><line x1="11" y1="21" x2="21" y2="11" stroke="#30a322"></line> <circle cx="12" cy="12" r="2" stroke="#30a322"></circle> <circle cx="20" cy="20" r="2" stroke="#30a322"></circle> <polygon points="27,21 31,16 27,11 27,5 21,5 16,1 11,5 5,5 5,11 1,16 5,21 5,27 11,27 16,31 21,27 27,27 "></polygon></g></svg> <p class="mb-0"> Our Cyber Week Sale has begun! <span>Browse the store <svg xmlns="http://www.w3.org/2000/svg" class="ml-1" width="16" height="16" viewBox="0 0 16 16"><title>arrow-right</title><g fill="#30a322"><path d="M10.707,2.293A1,1,0,0,0,9.293,3.707L12.586,7H1A1,1,0,0,0,1,9H12.586L9.293,12.293a1,1,0,1,0,1.414,1.414l5-5a1,1,0,0,0,0-1.414Z" fill="#30a322"></path></g></svg></span></p>-->
<!--        </router-link>-->


        <!-- Navigation Start -->
        <nav class="navbar navbar-expand-xl">
            <div class="container large d-flex flex-row justify-content-xl-between align-items-center">
                <div class="d-flex flex-row align-items-center">
                    <div class="nav-logo mr-3 mr-lg-4">
                        <router-link to="/">
                            <img src="@/assets/img/nav-logo.png" lazy>
                        </router-link>
                    </div>

                    <div class="collapse navbar-collapse" id="navbarContent">
                        <ul class="navbar-nav">
                            <li class="nav-item px-3 nav-login">
                                <router-link to="/login" class="nav-link">Login / Register</router-link>
                            </li>
                            <li class="nav-item px-3">
                                <router-link to="/play" class="nav-link">Play</router-link>
                            </li>
<!--                            <li class="nav-item px-3">-->
<!--                                <router-link to="/credits" class="nav-link">Store</router-link>-->
<!--                            </li>-->
<!--                            <li class="nav-item px-3">-->
<!--                                <router-link to="/vote" class="nav-link">Vote</router-link>-->
<!--                            </li>-->
                            <li class="nav-item px-3">
                                <a class="nav-link" href="https://discord.com/363WxMFMWx" target="_blank">Discord</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end gap-2">
                    <div class="guest-actions user-signed-in">
                        <div class="dropdown">
                            <div v-if="!($store.getters.isLoggedIn)" class="d-flex flex-row-reverse">
                                <router-link to="/login" class="mb-0 text-white font-weight-bold">Login</router-link>
                            </div>
<!--                            <a class="dropdown-toggle text-white" href="#" role="button" id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--                                <i class="fas fa-download mr-2"></i>-->
<!--                                <p class="mb-0">Play Free Now</p>-->
<!--                            </a>-->
<!--                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userMenu">-->
<!--                                <li><a class="dropdown-item" href="https://static.near-reality.com/Install-Near-Reality.exe" target="_blank"><i class="fab fa-windows mr-1"></i>Windows (64-bit)</a></li>-->
<!--                                <li><a class="dropdown-item" href="https://static.near-reality.com/Install_Near-Reality_32-bit.exe" target="_blank"><i class="fab fa-windows mr-1"></i>Windows (32-bit)</a></li>-->
<!--                                <li><a class="dropdown-item" href="https://static.near-reality.com/Near-Reality.app.zip" target="_blank"><i class="fab fa-apple mr-1"></i>MacOS</a></li>-->
<!--                                <li><a class="dropdown-item" href="https://static.near-reality.com/Near-Reality.jar" target="_blank"><i class="fab fa-java mr-1"></i>Jar Launcher</a></li>-->
<!--                            </ul>-->
                        </div>
                    </div>
                    <div v-if="($store.getters.isLoggedIn)" class="authenticated-actions ml-4">
                        <div class="dropdown">
                            <a class="dropdown-toggle text-white" href="#" role="button" id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                <p class="rank-styles plus-icon mb-0" :class="getRankSelector($store.state.user.privilege, $store.state.user.memberRank)">{{ $store.state.user.name }}</p>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userMenu">
                                <li><router-link class="dropdown-item" to="/account"><i class="fas fa-user-circle mr-1"></i>Account</router-link></li>
<!--                                <li><router-link class="dropdown-item" to="/adventure-log"><i class="fas fa-book-dead mr-1"></i>Adventures Log</router-link></li>-->
<!--                                <li><router-link class="dropdown-item" to="/account/tickets"><i class="fas fa-envelope-open-text mr-1"></i>Tickets</router-link></li>-->
                                <li class="logout"><a class="dropdown-item" href="#" @click.prevent="logout"><i class="fas fa-sign-out-alt mr-1"></i>Logout</a></li>
                            </ul>
                        </div>
                    </div>

                    <button type="button" class="navbar-toggle navbar-toggler" data-bs-toggle="collapse" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation" data-bs-target="#navbarContent" id="nav-btn">
                <input class="menu-icon" type="checkbox" id="menu-icon" name="menu-icon" onclick="checkFunction()"/>
                <label for="menu-icon"></label>
            </button>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { rankMixin } from "@/mixins/rankMixin.js"

export default {
    mixins: [ rankMixin ],
    computed: {
        userRank() {
            return this.$store.state.user.privilege.toLowerCase().replace(' ', '-')
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push('/')
        }
    }
}
</script>
