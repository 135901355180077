import { createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";
import Terms from "@/views/static/Terms.vue"
import Privacy from "@/views/static/Privacy.vue"
import Rules from "@/views/static/Rules.vue"
import FAQ from "@/views/static/FAQ.vue"
import Partnerships from "@/views/static/Partnerships.vue"
import Register from "@/views/Register.vue";
import AccountCreated from "@/views/account/AccountCreated.vue";
import Login from "@/views/Login.vue";
import Play from "@/views/Play.vue"
import Store from "@/views/Store.vue"
import StoreSuccess from "@/views/static/StoreSuccess.vue"
import Credits from "@/views/Credits.vue"
import Vote from "@/views/Vote.vue"
import Account from "@/views/account/Account.vue"
import PurchaseHistory from "@/views/account/PurchaseHistory.vue"
import ChangePassword from "@/views/account/ChangePassword.vue"
import RequestPasswordReset from "@/views/account/RequestPasswordReset.vue"
import ResetPassword from "@/views/account/ResetPassword.vue"
import Hiscores from "@/views/Hiscores.vue"
import HiscoresIndividual from "@/views/HiscoresIndividual.vue"
import TicketCreate from "@/views/tickets/TicketCreate.vue"
import TicketsIndex from "@/views/tickets/TicketsIndex.vue"
import Ticket from "@/views/tickets/Ticket.vue"
import TicketInformation from "@/views/tickets/TicketInformation.vue"
import Tickets from "@/views/account/Tickets.vue"
import NewsArchive from "@/views/news/NewsArchive.vue"
import ForumsIndex from "@/views/forums/ForumsIndex.vue"
import ForumsCreateThread from "@/views/forums/ForumsCreateThread.vue"
import ForumsCategory from "@/views/forums/ForumsCategory.vue"
import ForumsThread from "@/views/forums/ForumsThread.vue"
import NotFound from "@/views/NotFound.vue"
import NewsCreate from "@/views/news/NewsCreate.vue"
import NewsPost from "@/views/news/NewsPost.vue"
import store from "@/store/index.js";
import AccountCreationExpired from "@/views/account/AccountCreationExpired.vue";

const routes = [
  { path: "/", name: "Index", component: Index },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/privacy", name: "Privacy", component: Privacy },
  { path: "/rules", name: "Rules", component: Rules },
  { path: "/faq", name: "FAQ", component: FAQ },
  { path: "/partnerships", name: "Partnerships", component: Partnerships },
  { path: "/register", name: "Register", component: Register },
  { path: "/welcome", name: "Welcome", component: AccountCreated },
  { path: "/registration-failed", name: "RegistrationFailure", component: AccountCreationExpired },
  { path: "/login", name: "Login", component: Login },
  { path: "/play", name: "Play", component: Play },
  { path: "/store", name: "Store", component: Store, meta: { auth: true } },
  { path: "/store/success", name: "StoreSuccess", component: StoreSuccess, meta: { auth: true } },
  { path: "/credits", name: "Credits", component: Credits, meta: { auth: true } },
  { path: "/vote", name: "Vote", component: Vote, meta: { auth: true } },
  { path: "/hiscores/:skill?", name: "HiscoresSkill", component: Hiscores },
  { path: "/hiscores/player/:username", name: "Hiscores-Individual", component: HiscoresIndividual },
  { path: "/account", name: "Account", component: Account, meta: { auth: true }},
  { path: "/account/tickets", name: "Tickets", component: Tickets, meta: { auth: true } },
  { path: "/account/purchases", name: "PurchaseHistory", component: PurchaseHistory, meta: { auth: true } },
  { path: "/account/change-password", name: "ChangePassword", component: ChangePassword, meta: { auth: true } },
  { path: "/account/request-password-reset", name: "RequestPasswordReset", component: RequestPasswordReset },
  { path: "/account/reset-password", name: "ResetPassword", component: ResetPassword },
  { path: "/tickets/", name: "TicketsIndex", component: TicketsIndex },
  { path: "/ticket/:id(\\d+)", name: "Ticket", component: Ticket, meta: { auth: true } },
  { path: "/ticketinfo/", name: "TicketInformation", component: TicketInformation },
  { path: "/tickets/create", name: "Tickets-Create", component: TicketCreate, meta: { auth: true } },
  { path: "/forums", name: "ForumsIndex", component: ForumsIndex },
  { path: "/forums/:id(\\d+)/create", name: "ForumsCreateThread", component: ForumsCreateThread, meta: { auth: true } },
  { path: "/news/create", name: "NewsCate", component: NewsCreate, meta: { auth: true } },
  { path: "/forums/:id(\\d+)", name: "ForumsCategory", component: ForumsCategory },
  { path: "/forums/thread/:id(\\d+)", name: "ForumsThread", component: ForumsThread },
  { path: "/news", name: "NewsArchive", component: NewsArchive },
  { path: "/news/:slug/", name: "NewsPost", component: NewsPost },
  { path: "/:catchAll(.*)", name: "404", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters.isLoggedIn
  if (loggedIn) {
    store.dispatch('getAuthenticatedUser') // Logged in on an auth page, fetch user data from API.
  }

  if (loggedIn && (to.fullPath === "/login" || to.fullPath === "/register")) {
    next("/");
  } else if (to.meta.auth && !loggedIn) {
    next("/login");
  } else {
    next();
  }
})

export default router;
