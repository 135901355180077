<template>
  <main>
    <div class="container">
      <div class="main-content-box">
        <div class="top-page-divider">
          <h3 class="page-title center">Frequently Asked Questions</h3>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <h5 class="content-box--heading">Contents</h5>
            <div class="table-of-contents">
              <ul>
                <li><a href="#2">1. Do I need a website account to play Near-Reality?</a></li>
                <li><a href="#3">2. How do I report a bug?</a></li>
                <li><a href="#4">3. Can I purchase products with OSGP, and if so, how?</a></li>
                <li><a href="#5">4. What is the process for becoming a Near-Reality staff member?</a></li>
                <li><a href="#6">5. How do I claim rewards after voting?</a></li>
                <li><a href="#7">6. How do I claim items purchased from the store?</a></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-9">
            <hr id="1">
            <h5 class="paragraph--heading">1. Do I need a website acount to play Near-Reality?</h5>
            <p><strong>Yes</strong>. Our website provides a centralized authentication database that is used by the game server. This means you must create an account before logging in game.</p>
            <hr id="2">
            <h5 class="paragraph--heading">2. How do I report a bug?</h5>
            <p>Near-Reality's bug reports are handled through our in-house ticketing system. Our staff members will review and assess each bug report ticket individually, and pass the information along to our developers on a frequent basis.</p>
            <hr id="3">
            <h5 class="paragraph--heading">3. Can I purchase credits with OSGP, and if so, how?</h5>
            <p><strong>Yes</strong> - we actively accept OSRS GP donations. When purchasing a credit package from the <router-link to="/credits">credit store</router-link>, you may select OSRS Gold as your payment method. Upon doing so, you will be prompted to create a ticket where one our staff members will be in contact with you. At this point, a direct conversation via Discord may be easier for both parties, so please be ready to be contacted over Discord.</p>
            <hr id="4">
            <h5 class="paragraph--heading">4. What is the process for becoming a Near-Reality staff member?</h5>
            <p>When staff positions are open, an announcement will be made in our discord channel </p>
            <hr id="5">
            <h5 class="paragraph--heading">5. How do I claim rewards after voting?</h5>
            <p>After successfully voting on our website, the vote points will be automatically added to your account.</p>
            <hr id="6">
            <h5 class="paragraph--heading">6. How do I claim items purchased from the store?</h5>
            <p>After successfully purchasing an item through the in-game store (<i>::store</i>), the items will be automatically delivered to your account upon payment completion.</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  created() {
    document.title = 'FAQ - Near-Reality'
  }
}
</script>
