import axios from "axios";

// Create an API client function with request and response interceptors
const apiClient = (baseURL = "/api", timeout = 60000) => {
    const options = {
        baseURL,
        timeout,
        headers: {
            "Content-Type": "application/json"
        }
    };

    const client = axios.create(options);

    client.interceptors.request.use(
        (config) => {
            const accessToken = localStorage.getItem('access');
            if (accessToken) {
                config.headers["Authorization"] = `Bearer ${accessToken}`;
            }
            return config;
        },
        (requestError) => Promise.reject(requestError)
    );

    client.interceptors.response.use(
        (response) => response,
        (error) => {
            const config = error.config;
            if (error.response && error.response.status === 401) {
                console.log('Unauthorized request!');

                const refreshToken = localStorage.getItem("refresh");
                if (!refreshToken || config.url === '/token/refresh') {
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    return Promise.reject(error);
                }

                return client.post("/token/refresh", { "refresh": refreshToken }).then((response) => {
                    if (response.data && response.data.access) {
                        localStorage.setItem('access', response.data.access);
                        config.headers["Authorization"] = `Bearer ${response.data.access}`;
                    }
                    return client(config);
                }).catch((tokenRefreshError) => {
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    return Promise.reject(tokenRefreshError);
                });
            }
            return Promise.reject(error);
        }
    );

    return client;
};

// Define APIClient class with various methods
class APIClient {
    constructor(baseURL = '/api', timeout = 30000) {
        this.client = apiClient(baseURL, timeout);
    }

    authenticate(username, password) {
        return this.client.post('/token', { username, password });
    }

    refreshToken(refreshToken) {
        return this.client.post('/token/refresh', { refreshToken });
    }

    getAuthenticatedUser() {
        return this.client.get('/user');
    }

    postUser(payload) {
        return this.client.post('/users', payload);
    }

    postRequestPasswordReset(payload) {
        return this.client.post('/request-password-reset', payload);
    }

    postResetPassword(payload) {
        return this.client.post('/reset-password', payload);
    }

    getCreditProducts() {
        return this.client.get('/store/credits/products');
    }

    getStoreCategories() {
        return this.client.get('/store/category');
    }

    getStoreProducts() {
        return this.client.get('/store/products/all');
    }

    getStoreProductsByCategory(category, page) {
        return this.client.get(`/store/products/${category}/?page=${page}`);
    }

    getStoreCreditAmount() {
        return this.client.get('/store/credits/amount');
    }

    getStoreProductsForCategories(categories) {
        const queryParams = categories.map(category => `category=${category}`).join('&');
        return this.client.get(`/store/products?${queryParams}`);
    }

    postStorePurchase(payload) {
        return this.client.post('/store/purchase', payload);
    }

    postCreditPurchase(payload) {
        return this.client.post('/store/credits/purchase', payload);
    }

    getTickets() {
        return this.client.get('/tickets');
    }

    getTicket(id) {
        return this.client.get(`/tickets/${id}`);
    }

    postTicket(payload) {
        return this.client.post('/tickets', payload);
    }

    patchTicket(id, payload) {
        return this.client.patch(`/tickets/${id}`, payload);
    }

    postComment(payload) {
        return this.client.post('/tickets/comments', payload);
    }

    getVoteMetrics() {
        return this.client.get('/vote/metrics');
    }

    getHiscores(page) {
        return this.client.get(`/hiscores/?page=${page}`);
    }

    getPlayerHiscores(username) {
        return this.client.get(`/hiscores/player/${username}`);
    }

    getForums() {
        return this.client.get('/forums/');
    }

    getCategories() {
        return this.client.get('/forums/categories/');
    }

    getForumThreads(forum, page) {
        return this.client.get(`/forums/threads/${forum}?page=${page}`);
    }

    getForumThread(id, page) {
        return this.client.get(`/forums/thread/${id}/?page=${page}`);
    }

    postForumThread(payload) {
        return this.client.post('/forums/threads', payload);
    }

    postForumPost(payload) {
        return this.client.post('/forums/posts', payload);
    }

    getLatestForumThreads(amount) {
        return this.client.get(`/forums/threads/latest?amount=${amount}`);
    }

    getLatestNews(amount) {
        return this.client.get(`/news/latest?amount=${amount}`);
    }

    getNewsByDate(year, month) {
        return this.client.get(`/news/?year=${year}&month=${month}`);
    }

    getNews(slug) {
        return this.client.get(`/news/${slug}/`);
    }

    postNews(payload) {
        return this.client.post('/news/', payload);
    }

    getStorePurchases(type) {
        return this.client.get('/store/purchases');
    }

    postPasswordChange(payload) {
        return this.client.post('/change-password', payload);
    }

    getTwoFactorStatus() {
        return this.client.get('/2fa/status');
    }

    postTwoFactorDeactivation(payload) {
        return this.client.post('/2fa/deactivate', payload);
    }

    postTwoFactorActivation(payload) {
        return this.client.post('/2fa/activate', payload);
    }

    getVoteCountdowns() {
        return this.client.get('/vote/countdown');
    }
}

export default APIClient;
