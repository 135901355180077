<template>
    <main>
        <div class="container super-narrow">
            <div class="main-content-box">
                <div class="top-page-divider">
                    <h3 class="page-title center">Change Password</h3>
                </div>
                <p class="text-center">We will send you an email to your registered email address with a link to change your password.</p>
                <form>
                    <button type="submit" class="btn full-width large green">Change Password</button>
                </form>
                <hr>
                <router-link to="/account" class="btn full-width">Back to Account</router-link>
            </div>
        </div>
    </main>
</template>