<template>
    <article class="forum-posts-row p-4" :class="[{ 'staff-rank': user.name }, user.name ? userRank : '']">
        <div class="row gx-5 gy-4">
            <div class="col-12 col-md-4 col-lg-4 col-xl-3">
                <div class="forum-posts--profile">

                    <div class="forum-posts--profile-image mb-4">
                        <img src="@/assets/img/forums/playerhead.png" class="user-avatar">
                        <div v-if="user.is_staff" class="user-avatar--rank">
                            <img :src="require(`@/assets/img/crowns/${userRank}.png`)">
                        </div>
                    </div>
                    <div class="forum-posts--profile-info">
                        <h5 class="rank-styles" :class="getRankSelector(user.privilege, user.memberRank)">{{ user.name }}</h5>
                        <div class="user-profile-dropdown mt-3">

                            <ul class="user-stats mb-2 p-2">
                                <li><p>Rank:</p> <span class="rank-styles plus-icon" :class="getRankSelector(user.privilege, user.memberRank)">{{ getDisplayedRank(user.privilege, user.memberRank) }}</span></li>
                                <li><p>Thread Count:</p> <span>{{ user.thread_count }}</span></li>
                                <li><p>Post Count:</p> <div class="post-rank" :class="postRank">{{ user.post_count }}</div></li>
                                <li><p>Joined:</p> <span>{{ formatDateSimple(user.join_date) }}</span></li>
                            </ul>

                            <div class="user-links">
                                <router-link :to="`/hiscores/${user.name}`">Hiscores</router-link>
                                <router-link to="/adventure-log">Adventure Log</router-link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-8 col-xl-9">
                <div class="forum-posts--message-container p-4 mb-2">
                    <div v-html="content"></div>
                </div>
                <p class="text-faded">{{ formatDate(date) }}</p>
                <div class="forum-posts--message-controls">
                    <!-- <button class="btn small dark"><i class="fas fa-quote-right mr-2"></i>Quote</button>
                    <button class="btn small dark"><i class="fas fa-pen mr-2"></i>Edit</button>
                    <button class="btn small dark"><i class="fas fa-eye-slash mr-2"></i>Hide</button>
                    <button class="btn small dark"><i class="far fa-trash-alt mr-2"></i>Delete</button> -->
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import { dateMixin } from "@/mixins/dateMixin.js"
import { rankMixin } from "@/mixins/rankMixin.js"

export default {
    mixins: [ dateMixin, rankMixin ],
    props: {
        user: Object,
        content: String,
        date: String
    },
    computed: {
        userRank() {
            return this.user.privilege.toLowerCase().replace(' ', '-')
        },
        postRank() {
            if (this.user.post_count > 1500) {
                return 'twisted'
            } else if (this.user.post_count > 1000) {
                return 'third-age'
            } else if (this.user.post_count > 750) {
                return 'bandos'
            } else if (this.user.post_count > 500) {
                return 'barrows'
            } else if (this.user.post_count > 400) {
                return 'dragon'
            } else if (this.user.post_count > 300) {
                return 'rune'
            } else if (this.user.post_count > 200) {
                return 'adamant'
            } else if (this.user.post_count > 100) {
                return 'mithril'
            } else if (this.user.post_count > 50) {
                return 'black'
            } else if (this.user.post_count > 25) {
                return 'steel'
            } else if (this.user.post_count > 10) {
                return 'iron'
            }

            return 'bronze'
        }
    }
}
</script>
